import React from 'react';
import { IPropsSVG } from 'types';

export const LitecoinLogo: React.FC<IPropsSVG> = ({
  width = 25,
  height = 26,
  fill = {
    primary: '#017F04',
  },
  viewBox = '0 0 25 26',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M12.5 25.952C19.4036 25.952 25 20.3556 25 13.452C25 6.54844 19.4036 0.951996 12.5 0.951996C5.59644 0.951996 0 6.54844 0 13.452C0 20.3556 5.59644 25.952 12.5 25.952Z"
        fill={fill.primary}
      />
      <path
        d="M13.5627 15.3971L12.8866 13.3317L13.7314 12.2078L13.3346 10.9936L13.3085 10.9741L12.4769 12.0804L11.3416 8.61239L9.11375 10.0591L10.5932 14.5864L9.93898 15.4567L10.3487 16.7081L11.0024 15.8384L11.8743 18.5065L17.8034 14.6561L17.264 12.9934L13.5627 15.3971Z"
        fill={fill.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4996 22.1059C17.2789 22.1059 21.1534 18.2314 21.1534 13.452C21.1534 8.67262 17.2789 4.79816 12.4996 4.79816C7.72016 4.79816 3.8457 8.67262 3.8457 13.452C3.8457 18.2314 7.72016 22.1059 12.4996 22.1059Z"
        stroke={fill.primary}
      />
    </svg>
  );
};
