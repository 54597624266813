/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-bracket-spacing */
import React, { useState } from 'react';
import { Form, message, Tabs } from 'antd';
import { Result } from './tabs/Result';
import { Options } from './tabs/Options';
import { createCampaign } from 'api/traffic';
import { ChooseLanding } from './tabs/ChooseLanding';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const Campaign: React.FC<Props> = ({ closeHandler }) => {
  const messageKey = 'campaignForm';

  const [fields] = Form.useForm();
  const [tab, setTab] = useState<string>('landing');
  const [landing, setLanding] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [generatedUrl, setGeneratedUrl] = useState<{
    generatedUrlToSite: string;
    generatedUrlToLanding: string;
    generatedUrlToRedirectLanding: string;
  } | null>(null);

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      const generatedUrl = await createCampaign({
        landing,
        ...fields.getFieldsValue(),
      });

      setGeneratedUrl(generatedUrl);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  const items = [
    {
      label: 'Landing',
      key: 'landing',
      children: (
        <div>
          <ChooseLanding setLanding={setLanding} setTab={setTab} />
        </div>
      ),
    },
    {
      label: 'Options',
      key: 'options',
      children: (
        <div>
          <Options setTab={setTab} onFinish={onFinish} />
        </div>
      ),
    },
    {
      label: 'Result',
      key: 'result',
      children: (
        <div>
          <Result
            closeHandler={closeHandler}
            isLoading={isLoading}
            generatedUrl={generatedUrl}
          />
        </div>
      ),
    },
  ];

  return (
    <Form form={fields} layout="vertical" onFinishFailed={onFinishChecker}>
      <Tabs
        items={items}
        activeKey={tab}
        tabPosition={'left'}
        style={{ minWidth: '500px', minHeight: '400px' }}
      />
    </Form>
  );
};
