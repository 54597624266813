import React from 'react';
import { IPropsSVG } from 'types';

export const TronLogo: React.FC<IPropsSVG> = ({
  width = 45,
  height = 45,
  fill = {
    primary: 'white',
    secondary: '#EC0928',
  },
  viewBox = '0 0 45 45',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      fill="none"
    >
      <rect width="45" height="45" rx="8.33333" fill={fill.secondary} />
      <path
        d="M28.8697 14.2854L11.5747 11.1024L20.6752 34.0059L33.3577 18.5544L28.8697 14.2854ZM28.5907 15.6894L31.2367 18.2034L23.9992 19.5144L28.5907 15.6894ZM22.4287 19.2504L14.8012 12.9249L27.2677 15.2184L22.4287 19.2504ZM21.8857 20.3709L20.6422 30.6519L13.9357 13.7769L21.8857 20.3709ZM23.0362 20.9154L31.0492 19.4649L21.8587 30.6639L23.0362 20.9154Z"
        fill={fill.primary}
      />
    </svg>
  );
};
