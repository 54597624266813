import { configureStore } from '@reduxjs/toolkit';
import {
  authApi,
  usersApi,
  depositsApi,
  withdrawsApi,
  verificationsApi,
  articlesApi,
  categoriesApi,
  bannedCountryApi,
  statisticApi,
  awsApi,
  bansApi,
  promocodesApi,
  bonusesApi,
  dailyQuestsApi,
  userChangesApi,
} from './services';
import { sendTipsApi } from './services/sendTips';
import { suspiciousUsersApi } from './services/suspiciousUsers';
import { ticketsApi } from './services/tickets';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [depositsApi.reducerPath]: depositsApi.reducer,
    [withdrawsApi.reducerPath]: withdrawsApi.reducer,
    [verificationsApi.reducerPath]: verificationsApi.reducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [promocodesApi.reducerPath]: promocodesApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [bannedCountryApi.reducerPath]: bannedCountryApi.reducer,
    [statisticApi.reducerPath]: statisticApi.reducer,
    [awsApi.reducerPath]: awsApi.reducer,
    [bansApi.reducerPath]: bansApi.reducer,
    [bonusesApi.reducerPath]: bonusesApi.reducer,
    [dailyQuestsApi.reducerPath]: dailyQuestsApi.reducer,
    [sendTipsApi.reducerPath]: sendTipsApi.reducer,
    [suspiciousUsersApi.reducerPath]: suspiciousUsersApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [userChangesApi.reducerPath]: userChangesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(depositsApi.middleware)
      .concat(withdrawsApi.middleware)
      .concat(verificationsApi.middleware)
      .concat(articlesApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(sendTipsApi.middleware)
      .concat(bannedCountryApi.middleware)
      .concat(statisticApi.middleware)
      .concat(awsApi.middleware)
      .concat(bansApi.middleware)
      .concat(bonusesApi.middleware)
      .concat(dailyQuestsApi.middleware)
      .concat(promocodesApi.middleware)
      .concat(ticketsApi.middleware)
      .concat(suspiciousUsersApi.middleware)
      .concat(userChangesApi.middleware)
      .concat(bonusesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
