/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { CustomTable } from 'components/Table';
import { columns } from '../utils/tableConfig';
import { depositsApi } from 'store/services';
import moment, { Moment } from 'moment';
import { convertDates } from 'pages/Dashboard/utils/dates';
import generatePicker from 'antd/es/date-picker/generatePicker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

export const DepositsHome = () => {
  const MomentDatePicker = generatePicker<Moment>(momentGenerateConfig);
  const [currentDate, setDate] = useState<Moment>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const _dates = [] as any;
  const type = 'day';

  const rangeDates = useMemo(
    () => convertDates(_dates, currentDate, type),
    [_dates, currentDate]
  );

  const { data, isLoading } = depositsApi.useGetAllDepositsQuery({
    page: currentPage,
    perPage,
    ...rangeDates,
  });

  useEffect(() => {
    const localCurrentPage = sessionStorage.getItem('currentPage');
    const localCurrentDate = sessionStorage.getItem('currentDate');
    const localPerPage = sessionStorage.getItem('perPage');

    if (localCurrentPage) setCurrentPage(Number(localCurrentPage));
    if (localCurrentDate) setDate(moment(localCurrentDate));
    if (localPerPage) {
      setPerPage(Number(localPerPage));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', String(currentPage));
    sessionStorage.setItem('perPage', String(perPage));

    if (currentDate)
      sessionStorage.setItem('currentDate', String(currentDate.toDate()));

    return () => {
      sessionStorage.removeItem('currentPage');
      sessionStorage.removeItem('currentDate');
      sessionStorage.removeItem('perPage');
    };
  }, [currentPage, perPage, currentDate]);

  return (
    <>
      <div style={{ margin: '10px 0' }}>
        <MomentDatePicker
          value={currentDate}
          onChange={(v) => setDate(v as any)}
        />
      </div>

      <CustomTable
        loading={isLoading}
        columns={columns}
        data={data?.data}
        meta={data?.meta}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPerPage={setPerPage}
      />
    </>
  );
};
