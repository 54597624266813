export enum CurrencyEnum {
  usdt = 'usdt',
  native = 'native',
  KZT = 'KZT',
  UAH = 'UAH',
  RUB = 'RUB'
}

export enum FiatCurrency {
  KZT = 'KZT',
  UAH = 'UAH',
  RUB = 'RUB'
}

export enum BlockchainEnum {
  ETH = 'ETH',
  'BNB.BSC' = 'BNB.BSC',
  BNB = 'BNB',
  TRX = 'TRX',
  BTC = 'BTC',
  LTC = 'LTC',
  DOGE = 'DOGE',
  BUSD = 'BUSD',
  tether = 'tether',
  'MATIC.POLY' = 'MATIC',
  XMR = 'XMR',
  SOL = 'SOL',
  USDC = 'USDC',
  TON = 'TON',
  FIAT = 'FIAT',
}

export interface UserBalance {
  id: string;
  userId: string;
  blockchain: BlockchainEnum;
  currency: CurrencyEnum;
  amount: number;
  createdAt: string;
  updatedAt: string;
}

export type IBalance = {
  [key in UserBalance['blockchain'] | 'usdt']: number | null;
};
