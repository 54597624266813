import React, { useEffect } from 'react';
import { Button, Form, Select } from 'antd';
import countries from 'utils/countries';
import { bannedCountryApi } from 'store/services';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const BannedCountryAdd: React.FC<Props> = ({ closeHandler }) => {
  const [fields] = Form.useForm();
  const [add, { isLoading, isSuccess }] =
    bannedCountryApi.useAddCountryMutation();

  const onFinish = () => {
    // TODO
    // temporary solution before refactoring the backend, remove it after
    // use await addBannedCountry(fields.getFieldsValue())

    const currentCountry = countries.find(
      (i) => fields.getFieldValue('countryCode') === i.value
    );

    if (currentCountry) {
      add({
        countryCode: currentCountry.value,
        countryName: currentCountry.label,
      });
    }
  };

  const search = (
    input: string,
    option: { label: string; value: string } | undefined
  ) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    if (isSuccess) {
      closeHandler(true);
    }
  }, [isSuccess]);

  return (
    <Form form={fields} onFinish={onFinish} layout={'vertical'}>
      <Form.Item
        name="countryCode"
        label="Country"
        rules={[
          {
            required: true,
            message: 'Please select an country',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={search}
          placeholder="Please select an country"
          disabled={!countries}
          options={countries}
        ></Select>
      </Form.Item>
      <Button key="submit" htmlType="submit" loading={isLoading}>
        Submit
      </Button>
    </Form>
  );
};
