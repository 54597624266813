/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable padding-line-between-statements */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import { Localizations } from './Localizations';
import { bonusesApi, promocodesApi } from 'store/services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export enum MODE {
  EDIT,
  CREATE,
}
export enum PromocodeType {
  BALANCE = 'balance-reward',
  DEPOSIT_BONUS = 'deposit-bonus',
}

interface Props {
  mode: MODE;
  promocodeId?: string;
}

export const PromocodeEdit: React.FC<Props> = ({ mode, promocodeId }) => {
  const [fields] = Form.useForm<any>();
  const [typeField, setType] = useState<PromocodeType | null>(null);
  const [fieldsValue, setFieldsValue] = useState<any>(null);

  const [create, { isSuccess: createSuccess }] =
    promocodesApi.useCreatePromocodeMutation();

  const [update, { isSuccess: updateSuccess }] =
    promocodesApi.useUpdatePromocodeMutation();
  const { data: bonusData, isLoading } = bonusesApi.useGetAllBonusesQuery({
    page: 1,
    perPage: 100,
  });
  const dataArray = bonusData?.data.length ? bonusData.data : [];

  const { data: promocode } = promocodesApi.useGetPromocodeQuery(promocodeId);

  const navigate = useNavigate();

  const changeType = (type: PromocodeType) => {
    setType(type);
  };

  const onFinish = () => {
    const textData: {
      name: string;
      rewardAmount?: string;
      bonusId?: string;
      maxUses: string;
    } = {
      name: '',
      rewardAmount: '',
      bonusId: '',
      maxUses: '',
    };

    const fieldsValues = fields.getFieldsValue();

    const promocodeData: any = {
      ...textData,
      type: fieldsValues.type,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      expiresAt: new Date(fieldsValues.expiresAt.valueOf()),
      name: fieldsValues.name,
      rewardAmount: fieldsValues?.rewardAmount,
      bonusId: fieldsValues?.bonusId,
      maxUses: fieldsValues.maxUses,
    };

    if (mode === MODE.CREATE) {
      create(promocodeData);
    }
    if (mode === MODE.EDIT) {
      update({ ...promocodeData, id: promocodeId });
    }
  };

  useEffect(() => {
    if (promocode) {
      const parsedPromocode = {
        ...promocode,
        expiresAt: moment(promocode.expiresAt),
      };
      fields.setFieldsValue(parsedPromocode);

      setType(parsedPromocode.type);
      setFieldsValue(parsedPromocode);
    }
  }, [promocode]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate('..');
    }
  }, [createSuccess, updateSuccess]);

  return (
    <Form preserve form={fields} layout="vertical" onFinish={onFinish}>
      <Row>
        <Col>
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'Please select type',
              },
            ]}
          >
            <Select
              placeholder="Please select type"
              options={Object.values(PromocodeType).map((type) => {
                return {
                  label: type,
                  value: type,
                };
              })}
              onChange={(e) => changeType(e)}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {mode === MODE.CREATE && (
          <Col span={24}>
            <Localizations type={typeField} />
          </Col>
        )}
        {mode === MODE.EDIT && fieldsValue && (
          <Col span={24}>
            <Localizations type={typeField} />
          </Col>
        )}
      </Row>
      {typeField && typeField === PromocodeType.DEPOSIT_BONUS && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="bonusId" label="Bonus ID" required>
              <Select
                placeholder="Please select bonus ID"
                options={
                  dataArray &&
                  dataArray.map((bonus) => {
                    return {
                      label: bonus.name,
                      value: bonus.id,
                    };
                  })
                }
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Form.Item name="expiresAt" label="End At" required>
          <DatePicker
            format={'YYYY-MM-DD HH:mm'}
            showTime
            disabledDate={(current) => moment().add(-1, 'days') >= current}
          />
        </Form.Item>
      </Row>
      <Button key="submit" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
