import { GamesGroupUpdateModal } from 'components/Modals/GamesGroupUpdateModal';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { GamesGroupActiveUpdateModal } from 'components/Modals/GamesGroupActiveUpdateModal';
import { GamesEditRestrictedByIdModal } from 'components/Modals/GamesEditRestrictedByIdModal';
import { GameEditMetaByIdModal } from 'components/Modals/GameEditMetaByIdModal';
import { GameStatusChangeModal } from 'components/Modals/GameStatusChangeModal';

export const GamesGroupUpdate = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <GamesGroupUpdateModal open={true} closeHandler={closeHandler} />
    </>
  );
};

export const GamesGroupActiveUpdate = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <GamesGroupActiveUpdateModal open={true} closeHandler={closeHandler} />
    </>
  );
};

export const GamesRestrictedById = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <GamesEditRestrictedByIdModal open={true} closeHandler={closeHandler} />
    </>
  );
};

export const EditGamedMetaById = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <GameEditMetaByIdModal open={true} closeHandler={closeHandler} />
    </>
  );
};

export const GameStatusChange = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <GameStatusChangeModal open={true} closeHandler={closeHandler} />
    </>
  );
};
