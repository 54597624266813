/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-operators */
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import { TUser } from 'types/auth';
import { TBanUser } from 'types/bans';
import { TChangeRole } from 'types/roles';
import { TDisableSessions, TSession } from 'types/session';
import { IChangeDataParams, IUser } from 'types/users';

interface Response {
  data: TUser[];
  meta: TMetaData;
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getAllUsers: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.USERS,
        params,
      }),
      providesTags: ['User'],
      transformResponse: (rawResult: Response) => {
        const { data, meta } = rawResult;
        const newData = data.map((i, index) => ({
          ...i,
          number: index + (meta.currentPage - 1) * meta.perPage + 1,
        }));
        return {
          ...rawResult,
          data: newData,
        };
      },
    }),
    getFullInfoUser: builder.query<IUser, string>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/${params}`,
      }),
      providesTags: ['User'],
    }),

    getGamesTransactions: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/games-transactions/${params}`,
      }),
      providesTags: ['User'],
    }),

    getSuspects: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/suspects/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getSessions: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/sessions/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getWithdraws: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/withdraws/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getReferrals: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/referrals/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getUserBonuses: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/bonuses/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getTicketTransactions: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/tickets-transactions/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getReferralsTransactions: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/referral-transactions/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getReferralsClaims: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/referrals-claims/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getSendTips: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/send-tips/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getExchanges: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/exchanges/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    getTransaction: builder.query<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.USERS}/info/transaction/${params.id}`,
        params,
      }),
      providesTags: ['User'],
    }),

    ban: builder.mutation<TBanUser, TBanUser>({
      query: (params) => ({
        url: `${API_ROUTES.BAN_USER}`,
        body: {
          info: params,
        },
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    unban: builder.mutation<TBanUser, string>({
      query: (params) => ({
        url: `${API_ROUTES.BAN_USER}`,
        body: {
          params,
        },
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    remove2fa: builder.mutation<TBanUser, string>({
      query: (params) => ({
        url: `${API_ROUTES.REMOVE2FA}/${params}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    changeRole: builder.mutation<IUser, TChangeRole>({
      query: (params) => ({
        url: `${API_ROUTES.ROLE}`,
        body: {
          ...params,
        },
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    disableSessions: builder.mutation<TSession, TDisableSessions>({
      query: (params) => ({
        url: `${API_ROUTES.SESSIONS}`,
        body: {
          ...params,
        },
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    changeData: builder.mutation<IUser, IChangeDataParams>({
      query: (params) => ({
        url: `${API_ROUTES.CHANGE_DATA}/${params.id}`,
        body: {
          ...params.data,
        },
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const { useGetAllUsersQuery, useGetGamesTransactionsQuery } = usersApi;
