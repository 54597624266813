import React from 'react';
import { Outlet } from 'react-router-dom';
import { AddCategoryModal } from 'components/Modals/AddCategoryModal';

export const CategoriesAdd = () => {
  return (
    <>
      <Outlet />
      <AddCategoryModal open={true} />
    </>
  );
};
