import { Col, Form, Input, Row } from 'antd';
import Button from 'antd/es/button';
import React, { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { languages } from '../Localizations/utils/languages';
import { TLang } from 'types';
import { CustomEditor } from './CustomEditor';

export const Localizations: React.FC = () => {
  const [currentLang, setCurrentLang] = useState<number>(0);
  const currentTag = languages[currentLang].tag
    ? `_${languages[currentLang].tag}`
    : '';

  return (
    <>
      <Row gutter={6} style={{ margin: '12px 0' }}>
        {languages.map((lang: TLang, index: number) => (
          <Col key={`text-lang-${lang.tag}`}>
            <Button
              onClick={() => setCurrentLang(index)}
              type={currentLang === index ? 'primary' : 'default'}
            >
              {lang.label}
            </Button>
          </Col>
        ))}
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={`title${currentTag}`}
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please enter title',
              },
            ]}
          >
            <Input placeholder="Please enter title" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={`subtitle${currentTag}`}
            label="Subtitle"
            rules={[
              {
                required: true,
                message: 'Please enter subtitle',
              },
            ]}
          >
            <Input placeholder="Please enter title" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={`text${currentTag}`}
        label="Text"
        preserve
        rules={[
          {
            required: true,
            message: 'Please enter text',
          },
        ]}
      >
        <CustomEditor />
      </Form.Item>
    </>
  );
};
