/* eslint-disable space-before-function-paren */
export function getParams(prop: string) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(prop);
}

export function checkIfParamExists(includes: string) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString).toString();

  const isInclude = urlParams.includes(includes);

  return isInclude;
}

export const useGetParam = (param: string) => {
  const hashedParam = getParams(param);

  return hashedParam;
};
