/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TabsProps } from 'antd';
import { IUser } from 'types/users';
import { Balance } from 'components/Pages/Users/Balance';
import { ShortInfo } from 'components/Pages/Users/ShortInfo';

export const tabsConfig = (data: IUser, refetch: any): TabsProps['items'] => {
  return [
    {
      key: 'tabs_1',
      label: 'Balance',
      children: (
        <Balance data={data?.balance} refetch={refetch} userId={data.id} />
      ),
    },
    {
      key: 'tabs_2',
      label: 'Short info',
      children: <ShortInfo data={data} />,
    },
  ];
};
