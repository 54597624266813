import React from 'react';
import { Modal } from 'antd';
import { VerificationEdit } from 'components/Forms/Verifications';

interface Props {
  open: boolean;
  id: string;
  closeHandler: (value: boolean) => void;
}

export const VerificationModal: React.FC<Props> = ({
  open,
  id,
  closeHandler,
}) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  return (
    <Modal
      title={'Verification modal'}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <VerificationEdit id={id} closeHandler={closeHandler} />
    </Modal>
  );
};
