/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */

import React, { useEffect, useState } from 'react';
import { usersApi } from 'store/services';
import { CustomTable } from 'components/Table';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

export const TableUserWrapper = ({ columns, queryName }: any) => {
  const perPage = 15;
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);

  // if (!queryName) return <>No query</>;

  //@ts-ignore
  const { data, isError, isLoading } = usersApi[queryName]({
    id: id ?? skipToken,

    page: currentPage,
    perPage,
  });

  if (isError) return <></>;

  const tableData = Array.isArray(data) ? data[0] : data || [];
  const total = Array.isArray(data) ? data[1] : data?.length || 0;
  const totalPages = Math.ceil(
    Array.isArray(data) ? data[1] / perPage : data?.length / perPage || 0
  );

  return (
    <CustomTable
      hideSizeChanger
      data={tableData}
      columns={columns}
      loading={isLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      //@ts-ignore
      meta={{
        total,
        perPage,
        currentPage,
        totalPages,
      }}
    />
  );
};
