import { Button, Space } from 'antd';
import React, { useState } from 'react';
import { bonusesApi } from 'store/services';
import { AddBonusGameModal } from '../../../components/Modals/AddBonusGameModal';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
};

export const DeleteBonusAction: React.FC<Props> = ({ id }) => {
  const [del, { isLoading }] = bonusesApi.useDeleteBonusMutation();

  return (
    <Space>
      <Link to={`edit/${id}`}>
        <Button type="primary">Edit</Button>
      </Link>
      <Button danger loading={isLoading} onClick={() => del({ id })}>
        Delete
      </Button>
    </Space>
  );
};

export const OpenAddBonusGameModal: React.FC<Props> = ({ id }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <Space>
      <Button type="primary" size="small" onClick={() => setModalOpen(true)}>
        Add
      </Button>
      <AddBonusGameModal
        open={modalOpen}
        bonusId={id}
        closeHandler={() => setModalOpen(false)}
      />
    </Space>
  );
};

export const AddBonusGameAction: ({
  gameId,
  bonusId,
}: {
  gameId: string;
  bonusId: string;
}) => JSX.Element = ({
  gameId,
  bonusId,
}: {
  gameId: string;
  bonusId: string;
}) => {
  const [add] = bonusesApi.useAddBonusGameMutation();

  return (
    <Space>
      <Button
        type="primary"
        size="small"
        onClick={async () => await add({ gameId, bonusId })}
      >
        Add
      </Button>
    </Space>
  );
};
