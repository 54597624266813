import React from 'react';

export const Filter = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_585_11784)">
        <path d="M17.4961 2.54772C17.4961 0.57088 12.1531 0.043457 8.99609 0.043457C5.83902 0.043457 0.496094 0.570965 0.496094 2.54772C0.496094 2.90761 0.673317 3.21939 0.982037 3.48876L6.35055 8.85005C6.66199 9.16107 6.83701 9.58317 6.83701 10.0234V14.0686C6.83701 14.7026 7.19843 15.2811 7.76819 15.5589L10.5451 16.9136C10.6765 16.9778 10.8318 16.9696 10.9558 16.8921C11.0799 16.8146 11.1553 16.6786 11.1553 16.5324V10.0235C11.1553 9.58326 11.3304 9.16115 11.6417 8.85013L17.0102 3.48885C17.3189 3.2194 17.4961 2.90761 17.4961 2.54772ZM3.68053 1.64511C5.12077 1.31267 7.00854 1.12958 8.99609 1.12958C10.9837 1.12958 12.8714 1.31267 14.3117 1.64511C15.8796 2.00704 16.3538 2.41751 16.4075 2.54772C16.3538 2.67794 15.8794 3.08841 14.3116 3.45034C12.8713 3.78286 10.9836 3.96587 8.99609 3.96587C7.00862 3.96587 5.12095 3.78278 3.68062 3.45034C2.1128 3.08841 1.63841 2.67803 1.58452 2.54772C1.63832 2.41751 2.11262 2.00704 3.68053 1.64511Z" />
      </g>
      <defs>
        <clipPath id="clip0_585_11784">
          <rect
            width="17"
            height="17"
            fill="white"
            transform="translate(0.496094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
