import React, { useState } from 'react';
import { CustomTable } from 'components/Table';
import { promocodesApi } from 'store/services';
import { getColumns } from '../utils/tableConfig';
import { Form, Select } from 'antd';
import styles from '../../Users/Home/styles.module.scss';

const sizes = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 15,
    label: 15,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const PromocodesHome = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data, isLoading } = promocodesApi.useGetAllPromocodesQuery({
    page: currentPage,
    pageSize,
  });

  const handlePerPageSet = (value: number) => {
    setPageSize(value);
  };

  return (
    <>
      <Form>
        <Form.Item
          name={'perPageSelect'}
          className={styles.paginationContainer}
        >
          <Select
            options={sizes}
            defaultValue={10}
            style={{ width: '70px' }}
            onChange={(value) => setPageSize(value)}
          />
        </Form.Item>
      </Form>
      <CustomTable
        loading={isLoading}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        columns={getColumns()}
        data={data?.promocodes}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPerPage={handlePerPageSet}
        hideSizeChanger={true}
        meta={{
          total: data?.total || 0,
          perPage: pageSize,
          currentPage,
          totalPages: Math.ceil(data?.total || 0 / pageSize),
        }}
      />
    </>
  );
};
