import React from 'react';
import { useParams } from 'react-router-dom';
import { MODE } from 'components/Forms/Articles';
import { PromocodeEdit } from '../../../components/Forms/Promocodes';

export const PromocodesEdit = () => {
  const { id } = useParams();

  return <PromocodeEdit mode={MODE.EDIT} promocodeId={id} />;
};
