import React from 'react';
import { Button, Space } from 'antd';
import { categoriesApi } from 'store/services/categories';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
};

export const CategoryActions: React.FC<Props> = ({ id }) => {
  const [deleteCategory] = categoriesApi.useDeleteCategoryMutation();

  return (
    <Space>
      <Link to={`edit/${id}`}>
        <Button type="primary"> Edit</Button>
      </Link>
      <Button type="primary" danger onClick={() => deleteCategory({ id })}>
        Delete
      </Button>
    </Space>
  );
};
