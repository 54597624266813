import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import {
  TCategory,
  TCategoryNameParams,
  TCategoryIdParams,
  ICategoryParams,
} from 'types/categories';

interface Response {
  data: TCategory[];
  meta: TMetaData;
}

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Categories'],
  endpoints: (builder) => ({
    getAllCategories: builder.query<Response, TMeta>({
      query: (params) => ({
        url: `${API_ROUTES.CATEGORIES}/admin`,
        params,
      }),
      providesTags: ['Categories'],
    }),
    getCategory: builder.query<TCategory, string>({
      query: (params) => ({
        url: `${API_ROUTES.CATEGORIES}/${params}`,
      }),
      providesTags: ['Categories'],
    }),
    createCategory: builder.mutation<TCategory, TCategoryNameParams>({
      query: (params) => ({
        url: `${API_ROUTES.CATEGORIES}/add`,
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['Categories'],
    }),
    changeName: builder.mutation<TCategory, ICategoryParams>({
      query: (params) => ({
        url: `${API_ROUTES.CATEGORIES}/edit/${params.id}`,
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['Categories'],
    }),
    deleteCategory: builder.mutation<TCategory, TCategoryIdParams>({
      query: (params) => ({
        url: `${API_ROUTES.CATEGORIES}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Categories'],
    }),
  }),
});

export const { useGetAllCategoriesQuery } = categoriesApi;
