import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { TUserGameTransactions } from 'types/games';

export const columns: ColumnsType<TUserGameTransactions> = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    align: 'center',
    ellipsis: true,
  },
  {
    title: 'Game',
    dataIndex: 'gameName',
    key: 'gameName',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Total bets',
    dataIndex: 'totalGames',
    key: 'totalGames',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => Number(a.totalGames) - Number(b.totalGames),
  },
  {
    title: 'Sum bets',
    dataIndex: 'totalBets',
    key: 'totalBets',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => Number(a.totalBets) - Number(b.totalBets),
    render: (value) => Number(value).toFixed(2),
  },
  {
    title: 'Win sum',
    dataIndex: 'winSum',
    key: 'winSum',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => Number(a.winSum) - Number(b.winSum),
    render: (value) => Number(value).toFixed(2),
  },
  {
    title: 'Last bet',
    dataIndex: 'lastBet',
    key: 'lastBet',
    fixed: 'right',
    ellipsis: true,
    sorter: (a, b) =>
      new Date(a.lastBet).getTime() - new Date(b.lastBet).getTime(),
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'First bet',
    dataIndex: 'firstBet',
    key: 'firstBet',
    fixed: 'right',
    ellipsis: true,
    sorter: (a, b) =>
      new Date(a.firstBet).getTime() - new Date(b.firstBet).getTime(),
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    fixed: 'right',
    key: 'currency',
  }
];
