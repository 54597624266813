export enum TicketsActionEnum {
  Spent = 'spent',
  Purchased = 'purchased',
}

export type TTicketsTransaction = {
  id: string;
  action: TicketsActionEnum;
  amount: number;
  blockchain: string | null;
  quantity: number;
  updatedAt: string;
  createdAt: string;
};
