import React from 'react';
import { IPropsSVG } from 'types';

export const BinanceLogo: React.FC<IPropsSVG> = ({
  width = 46,
  height = 45,
  fill = {
    primary: '#1E2025',
    secondary: '#F0B90B',
  },
  viewBox = '0 0 46 45',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      fill="none"
    >
      <rect
        x="0.166992"
        width="46"
        height="45"
        rx="8.33333"
        fill={fill.primary}
      />
      <path
        d="M22.667 10.8334L16.5246 14.3978L18.7798 15.7157L22.667 13.4692L26.5542 15.7157L28.8094 14.3978L22.667 10.8334ZM26.5542 17.6027L28.8094 18.9207V21.5565L24.9222 23.803V28.2959L22.667 29.6139L20.4118 28.2959V23.803L16.5246 21.5565V18.9207L18.7798 17.6027L22.667 19.8492L26.5542 17.6027ZM28.8094 23.4436V26.0794L26.5542 27.3974V24.7615L28.8094 23.4436ZM26.5246 29.2544L30.4118 27.008V22.515L32.667 21.1971V28.3558L26.5246 31.9202V29.2544ZM30.4118 17.9921L28.1566 16.6742L30.4118 15.3563L32.667 16.6742V19.3101L30.4118 20.628V17.9921ZM20.4118 32.8488V30.2129L22.667 31.5309L24.9222 30.2129V32.8488L22.667 34.1667L20.4118 32.8488ZM18.7798 27.3974L16.5246 26.0794V23.4436L18.7798 24.7615V27.3974ZM22.667 17.9921L20.4118 16.6742L22.667 15.3563L24.9222 16.6742L22.667 17.9921ZM17.1774 16.6742L14.9222 17.9921V20.628L12.667 19.3101V16.6742L14.9222 15.3563L17.1774 16.6742ZM12.667 21.1671L14.9222 22.4851V26.978L18.8094 29.2245V31.8603L12.667 28.2959V21.1671Z"
        fill={fill.secondary}
      />
    </svg>
  );
};
