import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { NumberTypeEnum } from 'types';

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const numberType = (value: number): NumberTypeEnum => {
  if (value > 0) return NumberTypeEnum.PLUS;
  if (value < 0) return NumberTypeEnum.MINUS;

  return NumberTypeEnum.ZERO;
};

export const getStringWithDots = (str: string) => {
  return `${str.slice(0, 3)}...${str.slice(-3)}`;
};

export const copyToClipboard = (str: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard
      .writeText(str)
      .then(() => {
        message.open({
          key: 'clipboard-copy',
          type: 'success',
          content: `${str} - Copied to clipboard`,
        });
      })
      .catch(() => {
        message.open({
          key: 'clipboard-copy',
          type: 'error',
          content: 'Copy to clipboard was faild',
        });
      });
  }

  message.open({
    key: 'clipboard-copy',
    type: 'error',
    content: 'Copy to clipboard was faild',
  });
};
