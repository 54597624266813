import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Games } from './index';
import {
  GamesRestrictedById,
  GamesGroupActiveUpdate,
  GamesGroupUpdate,
  EditGamedMetaById,
  GameStatusChange,
} from './GamesGroupUpdate';
import { GamesHome } from './Home';

type Props = {
  redirectRoute?: string;
};

export const GamesRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Games>
            <Outlet />
          </Games>
        }
      >
        <Route
          path={'/'}
          element={
            <GamesHome>
              <Outlet />
            </GamesHome>
          }
        >
          <Route path={'/update'} element={<GamesGroupUpdate />} />

          <Route path={'/update-active'} element={<GamesGroupActiveUpdate />} />

          <Route path={'/update-one'} element={<GamesRestrictedById />} />

          <Route path={'/update-one-meta'} element={<EditGamedMetaById />} />

          <Route path={'/game-status-change'} element={<GameStatusChange />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
