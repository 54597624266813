/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import {Table, Typography} from 'antd';

import styles from '../Home/styles.module.scss';

const calculateAll = (arr: any[], ggrUp = false) => {
    let allClicks = 0;
    let allRegistrations = 0;
    let allFdp = 0;
    let allRdp = 0;
    let allDepositsSum = 0;
    let allBetSum = 0;
    let allWinSum = 0;
    let allLoseSum = 0;
    let allGGR = 0;
    if (ggrUp) {
        arr.forEach(
            ({
                 clicks,
                 registrations,
                 betSum,
                 winSum,
                 depositsSum,
                 fdp,
                 loseSum,
                 rdp,
                 GGR,
             }: any) => {
                allClicks = allClicks + clicks;
                allRegistrations = allRegistrations + registrations;
                allFdp = allFdp + fdp;
                allRdp = allRdp + rdp;
                allRdp = Number(allRdp.toFixed(2));
                allDepositsSum = Number(allDepositsSum.toFixed(2)) + Number(Number(depositsSum).toFixed(2));
                allBetSum = Number(String(Number(allBetSum).toFixed(2)).replace(/^0+(?=\d)/, '')) + Number(String(Number(betSum).toFixed(2)).replace(/^0+(?=\d)/, ''));
                allWinSum = Number(String(Number(allWinSum).toFixed(2)).replace(/^0+(?=\d)/, '')) + Number(String(Number(winSum).toFixed(2)).replace(/^0+(?=\d)/, ''));
                allLoseSum = Number(String(Number(allLoseSum).toFixed(2)).replace(/^0+(?=\d)/, '')) + Number(String(Number(loseSum).toFixed(2)).replace(/^0+(?=\d)/, ''));

                allGGR = allGGR + parseFloat(GGR);
            }
        );
    }else {
        arr.forEach(
            ({
                 clicks,
                 registrations,
                 betSum,
                 winSum,
                 depositsSum,
                 fdp,
                 loseSum,
                 rdp,
                 ggr,
             }: any) => {
                allClicks = allClicks + clicks;
                allRegistrations = allRegistrations + registrations;
                allFdp = allFdp + fdp;
                allRdp = allRdp + rdp;
                allRdp = Number(allRdp.toFixed(2));
                allDepositsSum = Number(allDepositsSum.toFixed(2)) + Number(Number(depositsSum).toFixed(2));
                allBetSum = Number(String(Number(allBetSum).toFixed(2)).replace(/^0+(?=\d)/, '')) + Number(String(Number(betSum).toFixed(2)).replace(/^0+(?=\d)/, ''));
                allWinSum = Number(String(Number(allWinSum).toFixed(2)).replace(/^0+(?=\d)/, '')) + Number(String(Number(winSum).toFixed(2)).replace(/^0+(?=\d)/, ''));
                allLoseSum = Number(String(Number(allLoseSum).toFixed(2)).replace(/^0+(?=\d)/, '')) + Number(String(Number(loseSum).toFixed(2)).replace(/^0+(?=\d)/, ''));

                allGGR = allGGR + parseFloat(ggr);
            }
        );
    }

    allGGR = parseFloat(allGGR.toFixed(2))
    console.log('total GGR ' + allGGR)
    return {
        allFdp,
        allRdp,
        allClicks,
        allBetSum,
        allWinSum,
        allLoseSum,
        allDepositsSum,
        allRegistrations,
        allGGR
    };
};

export const summary = (arr: any[]) => {
    const calculated = calculateAll(arr);

    if (!calculated) return null;
    let {
        allClicks,
        allRegistrations,
        allFdp,
        allRdp,
        allDepositsSum,
        allBetSum,
        allWinSum,
        allLoseSum,
        allGGR,
    } = calculated;

    allDepositsSum = Number(allDepositsSum.toFixed(2))
    allRdp = Number(allRdp.toFixed(2));
    allBetSum = Number(String(Number(allBetSum).toFixed(2)).replace(/^0+(?=\d)/, ''));
    allWinSum = Number(String(Number(allWinSum).toFixed(2)).replace(/^0+(?=\d)/, ''));
    allLoseSum = Number(String(Number(allLoseSum).toFixed(2)).replace(/^0+(?=\d)/, ''));

    return (
        <Table.Summary.Row className={styles.bold_border}>
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>

            <Table.Summary.Cell index={2}> - </Table.Summary.Cell>

            <Table.Summary.Cell index={3}>
                <Typography.Text>{allClicks}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={4}>
                <Typography.Text>{allRegistrations}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={5}>
                <Typography.Text>{allFdp}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={6}>
                <Typography.Text>{allRdp}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={7}>
                <Typography.Text>{allDepositsSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={8}>
                <Typography.Text>{allBetSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={9}>
                <Typography.Text>{allWinSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={10}>
                <Typography.Text>{allLoseSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={11}>
                <Typography.Text>{allGGR}</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row>
    );
};

export const expandedSummary = (arr: any) => {
    console.log(arr)
    const calculated = calculateAll(arr, true);

    if (!calculated) return null;
    const {
        allClicks,
        allRegistrations,
        allFdp,
        allRdp,
        allDepositsSum,
        allBetSum,
        allWinSum,
        allLoseSum,
        allGGR,
    } = calculated;
    return (
        <Table.Summary.Row className={styles.yellow_bold_border}>
            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

            <Table.Summary.Cell index={1}> - </Table.Summary.Cell>

            <Table.Summary.Cell index={2}>
                <Typography.Text>{allClicks}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={3}>
                <Typography.Text>{allRegistrations}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={4}>
                <Typography.Text>{allFdp}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={5}>
                <Typography.Text>{allRdp}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={6}>
                <Typography.Text>{allDepositsSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={7}>
                <Typography.Text>{allBetSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={8}>
                <Typography.Text>{allWinSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={9}>
                <Typography.Text>{allLoseSum}</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={10}>
                <Typography.Text>-</Typography.Text>
            </Table.Summary.Cell>

            <Table.Summary.Cell index={11}>
                <Typography.Text>{allGGR}</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row>
    );
};
