import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { BannedCountryModal } from 'components/Modals/BannedCountryModal';

export const CountryBlockerAdd = () => {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate('..');
  };

  return (
    <>
      <Outlet />
      <BannedCountryModal open={true} closeHandler={closeHandler} />
    </>
  );
};
