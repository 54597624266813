import React from 'react';
import { ICardConfig } from 'types/grid';
import { IUser } from 'types/users';
import { ChangeData } from 'components/Pages/Users/ChangeData';

export const contactInfoConfig: ICardConfig<IUser> = {
  key: 'contact-card',
  title: 'Contact info',
  className: 'user-info-card',
  grids: [
    {
      title: 'Email',
      dataIndex: 'email',
      render: (record) => (
        <ChangeData
          id={record.id}
          value={'email'}
          initial={record.email}
          copyable={true}
        />
      ),
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      render: (record) => (
        <ChangeData
          id={record.id}
          value={'phoneNumber'}
          initial={record?.phoneNumber as string}
          copyable={true}
        />
      ),
    },
    {
      title: 'Telegram',
      dataIndex: 'telegramName',
      render: (record) => (
        <ChangeData
          id={record.id}
          value={'telegramName'}
          initial={record?.telegramName as string}
          copyable={true}
        />
      ),
    }
  ],
};
