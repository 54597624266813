import { Card, Spin } from 'antd';
import React from 'react';
import { ICardConfig } from 'types/grid';

interface Props<T> {
  data: T;
  config: ICardConfig<T>;
}

export const InfoCard = <T extends { id: string }>({
  data,
  config,
}: Props<T>) => {
  if (!data) return <Spin />;

  return (
    <Card
      title={config.title}
      className={`${config.className} ant-card-contain-grid`}
    >
      {config.grids.map((i) => (
        <React.Fragment
          key={`item-${config.key}-${i.title}-${i.dataIndex.toString()}`}
        >
          <Card.Grid>{i.title}</Card.Grid>
          <Card.Grid>{i.render(data)}</Card.Grid>
        </React.Fragment>
      ))}
    </Card>
  );
};
