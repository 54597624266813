/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable padding-line-between-statements */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { ImageUploader } from '../../ImageUploader';
import { Localizations } from './Localizations';
import { bonusesApi } from 'store/services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { languages } from '../Articles/Localizations/utils/languages';
import { TBonus } from '../../../types/bonuses';
import dayjs from 'dayjs';

export enum MODE {
  EDIT,
  CREATE,
}
export enum BonusType {
  freeSpins = 'free-spins',
  depositBonus = 'deposit-bonus',
}

export enum BonusActivatorType {
  Deposit = 'Deposit',
}

interface Props {
  mode: MODE;
  bonusId?: string;
}

export const BonusEdit: React.FC<Props> = ({ mode, bonusId = '' }) => {
  const [fields] = Form.useForm<TBonus>();
  const [bonusType, setBonusType] = useState<BonusType | null>(null);
  const [fieldsValue, setFieldsValue] = useState<any>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDepositRequired, setIsDeposit] = useState<boolean>(true);

  const [currentLang, setCurrentLang] = useState<number>(0);

  const [create, { isSuccess: createSuccess }] =
    bonusesApi.useCreateBonusMutation();

  const [update, { isSuccess: updateSuccess }] =
    bonusesApi.useUpdateBonusMutation();
  const { data: bonus } = bonusesApi.useGetBonusQuery({ bonusId });
  const changeType = (type: BonusType) => {
    setBonusType(type);
  };

  const navigate = useNavigate();

  const onFinish = () => {
    const fieldsValues = fields.getFieldsValue();

    const bonusData: any = {
      type: fieldsValues.type,
      isActive,
      isDepositRequired,
      image: fieldsValues.image,
      name: fieldsValues.name,
      max_bet: fieldsValues?.max_bet,
      min_bet: fieldsValues?.min_bet,
      max_bonus: fieldsValues?.max_bonus,
      min_deposit: fieldsValues.min_deposit,
      wager_multiplier: fieldsValues.wager_multiplier,
      deposit_multiplier_percents: fieldsValues.deposit_multiplier_percents,
      spins_amount: fieldsValues.spins_amount,
    };

    if (mode === MODE.CREATE) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      create({ ...bonusData, endAt: new Date(fieldsValues?.endAt?.valueOf()) });
    }
    if (mode === MODE.EDIT) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      update({
        ...bonusData,
        //@ts-ignore
        id: bonus.id, endAt: new Date(fieldsValues?.endAt?.valueOf()),
      });
    }
  };

  useEffect(() => {
    if (mode === MODE.EDIT && bonus) {
      const parsedBonus = {
        ...bonus,
        endAt: dayjs(bonus.endAt),
      };
      fields.setFieldsValue(parsedBonus);

      setFieldsValue(parsedBonus);
      setIsActive(bonus.isActive);
      setBonusType(bonus.type);
      setIsDeposit(bonus.isDepositRequired);
    }
  }, [bonus]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate('..');
    }
  }, [createSuccess, updateSuccess]);

  return (
    <Form preserve form={fields} layout="vertical" onFinish={onFinish}>
      <Row>
        <Col>
          <Form.Item
            name="type"
            label="Type"
            preserve
            rules={[
              {
                required: true,
                message: 'Please select type',
              },
            ]}
          >
            <Select
              placeholder="Please select type"
              onChange={(e) => changeType(e)}
              options={Object.values(BonusType).map((type) => {
                return {
                  label: type,
                  value: type,
                };
              })}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="isActive" label="Active" preserve>
            <Checkbox
              name="isActive"
              checked={isActive}
              onChange={() => setIsActive(!isActive)}
            />
          </Form.Item>
        </Col>
      </Row>
      {bonusType === BonusType.freeSpins ? (
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="isDepositRequired"
              label="Is Deposit Required"
              preserve
            >
              <Checkbox
                name="isDepositRequired"
                checked={isDepositRequired}
                onChange={() => setIsDeposit(!isDepositRequired)}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        ''
      )}

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="image" label="Image" preserve>
            <ImageUploader />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {mode === MODE.CREATE && (
          <Col span={24}>
            <Localizations type={bonusType} isDeposit={isDepositRequired} />
          </Col>
        )}

        {mode === MODE.EDIT && fieldsValue && (
          <Col span={24}>
            <Localizations type={bonus.type} isDeposit={isDepositRequired} />
          </Col>
        )}
      </Row>
      <Row>
        <Form.Item name="endAt" label="End At" required={true} preserve>
          <DatePicker
            format={'YYYY-MM-DD HH:mm'}
            showTime
            disabledDate={(current) => moment().add(-1, 'days') >= current}
          />
        </Form.Item>
      </Row>
      <Button key="submit" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
