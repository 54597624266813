// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_label__lraKX {
  color: #fff;
  background-color: #d9b830;
  box-shadow: 0 2px 0 rgba(250, 190, 20, 0.1);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
}

.styles_pictureContainer__8A-q- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  padding-bottom: 20px;
}

.styles_newPicture__9UfLb {
  width: 300px;
  height: 300px;
  object-fit: scale-down;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/Games/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,2CAAA;EAEA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EAEA,uBAAA;EACA,mBAAA;EAEA,WAAA;EACA,aAAA;EAEA,oBAAA;AAHF;;AAMA;EACE,YAAA;EACA,aAAA;EAEA,sBAAA;AAJF","sourcesContent":[".label {\n  color: #fff;\n  background-color: #d9b830;\n  box-shadow: 0 2px 0 rgba(250, 190, 20, 0.1);\n\n  font-size: 14px;\n  height: 32px;\n  padding: 4px 15px;\n  border-radius: 6px;\n}\n\n.pictureContainer {\n  display: flex;\n  flex-direction: column;\n\n  justify-content: center;\n  align-items: center;\n\n  width: 100%;\n  height: 350px;\n\n  padding-bottom: 20px;\n}\n\n.newPicture {\n  width: 300px;\n  height: 300px;\n\n  object-fit: scale-down;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `styles_label__lraKX`,
	"pictureContainer": `styles_pictureContainer__8A-q-`,
	"newPicture": `styles_newPicture__9UfLb`
};
export default ___CSS_LOADER_EXPORT___;
