import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import { TWithdraw } from 'types/withdraws';

interface Response {
  data: TWithdraw[];
  meta: TMetaData;
}

export const withdrawsApi = createApi({
  reducerPath: 'withdrawsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Withdraw'],
  endpoints: (builder) => ({
    getAllWithdraws: builder.query<Response, TMeta>({
      query: (params) => ({
        url: `${API_ROUTES.WITHDRAW}/all`,
        params,
        method: 'GET',
      }),
      providesTags: ['Withdraw'],
    }),
    approveWithdraw: builder.mutation<TWithdraw, { id: string }>({
      query: (params) => ({
        url: `${API_ROUTES.WITHDRAW}/fulfilled`,
        body: { ...params },
        method: 'POST',
      }),
      invalidatesTags: ['Withdraw'],
    }),
    rejectWithdraw: builder.mutation<
      TWithdraw,
      { id: string; reason?: string }
    >({
      query: (params) => ({
        url: `${API_ROUTES.WITHDRAW}/reject`,
        body: { ...params },
        method: 'POST',
      }),
      invalidatesTags: ['Withdraw'],
    }),
  }),
});

export const { useGetAllWithdrawsQuery } = withdrawsApi;
