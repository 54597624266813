import React from 'react';
import { IPropsSVG } from 'types';
import polygonMaticImg from '../../img/PolygonMatic.png';

//TODO create vector
export const MaticLogo: React.FC<IPropsSVG> = ({
  width = 45,
  height = 45,
  fill = {
    primary: 'white',
    secondary: '#EC0928',
  },
  viewBox = '0 0 45 45',
  className,
  style,
}) => {
  return (
    <img
      width={width}
      height={height}
      style={style}
      className={className}
      src={polygonMaticImg}
    ></img>
  );
};
