/* eslint-disable function-paren-newline */
import React from 'react';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { promocodesApi } from 'store/services';

type Props = {
  setTab: (tab: string) => void;
  onFinish: () => void;
};

export const Options: React.FC<Props> = ({ setTab, onFinish }) => {
  const handleOptionsResult = () => {
    onFinish();

    setTab('result');
  };

  const { data: promocodes } =
    promocodesApi.useGetAllPromocodesWithoutPaginationQuery('');

  const newPromocodes = promocodes?.map(
    ({ id, name }: { id: string; name: string }) => ({
      label: name,
      value: id,
    })
  );

  return (
    <>
      <Form.Item
        name="name"
        label="Campaign Name"
        rules={[
          {
            required: true,
            message: 'Please enter campaign name',
          },
        ]}
      >
        <Input
          style={{ maxWidth: '350px' }}
          placeholder="Enter campaign name"
        />
      </Form.Item>

      <Form.Item
        name="commandId"
        label="Command Id"
        rules={[
          {
            required: true,
            message: 'Please enter command id',
          },
        ]}
      >
        <Input style={{ maxWidth: '350px' }} placeholder="Enter command id" />
      </Form.Item>

      <Form.Item
        name="geo"
        label="Geo"
        rules={[
          {
            required: true,
            message: 'Please enter geo',
          },
        ]}
      >
        <Input style={{ maxWidth: '350px' }} placeholder="Enter GEO" />
      </Form.Item>

      <Form.Item
        name="cpa"
        label="Command CPA"
        rules={[
          {
            required: true,
            message: 'Please enter command CPA',
          },
        ]}
      >
        <InputNumber
          min={1}
          style={{ width: '350px' }}
          placeholder="Enter command CPA"
        />
      </Form.Item>

      <Form.Item
        name="kapa"
        label="KAPA"
        rules={[
          {
            required: true,
            message: 'Please enter command KAPA',
          },
        ]}
      >
        <InputNumber
          style={{ width: '350px' }}
          min={1}
          placeholder="Enter command KAPA"
        />
      </Form.Item>

      <Form.Item name="promocodeId" label="Promocode">
        <Select
          mode="multiple"
          options={newPromocodes}
          style={{ width: '350px' }}
          placeholder="Select Promocode"
        />
      </Form.Item>

      <Button onClick={() => handleOptionsResult()}>Submit</Button>
    </>
  );
};
