/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */

import { Moment } from 'moment';
import { TDatesFormat } from 'types/statistic';
// import { RangePicker } from 'antd/es/date-picker/generatePicker/interface';
import moment from 'moment';
declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

type RangeValueMoment = RangeValue<Moment>;

export const convertDates = (
  dates: RangeValueMoment,
  date: Moment | null | undefined,
  type: TDatesFormat
) => {
  if (type !== 'day') {
    if (type === 'week') {
      return {
        from: dates?.[0]?.startOf(type).toISOString(),
        to: dates?.[1]?.endOf(type).toISOString(),
      };
    } else if (type === 'quarter') {
      return {
        from: dates?.[0]?.startOf(type).toISOString(),
        to: dates?.[1]?.endOf(type).toISOString(),
      };
    } else {
      return {
        from: dates?.[0]?.endOf('day').toISOString(),
        to: dates?.[1]?.endOf('day').toISOString(),
      };
    }
  } else {
    return {
      from: date?.startOf('day').toISOString(),
      to: date?.endOf('day').toISOString(),
    };
  }
};

export const cardSpan = {
  xs: 24,
  sm: 12,
  md: 8,
  lg: 6,
};

export enum EnumDateTypes {
  time = 'time',
  day = 'day',
  date = 'date',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

export const disabledDate: any = (
  current: Moment
) => {
  return moment(current).isAfter(moment());
};

export const disabledDatePlusOne: any = (
  current: Moment
) => {
  return moment(current).isAfter(moment().add(1, 'd'));
};
