import React from 'react';
import { IPropsSVG } from 'types';

export const SolanaLogo: React.FC<IPropsSVG> = ({
  width = 398,
  height = 312,
  fill = {
    primary: '#00FFA3',
    secondary: '#DC1FFF',
  },
  viewBox = '0 0 398 312',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1527_18080)">
        <path
          d="M64.6006 237.9C67.0006 235.5 70.3006 234.1 73.8006 234.1H391.201C397.001 234.1 399.901 241.1 395.801 245.2L333.101 307.9C330.701 310.3 327.401 311.7 323.901 311.7H6.50063C0.700627 311.7 -2.19937 304.7 1.90063 300.6L64.6006 237.9Z"
          fill="url(#paint0_linear_1527_18080)"
        />
        <path
          d="M64.6006 3.8C67.1006 1.4 70.4006 0 73.8006 0H391.201C397.001 0 399.901 7 395.801 11.1L333.101 73.8C330.701 76.2 327.401 77.6 323.901 77.6H6.50063C0.700627 77.6 -2.19937 70.6 1.90063 66.5L64.6006 3.8Z"
          fill="url(#paint1_linear_1527_18080)"
        />
        <path
          d="M333.101 120.1C330.701 117.7 327.401 116.3 323.901 116.3H6.50063C0.700627 116.3 -2.19937 123.3 1.90063 127.4L64.6006 190.1C67.0006 192.5 70.3006 193.9 73.8006 193.9H391.201C397.001 193.9 399.901 186.9 395.801 182.8L333.101 120.1Z"
          fill="url(#paint2_linear_1527_18080)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1527_18080"
          x1="360.88"
          y1="-37.4552"
          x2="141.214"
          y2="383.294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill.primary} />
          <stop offset="1" stopColor={fill.secondary} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1527_18080"
          x1="264.83"
          y1="-87.6014"
          x2="45.1636"
          y2="333.147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill.primary} />
          <stop offset="1" stopColor={fill.secondary} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1527_18080"
          x1="312.549"
          y1="-62.6879"
          x2="92.8828"
          y2="358.061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill.primary} />
          <stop offset="1" stopColor={fill.secondary} />
        </linearGradient>
        <clipPath id="clip0_1527_18080">
          <rect width="397.7" height="311.7" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
