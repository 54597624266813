import React from 'react';
import { IPropsSVG } from 'types';

export const USDTLogo: React.FC<IPropsSVG> = ({
  width = 25,
  height = 26,
  fill = {
    primary: '#00A478',
  },
  viewBox = '0 0 25 26',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M24.9326 13.3362C24.9326 20.2034 19.3657 25.7704 12.4986 25.7704C5.63137 25.7704 0.0644531 20.2034 0.0644531 13.3362C0.0644531 6.46908 5.63137 0.902161 12.4986 0.902161C19.3657 0.902161 24.9326 6.46908 24.9326 13.3362Z"
        fill={fill.primary}
      />
      <path
        d="M11.2219 13.1185V10.9432H8.27148V9.0365H16.516V10.9701H13.5656V13.1185H11.2219Z"
        fill={fill.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00391 13.3602C7.00391 12.6351 9.37523 12.0443 12.3256 12.0443C15.276 12.0443 17.6473 12.6351 17.6473 13.3602C17.6473 14.0853 15.276 14.6761 12.3256 14.6761C9.37523 14.6761 7.00391 14.0853 7.00391 13.3602ZM17.2061 13.3602C17.0131 13.0917 15.4138 12.2592 12.3256 12.2592C9.23736 12.2592 7.6381 13.0648 7.44508 13.3602C7.6381 13.6288 9.23736 14.0316 12.3256 14.0316C15.4414 14.0316 17.0131 13.6288 17.2061 13.3602Z"
        fill={fill.primary}
      />
      <path
        d="M13.5662 13.7899V12.286C13.1801 12.2592 12.7665 12.2323 12.3529 12.2323C11.9669 12.2323 11.6085 12.2323 11.25 12.2592V13.7631C11.5809 13.7631 11.9669 13.7899 12.3529 13.7899C12.7665 13.8168 13.1801 13.8168 13.5662 13.7899Z"
        fill={fill.primary}
      />
      <path
        d="M12.3256 14.6761C11.9396 14.6761 11.5811 14.6761 11.2227 14.6493V18.6508H13.5388V14.6224C13.1528 14.6493 12.7392 14.6761 12.3256 14.6761Z"
        fill={fill.primary}
      />
    </svg>
  );
};
