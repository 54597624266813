/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';

import classNames from 'classnames';
import { ExpandedTable } from 'components/Table/expandedTable';
import { DeleteCampaignAction } from '../utils/trafficActions';
import { prepareExpandedColumns } from '../utils/tableConfig';
import styles from '../Home/styles.module.scss';
import { expandedSummary } from '../utils/summary';

export const TitleElement = ({ title, value, isCopy }) => {
  if (value === undefined || value === null) return null;

  return (
    <div className={styles.titleElement}>
      <div className={styles.titleElementItem}>{title}</div>
      <div
        className={classNames(styles.titleElementItem, isCopy && styles.copy)}
        onClick={() => isCopy && navigator.clipboard.writeText(value || '')}
      >
        {value}
      </div>
    </div>
  );
};

export const ExpandedElement = ({
  name,
  geo,
  landing,
  generatedUrlToSite,
  generatedUrlToLanding,
  generatedUrlToRedirectLanding,
  numericId,
  traffics,
  kapa,
  cpa,
  id,
  promocodes,
  fetchTraffic,
  renderCell,
}) => {
  const expandedColumns = prepareExpandedColumns();
  const [isActive, setActive] = useState(false);

  return (
    <div key={id} className={styles.expandedContainer}>
      <div className={styles.expandedHeader}>
        <h2>{numericId}</h2>
        <button
          className={classNames(
            'ant-table-row-expand-icon',
            isActive
              ? 'ant-table-row-expand-icon-expanded'
              : 'ant-table-row-expand-icon-collapsed'
          )}
          onClick={() => setActive(!isActive)}
        />

        <div className={styles.titleTable}>
          <TitleElement title="Name" value={name} />
          <TitleElement title="Geo" value={geo} />

          <TitleElement title="Kapa" value={kapa} />
          <TitleElement title="CPA" value={cpa} />

          <TitleElement title="Landing" value={landing} />

          <TitleElement title="Casino Link" value={generatedUrlToSite} isCopy />
          <TitleElement
            title="Landing Link"
            value={generatedUrlToLanding}
            isCopy
          />
          <TitleElement
            title="Redirect Link"
            value={generatedUrlToRedirectLanding}
            isCopy
          />

          {promocodes && (
            <TitleElement
              isCopy
              title="Promocodes"
              value={promocodes.toString().replaceAll(',', ', ')}
            />
          )}

          <div className={styles.actions}>
            <DeleteCampaignAction id={id} fetchTraffic={fetchTraffic} />
          </div>
        </div>
      </div>

      {isActive && (
        <ExpandedTable
          meta={{
            currentPage: 1,
            total: traffics?.length,
            totalPages: traffics?.length / 10,
          }}
          data={traffics}
          columns={expandedColumns}
          hideSizeChanger={true}
          renderCell={renderCell}
          summary={(arr) => expandedSummary(arr)}
        />
      )}
    </div>
  );
};
