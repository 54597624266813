import { ColumnsType } from 'antd/es/table';
import { IUser } from '../../../types/users';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';
import { TBonus, TUserBonus } from '../../../types/bonuses';

export const columns: ColumnsType<TUserBonus> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Bonus',
    dataIndex: 'bonus',
    key: 'bonus.name',
    fixed: 'left',
    ellipsis: true,
    render: (bonus: TBonus) => bonus.name,
  },
  {
    title: 'Game name',
    dataIndex: 'game',
    key: 'game.name',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (value ? value.name : ''),
  },
  {
    title: 'Bonus amount',
    dataIndex: 'bonus_amount',
    key: 'bonus_amount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Wager required',
    dataIndex: 'wager_required',
    key: 'wager_required',
    fixed: 'left',
    ellipsis: true,
  },

  {
    title: 'Bonus state',
    dataIndex: 'state',
    key: 'state',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
