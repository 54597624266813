import React from 'react';
import { TabsProps } from 'antd';

import { IUser } from 'types/users';

import { CustomTable } from 'components/Table';
import { FullInfo } from 'components/Pages/Users/FullInfo';

import { columns as bansColumns } from '../subTables/bansTableConfig';
import { columns as bannedColumns } from '../subTables/bannedTableConfig';
import { columns as withdrawsColumns } from '../subTables/withdrawsConfig';
import { columns as sessionColumns } from '../subTables/sessionsTableConfig';
import { columns as transactionsColumns } from '../subTables/transactionsConfig';
import { columns as ticketsTransColumns } from '../subTables/ticketsTransConfig';
// import { columns as claimsColumns } from '../subTables/cashbackClaimsTableConfig';
import { columns as accrualsColumns } from '../subTables/cashbackAccrualsTableConfig';
// import { columns as gamesTransactionsColumns } from '../subTables/gamesTransaction';
import { columns as adminBalancesColumns } from '../subTables/userAdminBalancesConfig';
import { columns as columnsExchange } from '../subTables/exchangeTableConfig';
import {
  columnsReferral,
  columnsReferralTx,
  columns as referralsColumns,
} from '../subTables/referralTableConfig';

import { columns as sendTipsColumns } from '../../../../SendTips/utils/tableConfig';
import { columns as userChangesColumns } from '../../../../UserChanges/utils/tableConfig';
import { columns as userRakebackColumns } from '../../../../Rakeback/utils/userRakebackTableConfig';
import { columns as userPromocodesColumns } from '../../../../Promocodes/utils/userPromocodesTableConfig';
import { columns as userBonusesColumns } from '../../../../Bonuses/utils/userBonusesTableConfig';
import { columns as userDailyQuestsColumns } from '../../../../DailyQuest/utils/userDailyQuestsTableConfig';
import { columns as suspectsColumns } from '../../../../SuspiciousUsers/utils/tableConfig';
import { TableUserWrapper } from '../../TableWrapper';
import { GameTableWrapper } from '../../GameTableWrapper';

export const tableTabsConfig = (data: IUser): TabsProps['items'] => {
  const fullInfo = data;

  const bans = data?.bans || [];
  const banned = data?.banned || [];

  const userChanges = data?.userChanges || [];
  const userPromocodes = data?.userPromocodes || [];
  const userDailyQuests = data?.userDailyQuests || [];
  const rakebacks = data?.rakebacks || [];

  const accruals = data?.accruals || [];
  const userAdminBalances = data?.userAdminBalances || [];

  return [
    {
      key: '1',
      label: 'Info',
      children: <FullInfo data={fullInfo} />,
    },
    {
      key: '2',
      label: 'Suspects',
      children: (
        <TableUserWrapper
          columns={suspectsColumns}
          queryName={'useGetSuspectsQuery'}
        />
      ),
    },
    {
      key: '3',
      label: 'Bans',
      disabled: data && bans.length <= 0,
      children: (
        <CustomTable
          data={bans}
          columns={bansColumns(data.refetch)}
          loading={false}
        />
      ),
    },
    {
      key: '4',
      label: 'Banned',
      disabled: data && banned.length <= 0,
      children: (
        <CustomTable data={banned} columns={bannedColumns} loading={false} />
      ),
    },
    {
      key: '5',
      label: 'Sessions',
      children: (
        <TableUserWrapper
          columns={sessionColumns}
          queryName={'useGetSessionsQuery'}
        />
      ),
    },
    {
      key: '6',
      label: 'Tickets transactions',
      children: (
        <TableUserWrapper
          columns={ticketsTransColumns}
          queryName={'useGetTicketTransactionsQuery'}
        />
      ),
    },
    {
      key: '7',
      label: 'Games statistic',
      children: <GameTableWrapper />,
    },
    {
      key: '8',
      label: 'Deposits',
      children: (
        <TableUserWrapper
          columns={transactionsColumns}
          queryName={'useGetTransactionQuery'}
        />
      ),
    },
    {
      key: '9',
      label: 'Withdraws',
      children: (
        <TableUserWrapper
          columns={withdrawsColumns}
          queryName={'useGetWithdrawsQuery'}
        />
      ),
    },
    {
      key: '10',
      label: 'User changes',
      disabled: data && userChanges?.length <= 0,
      children: (
        <CustomTable
          hideSizeChanger
          meta={{
            currentPage: 1,
            totalPages: userChanges?.length,
            total: userChanges?.length,
            perPage: 10,
          }}
          data={userChanges}
          columns={userChangesColumns}
          loading={false}
        />
      ),
    },
    {
      key: '11',
      label: 'Send Tips',
      children: (
        <TableUserWrapper
          columns={sendTipsColumns}
          queryName={'useGetSendTipsQuery'}
        />
      ),
    },
    {
      key: '12',
      label: 'User promocodes',
      disabled: data && userPromocodes?.length <= 0,
      children: (
        <CustomTable
          hideSizeChanger
          meta={{
            currentPage: 1,
            totalPages: userPromocodes?.length,
            total: userPromocodes?.length,
            perPage: 10,
          }}
          data={userPromocodes}
          columns={userPromocodesColumns}
          loading={false}
        />
      ),
    },
    {
      key: '13',
      label: 'User Bonuses',
      children: (
        <TableUserWrapper
          columns={userBonusesColumns}
          queryName={'useGetUserBonusesQuery'}
        />
      ),
    },
    {
      key: '14',
      label: 'User Daily Quests',
      disabled: data && userDailyQuests?.length <= 0,
      children: (
        <CustomTable
          hideSizeChanger
          meta={{
            currentPage: 1,
            totalPages: userDailyQuests?.length,
            total: userDailyQuests?.length,
            perPage: 10,
          }}
          data={userDailyQuests}
          columns={userDailyQuestsColumns}
          loading={false}
        />
      ),
    },
    {
      key: '15',
      label: 'User Rakeback History',
      disabled: data && rakebacks.length <= 0,
      children: (
        <CustomTable
          hideSizeChanger
          meta={{
            currentPage: 1,
            totalPages: rakebacks.length,
            total: rakebacks.length,
            perPage: 10,
          }}
          data={rakebacks}
          columns={userRakebackColumns}
          loading={false}
        />
      ),
    },

    // {
    //   key: '16',
    //   label: 'Cashback Claims',
    //   disabled: data && claims.length <= 0,
    //   children: (
    //     <CustomTable
    //       hideSizeChanger
    //       meta={{
    //         currentPage: 1,
    //         totalPages: claims?.length,
    //         total: claims?.length,
    //         perPage: 10,
    //       }}
    //       data={claims}
    //       columns={claimsColumns}
    //       loading={false}
    //     />
    //   ),
    // },
    {
      key: '16',
      label: 'Cashback Accruals',
      disabled: data && accruals.length <= 0,
      children: (
        <CustomTable
          hideSizeChanger
          meta={{
            currentPage: 1,
            totalPages: accruals?.length,
            total: accruals?.length,
            perPage: 10,
          }}
          data={accruals}
          columns={accrualsColumns}
          loading={false}
        />
      ),
    },
    {
      key: '17',
      label: 'Referrals',
      children: (
        <TableUserWrapper
          columns={columnsReferral}
          queryName={'useGetReferralsQuery'}
        />
      ),
    },
    {
      key: '18',
      label: 'Referrals Сlaims',
      children: (
        <TableUserWrapper
          columns={referralsColumns}
          queryName={'useGetReferralsClaimsQuery'}
        />
      ),
    },
    {
      key: '19',
      label: 'Referrals Transactions',
      children: (
        <TableUserWrapper
          columns={columnsReferralTx}
          queryName={'useGetReferralsTransactionsQuery'}
        />
      ),
    },
    {
      key: '20',
      label: 'Exchanges',
      children: (
        <TableUserWrapper
          columns={columnsExchange}
          queryName={'useGetExchangesQuery'}
        />
      ),
    },
    {
      key: '21',
      label: 'Admin-Balance',
      disabled: data && userAdminBalances.length <= 0,
      children: (
        <CustomTable
          hideSizeChanger
          meta={{
            currentPage: 1,
            totalPages: userAdminBalances?.length,
            total: userAdminBalances?.length,
            perPage: 10,
          }}
          data={userAdminBalances}
          columns={adminBalancesColumns}
          loading={false}
        />
      ),
    },
  ];
};
