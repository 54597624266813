import React, { useEffect, useState } from 'react';
import {
  AuditOutlined,
  GoogleOutlined,
  IdcardOutlined,
  MailOutlined,
  NumberOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Space, Spin, Tag, Typography } from 'antd';
import { IUser } from 'types/users';
import { copyToClipboard, getStringWithDots } from 'utils';
import { isEmailVerified } from '../../../../api/user';

interface Props {
  data: IUser;
}

export const PopoverInfo: React.FC<Props> = ({ data }) => {
  console.log(`IS ACTIVE: ${data.isActive}`);
  if (!data) return <Spin />;

  return (
    <Space className="about-user pa-4" wrap>
      <Avatar
        size={'large'}
        shape="square"
        icon={<UserOutlined />}
        src={data?.avatarUrl}
      />
      <Typography.Text>{data?.username}</Typography.Text>
      {data?.campaign?.name && (
        <Tag color={'yellow'}>{data?.campaign?.name}</Tag>
      )}

      {data?.isSuspected && (
        <Tag color={'red'}>{data?.isSuspected && 'Suspected'}</Tag>
      )}

      <Tag
        icon={<NumberOutlined />}
        color={'geekblue-inverse'}
        onClick={() => copyToClipboard(data?.id)}
      >
        {getStringWithDots(data?.id)}
      </Tag>
      <Tag
        icon={<IdcardOutlined />}
        color={data?.role === 'admin' ? 'purple' : 'default'}
      >
        {data?.role}
      </Tag>
      <Tag icon={<MailOutlined />} color={data.isActive ? 'green' : 'red'}>
        email
      </Tag>
      <Tag
        icon={<AuditOutlined />}
        color={data?.verification ? 'green' : 'red'}
      >
        verification
      </Tag>
      <Tag icon={<GoogleOutlined />} color={data?.secret ? 'green' : 'red'}>
        google auth
      </Tag>
    </Space>
  );
};
