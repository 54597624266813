// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_chooseLanding_container__yjB1x {
  display: flex;
  gap: 15px;
  width: 350px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  height: 450px;
}
.styles_chooseLanding_item__5yFJ2 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.styles_chooseLanding_item__5yFJ2:hover > .styles_chooseLanding_item_canvas__TRjV8 {
  scale: 1.05;
}
.styles_chooseLanding_item_canvas__TRjV8 {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: 100px;
  height: 100px;
  background-color: #d9b830;
  object-fit: scale-down;
  object-position: 50% 50%;
  pointer-events: none;
}

.styles_title__OEuPe {
  font-size: 10px;
  line-height: 20px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Forms/Campaign/tabs/ChooseLanding/styles.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,SAAA;EAEA,YAAA;EAEA,eAAA;EAEA,uBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,aAAA;AAJJ;AAOE;EACE,eAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AANJ;AAQI;EACE,WAAA;AANN;AASI;EACE,yDAAA;EAEA,YAAA;EACA,aAAA;EAEA,yBAAA;EAEA,sBAAA;EACA,wBAAA;EACA,oBAAA;AAVN;;AAcA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAXF","sourcesContent":[".chooseLanding {\n  &_container {\n    display: flex;\n    gap: 15px;\n\n    width: 350px;\n\n    flex-wrap: wrap;\n\n    justify-content: center;\n    align-items: center;\n\n    overflow-y: scroll;\n    height: 450px;\n  }\n\n  &_item {\n    cursor: pointer;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    &:hover > &_canvas {\n      scale: (1.05);\n    }\n\n    &_canvas {\n      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n\n      width: 100px;\n      height: 100px;\n\n      background-color: #d9b830;\n\n      object-fit: scale-down;\n      object-position: 50% 50%;\n      pointer-events: none;\n    }\n  }\n}\n.title {\n  font-size: 10px;\n  line-height: 20px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chooseLanding_container": `styles_chooseLanding_container__yjB1x`,
	"chooseLanding_item": `styles_chooseLanding_item__5yFJ2`,
	"chooseLanding_item_canvas": `styles_chooseLanding_item_canvas__TRjV8`,
	"title": `styles_title__OEuPe`
};
export default ___CSS_LOADER_EXPORT___;
