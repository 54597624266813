import React from 'react';
import { IPropsSVG } from '../../../types';
import monero from '../../img/monero.png';

// TODO create vector graphic
export const MoneroLogo: React.FC<IPropsSVG> = ({
  width = 45,
  height = 45,
  className,
  style,
}) => {
  return (
    <img
      width={width}
      height={height}
      style={style}
      className={className}
      src={monero}
      alt="MONERO"
    ></img>
  );
};
