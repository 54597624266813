import React from 'react';
import { Link } from 'react-router-dom';
import {
  SnippetsOutlined,
  UserOutlined,
  DashboardOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  RollbackOutlined,
  EuroOutlined,
  FileProtectOutlined,
  StopOutlined,
  TagOutlined,
  LaptopOutlined,
  PartitionOutlined,
  ScheduleOutlined,
  FileImageOutlined,
  CloseCircleOutlined,
  DropboxOutlined,
  UserSwitchOutlined,
  TransactionOutlined,
  FrownOutlined,
  ThunderboltOutlined,
  ReadOutlined,
  FileOutlined,
  NodeExpandOutlined,
  VideoCameraOutlined,
  UsergroupDeleteOutlined,
  FileExclamationOutlined,
} from '@ant-design/icons';
import type { ItemType } from "antd/es/menu/interface";

import { paths } from 'pages/paths';

export const links: Array<ItemType> = [
  {
    icon: <DashboardOutlined />,
    label: <Link to={paths.HOME}>Home</Link>,
    key: paths.HOME,
  },
  {
    icon: <UserOutlined />,
    label: <Link to={paths.USERS}>Users</Link>,
    key: paths.USERS,
  },

  {
    icon: <LaptopOutlined />,
    label: <Link to={paths.GAMES}>Games</Link>,
    key: paths.GAMES,
  },

  {
    icon: <FileProtectOutlined />,
    label: <Link to={paths.VERIFICATIONS}>Verifications</Link>,
    key: paths.VERIFICATIONS,
  },

  {
    icon: <ReadOutlined />,
    label: 'News',
    key: 'news',
    children: [
      {
        icon: <SnippetsOutlined />,
        label: <Link to={paths.ARTICLES}>Articles</Link>,
        key: paths.ARTICLES,
      },
      {
        icon: <ContainerOutlined />,
        label: <Link to={paths.CATEGORIES}>Categories</Link>,
        key: paths.CATEGORIES,
      },
    ],
  },

  {
    icon: <ThunderboltOutlined />,
    label: 'Activities',
    key: 'activities',
    children: [
      {
        icon: <ScheduleOutlined />,
        label: <Link to={paths.DAILY}>Daily</Link>,
        key: paths.DAILY,
      },
      {
        icon: <TagOutlined />,
        label: <Link to={paths.PROMOCODES}>Promocodes</Link>,
        key: paths.PROMOCODES,
      },
      {
        icon: <DropboxOutlined />,
        label: <Link to={paths.BONUSES}>Bonuses</Link>,
        key: paths.BONUSES,
      },
    ],
  },

  {
    icon: <FileOutlined />,
    label: 'Reports',
    key: 'reports',
    children: [
      {
        icon: <DollarCircleOutlined />,
        label: <Link to={paths.DEPOSITS}>Deposits</Link>,
        key: paths.DEPOSITS,
      },
      {
        icon: <EuroOutlined />,
        label: <Link to={paths.WITHDRAWS}>Withdraws</Link>,
        key: paths.WITHDRAWS,
      },

      {
        icon: <FileExclamationOutlined />,
        label: <Link to={paths.TICKETS}>Tickets</Link>,
        key: paths.TICKETS,
      },
      {
        icon: <TransactionOutlined />,
        label: <Link to={paths.SEND_TIPS}>Send Tips</Link>,
        key: paths.SEND_TIPS,
      },
    ],
  },

  {
    icon: <NodeExpandOutlined />,
    label: 'Flow',
    key: 'flows',
    children: [
      {
        icon: <PartitionOutlined />,
        label: <Link to={paths.TRAFFIC}>Traffic</Link>,
        key: paths.TRAFFIC,
      },
      {
        icon: <FileImageOutlined />,
        label: <Link to={paths.LANDINGS}>Landings</Link>,
        key: paths.LANDINGS,
      },
    ],
  },
  {
    icon: <VideoCameraOutlined />,
    label: 'Tracking',
    key: 'tracking',
    children: [
      {
        icon: <UserSwitchOutlined />,
        label: <Link to={paths.USER_CHANGES}>User changes</Link>,
        key: paths.USER_CHANGES,
      },
      {
        icon: <FrownOutlined />,
        label: <Link to={paths.SUSPICIOUS_USERS}>Suspicious Users</Link>,
        key: paths.SUSPICIOUS_USERS,
      },
    ],
  },
  {
    icon: <UsergroupDeleteOutlined />,
    label: 'Bans',
    key: 'bans',
    children: [
      {
        icon: <StopOutlined />,
        label: <Link to={paths.COUNTRY_BLOCKER}>Banned countries</Link>,
        key: paths.COUNTRY_BLOCKER,
      },
      {
        icon: <CloseCircleOutlined />,
        label: <Link to={paths.BANS}>Banned users</Link>,
        key: paths.BANS,
      },
    ],
  },

  {
    icon: <RollbackOutlined />,
    label: <Link to={paths.LOGOUT}>Logout</Link>,
    key: paths.LOGOUT,
  },
];
