import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { TSession } from 'types/session';

export const columns: ColumnsType<TSession> = [
  {
    title: 'Ip',
    dataIndex: 'ip',
    key: 'ip',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Near',
    dataIndex: 'geo',
    key: 'geo',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Opened',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Last update',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) =>
      new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Is Active',
    dataIndex: 'isActive',
    key: 'isActive',
    fixed: 'left',
    ellipsis: true,
    render: (value) => value ? 'Active' : 'Disabled'
  }
];
