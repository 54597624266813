import React from "react";
import {IPropsSVG} from "../../../types";
import UAHLogo from "./UAHLogo";

const USDLogo: React.FC<IPropsSVG> = ({ width = 25, height = 26 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#262833"/>
            <path
                d="M29.8672 43.1016C27.5906 43.1016 25.6365 42.8107 24.0049 42.2289C22.386 41.6344 21.1212 40.7744 20.2106 39.6487C19.3126 38.5104 18.8067 37.1381 18.6929 35.5319L18.6739 35.2473H25.5037L25.5416 35.418C25.6555 35.8987 25.8958 36.3034 26.2626 36.6322C26.6294 36.9484 27.1353 37.1824 27.7803 37.3342C28.4253 37.486 29.2158 37.5619 30.1518 37.5619C31.0118 37.5619 31.7264 37.486 32.2956 37.3342C32.8774 37.1824 33.3137 36.9611 33.6046 36.6702C33.8955 36.3666 34.041 35.9935 34.041 35.5508V35.5129C34.041 34.9058 33.7817 34.4442 33.2631 34.128C32.7446 33.7991 31.7897 33.5082 30.3984 33.2553L26.8317 32.5913C25.0231 32.2498 23.5243 31.7375 22.3354 31.0545C21.1465 30.3589 20.2549 29.4862 19.6604 28.4365C19.0786 27.374 18.7877 26.1282 18.7877 24.699V24.6611C18.7877 23.0169 19.2367 21.5877 20.1347 20.3735C21.0327 19.1466 22.2975 18.198 23.929 17.5277C25.5606 16.8574 27.4768 16.5222 29.6775 16.5222C31.9541 16.5222 33.8892 16.8131 35.4828 17.3949C37.0891 17.9641 38.3412 18.8178 39.2392 19.9561C40.1372 21.0944 40.6684 22.5046 40.8329 24.1868L40.8708 24.5662H34.041L34.003 24.3955C33.8892 23.8516 33.6552 23.4089 33.3011 23.0675C32.9596 22.726 32.4916 22.473 31.8972 22.3086C31.3027 22.1442 30.5628 22.062 29.6775 22.062C28.8807 22.062 28.2167 22.1378 27.6854 22.2896C27.1542 22.4287 26.7495 22.6438 26.4713 22.9347C26.2057 23.2129 26.0729 23.567 26.0729 23.9971V24.035C26.0729 24.4397 26.1867 24.7939 26.4143 25.0974C26.642 25.3883 27.0025 25.635 27.4957 25.8373C28.0016 26.0397 28.6593 26.2168 29.4688 26.3685L33.1493 27.0515C35.9951 27.5827 38.0693 28.4554 39.372 29.6696C40.6748 30.8838 41.3261 32.547 41.3261 34.6592V34.6971C41.3261 36.4425 40.8645 37.9413 39.9412 39.1934C39.0305 40.4456 37.7152 41.4131 35.9951 42.0961C34.2876 42.7664 32.245 43.1016 29.8672 43.1016ZM29.2411 46.2509V13.3729H30.7589V46.2509H29.2411Z"
                fill="#FFCC00"/>
        </svg>
    );
}

export default USDLogo