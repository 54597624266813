// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_paginationContainer__UEOca {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.styles_sorted__seDx- {
  color: #d9b830;
}

.styles_pointer__xoAcR {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Games/Home/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,aAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".paginationContainer {\n  width: 100%;\n\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 5px;\n}\n\n.sorted {\n  color: #d9b830;\n}\n\n.pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `styles_paginationContainer__UEOca`,
	"sorted": `styles_sorted__seDx-`,
	"pointer": `styles_pointer__xoAcR`
};
export default ___CSS_LOADER_EXPORT___;
