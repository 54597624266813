import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Row, Select } from 'antd';
import { getFreespinsGames, getProviders } from '../../../api/games';
import { CustomTable } from '../../Table';
import { getColumns } from '../../../pages/Bonuses/utils/gamesAddTableConfig';

interface Props {
  open: boolean;
  bonusId: string;
  closeHandler: () => void;
}

export const AddBonusGameModal: React.FC<Props> = ({
  open,
  bonusId,
  closeHandler,
}) => {
  const [fields] = Form.useForm();
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPerPage] = useState<number>(15);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [gameName, setGameName] = useState('');
  const [games, setGames] = useState([]);

  const onFilterProviderChanged = (value: string) => {
    setSelectedProvider(value);
  };

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await getProviders('all');

      setProviders(response);
    };

    fetchProviders().then();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      const games = await getFreespinsGames({ provider: selectedProvider });

      setGames(games);
    };

    fetchGames().then();
  }, [selectedProvider]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const games = await getFreespinsGames({
        provider: selectedProvider,
        name: gameName,
      });

      setGames(games);
    }, 500); // Adjust the delay time as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [gameName]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleInputChange = (event) => {
    setGameName(event?.target?.value);
  };

  return (
    <Modal
      title={'Add Bonus Game'}
      open={open}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={null}
    >
      <Form form={fields} layout="vertical">
        <Row gutter={16}>
          <Form.Item name="provider" label="Game Provider">
            <Select
              options={providers.map(({ label }) => ({ value: label, label }))}
              style={{ width: '180px' }}
              placeholder="Select Provider"
              onChange={onFilterProviderChanged}
            />
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Form.Item name="name" label="Game Name">
            <Input
              placeholder="Enter game name"
              disabled={!selectedProvider}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Row>
        {games && (
          <CustomTable
            loading={!games.length}
            columns={getColumns(bonusId)}
            data={games}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
          />
        )}
      </Form>
    </Modal>
  );
};
