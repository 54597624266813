import moment from 'moment';
import { ICardConfig } from 'types/grid';
import { TSession } from 'types/session';

export const firstSession: ICardConfig<TSession> = {
  key: 'first-visit',
  title: 'Registration',
  className: 'user-info-card',
  grids: [
    {
      title: 'Account existence period',
      dataIndex: 'createdAt',
      render: (record) => moment(record.createdAt).fromNow().replace('ago', ''),
    },
    {
      title: 'Registration date',
      dataIndex: 'createdAt',
      render: (record) =>
        moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Device',
      dataIndex: 'browser',
      render: (record) => record.browser,
    },
    {
      title: 'Registration IP address',
      dataIndex: 'ip',
      render: (record) => record.ip,
    },
    {
      title: 'Geo',
      dataIndex: 'geo',
      render: (record) => record.geo,
    },
  ],
};
