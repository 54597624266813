/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import React from 'react';

import blockchainIconByName from 'assets/svg/blockchains';
import { TUserAdminBalance } from 'types/userAdminBalance';

export const columns: ColumnsType<TUserAdminBalance> = [
  {
    title: 'Admin',
    dataIndex: 'admin',
    key: 'admin',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    ellipsis: true,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
  },
  {
    title: 'Blockchain',
    dataIndex: 'blockchain',
    key: 'blockchain',
    ellipsis: true,
    render: (v) => blockchainIconByName(v),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    ellipsis: true,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    render: (value) => moment(value).fromNow(),
  },
];
