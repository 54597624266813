import { ColumnsType } from 'antd/es/table';
import blockchainIconByName from 'assets/svg/blockchains';
import moment from 'moment';
import { TExchange } from 'types/exchange';

export const columns: ColumnsType<TExchange> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value) => value?.username,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Blockchain',
    dataIndex: 'blockchain',
    key: 'blockchain',
    fixed: 'left',
    ellipsis: true,
    render: (v) => blockchainIconByName(v),
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'AmountTo',
    dataIndex: 'amountReceived',
    key: 'amountReceived',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'BlockchainTo',
    dataIndex: 'blockchainTo',
    key: 'blockchainTo',
    fixed: 'left',
    ellipsis: true,
    render: (v) => blockchainIconByName(v),
  },
  {
    title: 'CurrencyTo',
    dataIndex: 'currencyTo',
    key: 'currencyTo',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY-MM-DD hh-mm-ss'),
  },
];
