/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta } from 'types';
import { IArticle, IGetArticleParams } from 'types/articles';

interface Response {
  articles: IArticle[];
  newArticlesCount: number;
}

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Article'],
  endpoints: (builder) => ({
    getArticle: builder.query<any, string>({
      query: (params) => ({
        url: `${API_ROUTES.ARTICLES}/${params}`,
      }),
      providesTags: ['Article'],
    }),
    getAllArticles: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.ARTICLES,
        params,
      }),
      providesTags: ['Article'],
    }),
    createArticle: builder.mutation<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.ARTICLES}/new/add`,
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['Article'],
    }),
    deleteArticle: builder.mutation<any, IGetArticleParams>({
      query: (params) => ({
        url: `${API_ROUTES.ARTICLES}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Article'],
    }),
    updateArticle: builder.mutation<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.ARTICLES}/new/${params.id}`,
        body: params,
        method: 'PATCH',
      }),
      invalidatesTags: ['Article'],
    }),
  }),
});

export const { useGetAllArticlesQuery } = articlesApi;
