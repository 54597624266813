/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';

interface Response {
  data: any[];
  meta: TMetaData;
}

export const bonusesApi = createApi({
  reducerPath: 'bonusesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Bonus'],
  endpoints: (builder) => ({
    getAllBonuses: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.BONUSES,
        params,
      }),
      providesTags: ['Bonus'],
    }),
    getBonus: builder.query({
      query: (params) => ({
        url: `${API_ROUTES.BONUSES}/bonus-by-id`,
        params,
      }),
      providesTags: ['Bonus'],
    }),
    updateBonus: builder.mutation<any, any>({
      query: (params) => ({
        url: `${API_ROUTES.BONUSES}/${params.id}`,
        body: params,
        method: 'PATCH',
      }),
      invalidatesTags: ['Bonus'],
    }),
    deleteBonus: builder.mutation<Response, any>({
      query: (data) => ({
        url: API_ROUTES.BONUSES,
        body: data,
        method: 'DELETE',
      }),
      invalidatesTags: ['Bonus'],
    }),
    toggleBonus: builder.mutation<Response, any>({
      query: (data) => ({
        url: `${API_ROUTES.BONUSES}/toggle`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Bonus'],
    }),
    createBonus: builder.mutation<Response, any>({
      query: (data) => {
        return {
          url: API_ROUTES.BONUSES,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Bonus'],
    }),
    addBonusGame: builder.mutation<Response, any>({
      query: (data) => {
        return {
          url: `${API_ROUTES.BONUSES}/add-game`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Bonus'],
    }),
    removeBonusGame: builder.mutation<Response, any>({
      query: (data) => {
        return {
          url: `${API_ROUTES.BONUSES}/remove-game`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Bonus'],
    }),
  }),
});

export const { useGetAllBonusesQuery } = bonusesApi;
