import React from 'react';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { articlesApi } from 'store/services/articles';

interface Props {
  id: string;
}

export const ArticleActions: React.FC<Props> = ({ id }) => {
  const [deleteArticle] = articlesApi.useDeleteArticleMutation();

  return (
    <Space>
      <Link to={`edit/${id}`}>
        <Button type="primary"> Edit</Button>
      </Link>
      <Button type="primary" danger onClick={() => deleteArticle({ id })}>
        Delete
      </Button>
    </Space>
  );
};
