import React from 'react';
import moment from 'moment';
import { TReferralClaims, TReferralTx } from 'types/referral';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { TUser } from 'types/auth';

export const columnsReferral: ColumnsType<TUser> = [
  {
    title: 'User',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    ellipsis: true,
    render: (value, record) => (
      <Link to={`/users/info/${value}`}>{record.username}</Link>
    ),
  },
  {
    title: 'Campaign',
    dataIndex: 'referralCampaign',
    key: 'referralCampaign',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Balance',
    dataIndex: 'referralBalance',
    key: 'referralBalance',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
];

export const columns: ColumnsType<TReferralClaims> = [
  {
    title: 'Referral',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <Link to={`/users/info/${value?.id}`}>{value.username}</Link>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
];

export const columnsReferralTx: ColumnsType<TReferralTx> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <Link to={`/users/info/${value?.id}`}>{value.username}</Link>
    ),
  },
  {
    title: 'Partner',
    dataIndex: 'partner',
    key: 'partner',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <Link to={`/users/info/${value?.id}`}>{value.username}</Link>
    ),
  },
  {
    title: 'Round ID',
    dataIndex: 'roundId',
    key: 'roundId',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Wager',
    dataIndex: 'wagerAmount',
    key: 'wagerAmount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Commission',
    dataIndex: 'commissionAmount',
    key: 'commissionAmount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Campaign',
    dataIndex: 'campaign',
    key: 'campaign',
    fixed: 'left',
    ellipsis: true,
    render: (v) => v.name,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
];
