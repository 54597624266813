import React, { useEffect } from 'react';
import local from 'hooks/local';
import { Spin } from 'antd';

export const Logout = () => {
  const storage = local;
  const storageToken = storage.local<string>('token', { stringify: false });

  useEffect(() => {
    storageToken.remove();
  });

  return <Spin />;
};
