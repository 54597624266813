import React from 'react';
import { Modal } from 'antd';
import { GameEditActive } from 'components/Forms/Games/GameEditActive';

interface Props {
  open: boolean;
  closeHandler: (onOk: boolean) => void;
}

export const GamesGroupActiveUpdateModal: React.FC<Props> = ({
  open,
  closeHandler,
}) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  return (
    <>
      <Modal
        title={'Restricted Update'}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <GameEditActive closeHandler={closeHandler} />
      </Modal>
    </>
  );
};
