import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from '../interceptor';
import { API_ROUTES } from '../../api/routes';

export const promocodesApi = createApi({
  reducerPath: 'promocodesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Promocode'],
  endpoints: (builder) => ({
    getAllPromocodes: builder.query({
      query: (params) => ({
        url: API_ROUTES.PROMOCODES,
        params,
      }),
      providesTags: ['Promocode'],
    }),
    togglePromocode: builder.mutation({
      query: (data) => ({
        url: `${API_ROUTES.PROMOCODES}/toggle`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Promocode'],
    }),
    getAllPromocodesWithoutPagination: builder.query({
      query: () => ({
        url: `${API_ROUTES.PROMOCODES}/all`,
      }),
      providesTags: ['Promocode'],
    }),
    deletePromocode: builder.mutation({
      query: (data) => ({
        url: `${API_ROUTES.PROMOCODES}/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Promocode'],
    }),
    getPromocode: builder.query({
      query: (id) => ({
        url: `${API_ROUTES.PROMOCODES}/info/${id}`,
      }),
      providesTags: ['Promocode'],
    }),
    updatePromocode: builder.mutation({
      query: (data) => ({
        url: `${API_ROUTES.PROMOCODES}/${data.id}`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Promocode'],
    }),
    createPromocode: builder.mutation({
      query: (data) => {
        return {
          url: API_ROUTES.PROMOCODES,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Promocode'],
    }),
  }),
});
