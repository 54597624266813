import { Col, Form, Input, Row } from 'antd';
import Button from 'antd/es/button';
import React, { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { languages } from '../../Articles/Localizations/utils/languages';
import { TLang } from 'types';
import { BonusType } from '../index';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Localizations: React.FC<{
  type: BonusType | null;
  isDeposit: boolean;
}> = ({ type, isDeposit }: { type: BonusType | null; isDeposit: boolean }) => {
  const [currentLang, setCurrentLang] = useState<number>(0);

  return (
    <>
      <Row gutter={6} style={{ margin: '12px 0' }}>
        {languages.map((lang: TLang, index: number) => (
          <Col key={`text-lang-${lang.tag}`}>
            <Button
              onClick={() => setCurrentLang(index)}
              type={currentLang === index ? 'primary' : 'default'}
            >
              {lang.label}
            </Button>
          </Col>
        ))}
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'wager_multiplier'}
            label="Required wager(times) until withdrawal allowed"
            rules={[
              {
                required: true,
                message: 'Please enter required wager',
              },
            ]}
          >
            <Input placeholder="Please enter wager" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'name'}
            label="Bonus name"
            rules={[
              {
                required: true,
                message: 'Please enter required name',
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Col>
      </Row>
      {isDeposit && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'min_deposit'}
              label="Minimal deposit to trigger bonus"
              rules={[
                {
                  required: true,
                  message: 'Please enter minimal deposit',
                },
              ]}
            >
              <Input placeholder="Please enter min. deposit" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'min_bet'}
            label="Minimal bet to wager"
            rules={[
              {
                required: false,
                message: 'Please enter minimal bet',
              },
            ]}
          >
            <Input placeholder="Please enter min. bet" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'max_bet'}
            label="Maximal bet to wager"
            rules={[
              {
                required: false,
                message: 'Please enter maximal bet',
              },
            ]}
          >
            <Input placeholder="Please enter max. bet" />
          </Form.Item>
        </Col>
      </Row>
      {type === BonusType.freeSpins && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'spins_amount'}
              label="Amount of user freespins"
              rules={[
                {
                  required: type === BonusType.freeSpins,
                  message: 'Please enter amount of freespins',
                },
              ]}
            >
              <Input placeholder="Please enter freespins amount" />
            </Form.Item>
          </Col>
        </Row>
      )}
      {type === BonusType.depositBonus && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'deposit_multiplier_percents'}
              label="User deposit multiplier(%)"
              rules={[
                {
                  required: type === BonusType.depositBonus,
                  message: 'User deposit multiplier(%)',
                },
              ]}
            >
              <Input placeholder="Please enter deposit multiplier" />
            </Form.Item>
          </Col>
        </Row>
      )}
      {type === BonusType.depositBonus && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'max_bonus'}
              label="Maximal bonus amount"
              rules={[
                {
                  required: false,
                  message: 'Please enter maximal freespins amount',
                },
              ]}
            >
              <Input placeholder="Please enter max. freespins amount" />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};
