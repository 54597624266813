import { VerificationModal } from 'components/Modals/VerificationModal';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

export const VerificationsEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const closeHandler = () => {
    navigate('..');
  };

  return (
    <>
      <Outlet />
      <VerificationModal
        open={true}
        closeHandler={closeHandler}
        id={id || ''}
      />
    </>
  );
};
