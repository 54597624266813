import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { TTicketsTransaction } from 'types/tickets-transactions';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';

type Response = [
  { items: TTicketsTransaction[]; meta: TMetaData },
  TTicketsTransaction[],
];

export const ticketsApi = createApi({
  reducerPath: 'tickets',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Tickets'],
  endpoints: (builder) => ({
    getAll: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.TICKETS_TX,
        params,
      }),
      providesTags: ['Tickets'],
    }),
  }),
});

export const { useGetAllQuery } = ticketsApi;
