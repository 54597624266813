// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_balanceItem__F2W6K {
  position: relative;
}

.styles_active__-2t9o {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s all;
  cursor: pointer;
}
.styles_active__-2t9o:hover {
  transform: scale(1.1);
}

.styles_hide__ktQfI {
  position: absolute;
  left: 5%;
  top: -7%;
  background-color: rgba(0, 0, 0, 0.366);
  width: 90%;
  height: 105%;
  z-index: 10;
  padding: 5px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Users/Balance/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,sDAAA;EACA,eAAA;AACF;AACE;EACE,qBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EAEA,sCAAA;EAEA,UAAA;EACA,YAAA;EAEA,WAAA;EAEA,YAAA;EAEA,kBAAA;AALF","sourcesContent":[".balanceItem {\n  position: relative;\n}\n\n.active {\n  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s all;\n  cursor: pointer;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n\n.hide {\n  position: absolute;\n  left: 5%;\n  top: -7%;\n\n  background-color: rgba(0, 0, 0, 0.366);\n\n  width: 90%;\n  height: 105%;\n\n  z-index: 10;\n\n  padding: 5px;\n\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balanceItem": `styles_balanceItem__F2W6K`,
	"active": `styles_active__-2t9o`,
	"hide": `styles_hide__ktQfI`
};
export default ___CSS_LOADER_EXPORT___;
