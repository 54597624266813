import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { ICardConfig } from 'types/grid';
import { StatusColorsEnum, TVerification } from 'types/verification';

export const personalInfoConfig: ICardConfig<TVerification> = {
  key: 'personal-card',
  title: 'Personal info',
  className: 'user-info-card',
  grids: [
    {
      title: 'First name',
      dataIndex: 'firstName',
      render: (record) => record.firstName,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      render: (record) => record.lastName,
    },
    {
      title: 'Middle name',
      dataIndex: 'middleName',
      render: (record) => record.middleName,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      render: (record) => record.gender,
    },
    {
      title: 'Birthday',
      dataIndex: 'birthdayDate',
      render: (record) => moment(record.birthdayDate).format('YYYY-MM-DD'),
    },
    {
      title: 'Age',
      dataIndex: 'birthdayDate',
      render: (record) =>
        moment(record.birthdayDate).fromNow().replace('ago', ''),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (record) => (
        <Tag color={StatusColorsEnum[record.status]}>{record.status}</Tag>
      ),
    },
  ],
};
