import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { BannedCountryActions } from './bannedCountryActions';
import { IBannedCountry } from 'types/banned-country';
import moment from 'moment';

export const columns: ColumnsType<IBannedCountry> = [
  {
    title: 'Country code',
    dataIndex: 'countryCode',
    key: 'countryCode',
    ellipsis: true,
  },
  {
    title: 'Country',
    dataIndex: 'countryName',
    key: 'countryName',
    ellipsis: true,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: (value: Date) => moment(value).fromNow(),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    ellipsis: true,
    render: (value: IBannedCountry) => <BannedCountryActions id={value.id} />,
  },
];
