import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import { TDeposit } from 'types/deposits';

interface Response {
  data: TDeposit[];
  meta: TMetaData;
}

export const depositsApi = createApi({
  reducerPath: 'depositsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Deposit'],
  endpoints: (builder) => ({
    getAllDeposits: builder.query<Response, TMeta>({
      query: (params) => ({
        url: `${API_ROUTES.HISTORY}/all`,
        params: {
          ...params,
          type: 'Deposit',
        },
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllDepositsQuery } = depositsApi;
