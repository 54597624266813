import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from '../interceptor';
import { API_ROUTES } from '../../api/routes';

export const dailyQuestsApi = createApi({
  reducerPath: 'dailyQuestsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['DailyQuest'],
  endpoints: (builder) => ({
    getAllDailyQuests: builder.query({
      query: (params) => ({
        url: `${API_ROUTES.DAILY_QUEST}/list`,
        params,
      }),
      providesTags: ['DailyQuest'],
    }),
    deleteDailyQuest: builder.mutation({
      query: (data) => ({
        url: `${API_ROUTES.DAILY_QUEST}`,
        body: data,
        method: 'DELETE',
      }),
      invalidatesTags: ['DailyQuest'],
    }),
    updateDailyQuest: builder.mutation({
      query: (data) => ({
        url: `${API_ROUTES.DAILY_QUEST}/${data.id}`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['DailyQuest'],
    }),
    getDailyQuest: builder.query({
      query: (data) => ({
        url: `${API_ROUTES.DAILY_QUEST}/info/${data}`,
      }),
      providesTags: ['DailyQuest'],
    }),
    createDailyQuest: builder.mutation({
      query: (data) => ({
        url: API_ROUTES.DAILY_QUEST,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['DailyQuest'],
    }),
  }),
});
