import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import { TBan, TBanUser } from 'types/bans';

interface Response {
  data: TBan[];
  meta: TMetaData;
}

export const bansApi = createApi({
  reducerPath: 'bansApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Ban'],
  endpoints: (builder) => ({
    getAllBans: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.BANS,
        params,
      }),
      providesTags: ['Ban'],
    }),
    unban: builder.mutation<TBanUser, string>({
      query: (params) => ({
        url: `${API_ROUTES.BAN_USER}`,
        body: {
          params,
        },
        method: 'DELETE',
      }),
      invalidatesTags: ['Ban'],
    }),
  }),
});

export const { useGetAllBansQuery } = bansApi;
