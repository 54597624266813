import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import {
  IUpdateVerificationParams,
  TGetVerificationParams,
  TVerification,
} from 'types/verification';

interface Response {
  data: TVerification[];
  meta: TMetaData;
}

export const verificationsApi = createApi({
  reducerPath: 'verificationsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Verification'],
  endpoints: (builder) => ({
    getAllVerifications: builder.query<Response, TMeta>({
      query: (params) => ({
        url: `${API_ROUTES.VERIFICATIONS}`,
        params,
      }),
      providesTags: ['Verification'],
      transformResponse: (rawResult: Response) => {
        const { data, meta } = rawResult;
        const newData = data.map((i, index) => ({
          ...i,
          number: index + (meta.currentPage - 1) * meta.perPage + 1,
        }));

        return {
          ...rawResult,
          data: newData,
        };
      },
    }),
    getVerification: builder.query<TVerification, TGetVerificationParams>({
      query: (params) => ({
        url: `${API_ROUTES.VERIFICATIONS}/${params.id}`,
      }),
    }),
    updateVerificationStatus: builder.mutation<
      TVerification,
      IUpdateVerificationParams
    >({
      query: (params) => ({
        url: `${API_ROUTES.VERIFICATIONS}/${params.id}`,
        body: params.data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Verification'],
    }),
  }),
});

export const { useGetAllVerificationsQuery } = verificationsApi;
