import { TUser } from './auth';

export interface IUpdateVerificationParams {
  id: string;
  data: {
    status: VerificationStatusEnum;
    message?: string;
  };
}

export enum GendersEnum {
  Male = 'Male',
  Female = 'Female',
  Transgender = 'Transgender',
}

export enum VerificationStatusEnum {
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

export enum StatusColorsEnum {
  REJECTED = 'red',
  ACCEPTED = 'green',
  REVIEW = 'yellow',
}

export type TVerification = {
  number?: number;
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string
  middleName: string;
  birthdayDate: string;
  gender: GendersEnum;
  country: string;
  city: string;
  street: string;
  house: number;
  apartment: number;
  zipCode: number;
  selfieImgUrl: string;
  driveLicenseImgUrlFirstStep?: string | null;
  driveLicenseImgUrlSecondStep?: string | null;
  idImgUrlFirstStep?: string | null;
  idImgUrlSecondStep?: string | null;
  passportImgUrl?: string | null;
  message: string | null;
  user: TUser;
  admin: TUser | null;
  status: VerificationStatusEnum;
  createdAt: string | null;
  updatedAt: string | null;
};

export type TGetVerificationParams = {
  id: string;
};
