import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Withdraws } from '.';
import { WithdrawsHome } from './Home';

type Props = {
  redirectRoute?: string;
};

export const WithdrawsRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Withdraws>
            <Outlet />
          </Withdraws>
        }
      >
        <Route path={'/'} element={<WithdrawsHome />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
