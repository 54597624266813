import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { API_ROUTES, BASE_URL } from 'api/routes';
import { paths } from 'pages/paths';
import local from 'hooks/local';
import { message } from 'antd';

export interface ErrorData {
  message: string | string[];
}

export interface ErrorWithMessage {
  data: ErrorData;
  status: number;
}

const mutex = new Mutex();
const storage = local;
const storageToken = storage.local<string>('token', { stringify: false });

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${storageToken.get()}`);
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const refreshResult = await baseQuery(
            API_ROUTES.REFRESH,
            api,
            extraOptions
          );

          if (refreshResult.data) {
            const data = refreshResult.data as { token: string };

            storageToken.set(data.token);

            result = await baseQuery(args, api, extraOptions);
          } else {
            window.location.href = paths.LOGOUT;
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();

        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      const e = result.error as ErrorWithMessage;

      console.log(e);

      if (Array.isArray(e.data.message)) {
        const errors = e.data.message.join('.');

        message.open({
          type: 'error',
          content: errors,
        });
      } else {
        message.open({
          type: 'error',
          content: e.data.message,
        });
      }
    }
  }

  return result;
};
