import React from 'react';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';

interface Props {
  id: number;
}

export const GameActions: React.FC<Props> = ({ id }) => {
  return (
    <Space>
      <Button>
        <Link to={`update-one?id=${id}`}>Update Restricted</Link>
      </Button>

      <Button type="primary">
        <Link to={`update-one-meta?id=${id}`}>Edit</Link>
      </Button>
    </Space>
  );
};
