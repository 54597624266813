/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_ROUTES, BASE_URL } from 'api/routes';
import { TMeta } from 'types';
import { $api } from 'api';

export const addGame = async (params: any) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.CATEGORIES}/add`,
    params
  );

  return data;
};

export const getGames = async (params?: TMeta) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.GAMES}/list-sort-by`,
    {
      params,
    }
  );

  return data;
};

export const getOneGame = async (params?: TMeta) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.GAMES}/get-one-game`,
    {
      params,
    }
  );

  return data;
};

export const getFreespinsGames = async (params: TMeta) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.GAMES}/list/freespins`,
    {
      params,
    }
  );

  return data;
};

export const getProviders = async (type: string) => {
  const { data } = await $api.get(`${BASE_URL}/games/providers`, {
    params: { type },
  });

  return data;
};

export const editGame = async (payload: {
  id: number;
  hot?: string;
  top?: string;
  new?: string;
  active?: string;
  restricted?: string[];
}) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.GAMES}/edit`,
    payload
  );

  return data;
};

export const editGameMeta = async (payload: {
  id: number;
  image?: any;
  name?: string;
}) => {
  await $api.post(
    `${BASE_URL}/${API_ROUTES.GAMES}/edit-image?id=${payload.id}`,
    payload.image
  );

  await $api.post(`${BASE_URL}/${API_ROUTES.GAMES}/edit-name`, {
    id: payload.id,
    name: payload.name,
  });
};

export const updateBulkGames = async (payload: {
  text?: string[];
  type?: string;
  search?: string;
  toggle?: boolean;
  countriesCodes?: string[];
  provider?: string;
}) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.GAMES}/update-bulk`,
    payload
  );

  return data;
};

export const changeGamesStatus = async (payload: {
  type?: string;
  provider?: string;
  isRemove?: boolean;
}) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.GAMES}/change-games-status`,
    payload
  );

  return data;
};

export const deleteGame = async (params: any) => {
  const { data } = await $api.delete(
    `${BASE_URL}/${API_ROUTES.CATEGORIES}/${params.id}`
  );

  return data;
};

export const getRestricted = async (id: string | null, filters: any) => {
  const { data } = await $api.get(`${BASE_URL}/games/restricted`, {
    params: { id, provider: filters.provider },
  });

  return data;
};
