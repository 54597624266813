import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import { getBase64 } from 'utils';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import type { UploadProps } from 'antd/es/upload';
import type {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/es/upload/interface';
import { awsApi } from 'store/services';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export const ImageUploader: React.FC<Props> = ({ value = '', onChange }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [upload] = awsApi.useUploadMutation();

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview && !file.thumbUrl) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || file.thumbUrl || file?.preview || '');
    setPreviewOpen(true);
  };

  const uploadAction = async (info: UploadRequestOption) => {
    const { file, onSuccess, onError } = info;
    const formData = new FormData();

    formData.append('file', file);

    await upload(formData)
      .unwrap()
      .then((data) => {
        onSuccess?.(data);
      })
      .catch((e) => {
        console.log(e);

        onError?.(e);
      });
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam) => {
    setFileList(info.fileList);

    if (info.file.status === 'done') {
      onChange?.(info.file.response);
      message.success('Image uploaded');
    }
    if (info.file.status === 'error') {
      onChange?.('');
      message.error('Image upload error');
    }
    if (info.file.status === 'removed') {
      onChange?.('');
      message.success('Image removed');
    }
  };

  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: '0000',
          name: 'image',
          status: 'done',
          url: value,
        },
      ]);
    }
  }, [value]);

  return (
    <>
      <Upload
        customRequest={uploadAction}
        maxCount={1}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length < 1 && <PlusOutlined />}
      </Upload>
      <Modal
        open={previewOpen}
        title={'Image'}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt={'Preview'} className={'w-100'} src={previewImage} />
      </Modal>
    </>
  );
};
