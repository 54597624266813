import React, { useState } from 'react';
import { Typography } from 'antd';
import { usersApi } from 'store/services';
import { IUser } from 'types/users';

interface Props {
  id: string;
  value: keyof IUser;
  initial: string;
  copyable?: boolean;
  trigger?: 'icon' | 'text';
  afterText?: string;
}

export const ChangeData: React.FC<Props> = ({
  id,
  value,
  initial,
  copyable,
  trigger = 'icon',
  afterText,
}) => {
  const [changeData] = usersApi.useChangeDataMutation();
  const [text, setText] = useState<string>(initial);

  const onChange = async (newValue: string) => {
    if (newValue === text) return;
    const newData = {
      id,
      data: {
        [value]: newValue,
      },
    };
    await changeData(newData)
      .unwrap()
      .then(() => {
        setText(afterText ? afterText : newValue);
      });
  };

  return (
    <Typography.Text
      editable={{
        tooltip: 'Change value',
        onChange,
        triggerType: [trigger],
      }}
      copyable={copyable ? { text: text } : copyable}
    >
      {text}
    </Typography.Text>
  );
};
