import { DashboardRouter } from './Dashboard/router';
import { UsersRouter } from './Users/router';
import { BansRouter } from './Bans/router';
import { ArticlesRouter } from './Articles/router';
import { CategoriesRouter } from './Categories/router';
import { GamesRouter } from './Games/router';
import { DepositsRouter } from './Deposits/router';
import { WithdrawsRouter } from './Withdraws/router';
import { VerificationsRouter } from './Verifications/router';
import { CountryBlockerRouter } from './BannedCountries/router';
import { Logout } from './Logout';
import { Login } from './Login';
import { BonusesRouter } from './Bonuses/router';
import { DailyQuestRouter } from './DailyQuest/router';
import { PromocodesRouter } from './Promocodes/router';

export {
  DashboardRouter,
  GamesRouter,
  PromocodesRouter,
  UsersRouter,
  BansRouter,
  BonusesRouter,
  ArticlesRouter,
  CategoriesRouter,
  DepositsRouter,
  WithdrawsRouter,
  VerificationsRouter,
  CountryBlockerRouter,
  Logout,
  Login,
  DailyQuestRouter,
};
