import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { TCategoryNameParams } from 'types/categories';
import { createLanding, getLanding, updateLanding } from 'api/traffic';

interface Props {
  id?: string;
  closeHandler: (value: boolean) => void;
}

export const LandingsEdit: React.FC<Props> = ({ id, closeHandler }) => {
  const [fields] = Form.useForm<TCategoryNameParams>();
  const [loading, setIsLoading] = useState(false);

  const onFinish = async () => {
    if (!id) {
      await createLanding(fields.getFieldsValue(true));
    }
    if (id && fields.getFieldsValue()) {
      await updateLanding({ id, ...fields.getFieldsValue(true) });
    }

    closeHandler(true);
  };

  useEffect(() => {
    const getCurrentLanding = async () => {
      setIsLoading(true);
      const landing = await getLanding(id);

      setIsLoading(false);
      fields.setFieldsValue(landing);
    };

    if (id) getCurrentLanding();
  }, []);

  if (loading) return <Spin />;

  return (
    <Form form={fields} layout="vertical">
      <Form.Item
        name="domain"
        label="Domain"
        rules={[
          {
            required: true,
            message: 'Please enter domain name',
          },
        ]}
      >
        <Input placeholder="Enter domain name" />
      </Form.Item>
      <Form.Item
        name="language"
        label="Language"
        rules={[
          {
            required: true,
            message: 'Please enter language',
          },
        ]}
      >
        <Input placeholder="Enter language" />
      </Form.Item>
      <Button key="submit" htmlType="submit" onClick={() => onFinish()}>
        Submit
      </Button>
    </Form>
  );
};
