import React from "react";
import {IPropsSVG} from "../../../types";

const UAHLogo: React.FC<IPropsSVG> = ({ width = 25, height = 26 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#262833"/>
            <path
                d="M29.8862 43.1016C27.7487 43.1016 25.8831 42.7917 24.2895 42.172C22.7085 41.5522 21.4817 40.6859 20.609 39.5729C19.7489 38.4472 19.3189 37.1255 19.3189 35.6078V35.5698C19.3189 34.8742 19.4454 34.2292 19.6984 33.6347C19.964 33.0276 20.2928 32.528 20.6849 32.1359H29.9241C29.2917 32.3636 28.7668 32.6166 28.3495 32.8948C27.9447 33.1731 27.6412 33.4829 27.4388 33.8244C27.2491 34.1533 27.1542 34.5264 27.1542 34.9438V34.9817C27.1542 35.6647 27.4325 36.2085 27.989 36.6133C28.5455 37.018 29.298 37.2204 30.2466 37.2204C31.2585 37.2204 32.0806 36.9927 32.713 36.5374C33.358 36.0694 33.6805 35.4623 33.6805 34.7161V34.6781H40.757V34.7161C40.757 36.4109 40.308 37.8907 39.41 39.1555C38.512 40.4076 37.2472 41.3815 35.6156 42.0771C33.9841 42.7601 32.0742 43.1016 29.8862 43.1016ZM17.1751 32.8948V31.3771H42.8249V32.8948H17.1751ZM17.1751 28.1519V26.6341H42.8249V28.1519H17.1751ZM29.9621 27.4879C30.6071 27.2476 31.132 26.9946 31.5367 26.729C31.9414 26.4507 32.2387 26.1409 32.4284 25.7994C32.6307 25.4579 32.7319 25.0848 32.7319 24.68V24.6421C32.7319 23.9591 32.4726 23.4153 31.9541 23.0105C31.4355 22.6058 30.7272 22.4034 29.8293 22.4034C28.868 22.4034 28.0839 22.6311 27.4768 23.0864C26.8823 23.5291 26.5851 24.1046 26.5851 24.8129V24.8508H19.5086V24.8129C19.5086 23.1433 19.9513 21.6888 20.8367 20.4494C21.722 19.2099 22.9615 18.2486 24.5551 17.5657C26.1614 16.87 28.0396 16.5222 30.1897 16.5222C32.3019 16.5222 34.1295 16.8321 35.6725 17.4518C37.2282 18.0716 38.4298 18.9443 39.2772 20.0699C40.1372 21.1829 40.5673 22.4983 40.5673 24.016V24.054C40.5673 24.5093 40.5103 24.952 40.3965 25.382C40.2827 25.812 40.1183 26.2041 39.9032 26.5583C39.7009 26.9124 39.4669 27.2223 39.2013 27.4879H29.9621Z"
                fill="#FFCC00"/>
        </svg>


    );
};

export default UAHLogo