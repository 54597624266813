export const paths = {
  HOME: '/',
  USERS: '/users',
  ARTICLES: '/articles',
  CATEGORIES: '/categories',
  LANDINGS: '/landings',
  WITHDRAWS: '/withdraws',
  TRAFFIC: '/traffic',
  PROMOCODES: '/promocodes',
  GAMES: '/games',
  DAILY: '/daily',
  DEPOSITS: '/deposits',
  VERIFICATIONS: '/verifications',
  COUNTRY_BLOCKER: '/country-blocker',
  USER_CHANGES: '/user-changes',
  SEND_TIPS: '/send-tips',
  SUSPICIOUS_USERS: '/suspicious-users',
  LOGOUT: '/logout',
  BANS: '/bans',
  BONUSES: '/bonuses',
  TICKETS: '/tickets',
};
