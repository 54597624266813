/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */

import React from 'react';
import { usersApi } from 'store/services';
import { CustomTable } from 'components/Table';

import { columns as gamesTransactionsColumns } from './utils/subTables/gamesTransaction';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

export const GameTableWrapper = () => {
  const { id } = useParams();

  const { data } = usersApi.useGetGamesTransactionsQuery(id ?? skipToken);

  return (
    <CustomTable
      meta={{
        currentPage: 1,
        totalPages: data?.length,
        total: data?.length,
        perPage: 10,
      }}
      data={data}
      columns={gamesTransactionsColumns}
      loading={false}
    />
  );
};
