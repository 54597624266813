import React from 'react';
import { Card, Statistic, Tag } from 'antd';
import { TStatisticValues } from 'types/statistic';
import { numberType } from 'utils';
import { NumberTypeEnum, StatusColorsEnum } from 'types';
import BigNumber from 'bignumber.js';

// const { Text } = Typography;

interface Props {
  data: TStatisticValues | undefined | number;
  title: string;
  loading: boolean;
  prefix: React.ReactNode;
  hideDiff?: boolean;
}

export const StatisticCard: React.FC<Props> = ({
  data,
  title,
  loading,
  prefix,
  hideDiff,
}) => {
  const difference = (difference: NumberTypeEnum, value: number) => {
    switch (difference) {
      case NumberTypeEnum.PLUS:
        return `+${value}%`;
      case NumberTypeEnum.MINUS:
        return `${value}%`;
      case NumberTypeEnum.ZERO:
      default:
        return '-';
    }
  };

  const titleWithTag = (value = 0) => {
    const diffType = numberType(value);
    const diffText = difference(
      diffType,
      Number(new BigNumber(value).decimalPlaces(0, 2))
    );

    return (
      <>
        {title}
        {!hideDiff && (
          <Tag className="mx-2" color={StatusColorsEnum[diffType]}>
            {diffText}
          </Tag>
        )}
      </>
    );
  };

  // const suffix = (value = 0) => {
  //   return <Text type="secondary">{value}</Text>;
  // };

  return (
    <Card bordered={true}>
      {typeof data !== 'number' && (
        <Statistic
          title={titleWithTag(data?.d)}
          value={data?.c}
          loading={loading}
          prefix={prefix}
          // suffix={suffix(data?.p)}
        />
      )}
    </Card>
  );
};
