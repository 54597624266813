import React from 'react';
import moment from 'moment';
import { Space, Spin, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IUser } from 'types/users';

interface Props {
  data: IUser;
}

export const ShortInfo: React.FC<Props> = ({ data }) => {
  if (!data) return <Spin />;

  return (
    <Space direction="vertical">
      <Typography.Text type="success">
        <CheckOutlined /> Created - {moment(data?.createdAt).fromNow()}
      </Typography.Text>
      <Typography.Text type={data?.bans.length > 0 ? 'danger' : 'success'}>
        {data?.bans.length > 0 ? <CheckOutlined /> : <CloseOutlined />} Banned
      </Typography.Text>
      <Typography.Text type={data?.isVerified ? 'success' : 'danger'}>
        {data?.isVerified ? <CheckOutlined /> : <CloseOutlined />} Verified
      </Typography.Text>
      <Typography.Text type={data?.is_deleted ? 'danger' : 'success'}>
        {data?.isVerified ? <CheckOutlined /> : <CloseOutlined />} Deleted
      </Typography.Text>
      <Typography.Text type={data?.secret ? 'success' : 'danger'}>
        {data?.secret ? <CheckOutlined /> : <CloseOutlined />} Google auth
      </Typography.Text>
    </Space>
  );
};
