import { ColumnsType } from 'antd/es/table';
import { IUser } from '../../../types/users';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';
import { TDailyQuest, TUserDailyQuest } from '../../../types/dailyQuests';

export const columns: ColumnsType<TUserDailyQuest> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Reward amount',
    dataIndex: 'quest',
    key: 'quest.rewardAmount',
    fixed: 'left',
    ellipsis: true,
    render: (quest: TDailyQuest) => quest.rewardAmount,
  },
  {
    title: 'Quest',
    dataIndex: 'questId',
    key: 'quest.name',
    fixed: 'left',
    ellipsis: true,
    render: (quest: TDailyQuest) => quest.title,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
