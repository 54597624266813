/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { DeletePromocodeAction } from './promocodeActions';
import { TPromocode } from '../../../types/promocodes';
import { Checkbox } from 'antd';
import { bonusesApi, promocodesApi } from '../../../store/services';

export const getColumns = (): ColumnsType<TPromocode> => {
  const [toggle] = promocodesApi.useTogglePromocodeMutation();

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      ellipsis: true,
      render: (value: Record<string, string>) => value,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      ellipsis: true,
      render: (value: Record<string, string>) => value,
    },
    {
      title: 'Is active',
      dataIndex: 'isActive',
      key: 'isActive',
      fixed: 'left',
      ellipsis: true,
      render: (value, record) => (
        <Checkbox checked={value} onChange={() => toggle({ id: record.id })} />
      ),
    },
    {
      title: 'BonusId',
      dataIndex: 'bonusId',
      key: 'bonusId',
      fixed: 'left',
      render: (value: Record<string, string>) => value || '--',
    },
    {
      title: 'Reward amount',
      dataIndex: 'rewardAmount',
      key: 'rewardAmount',
      fixed: 'left',
      ellipsis: true,
      render: (value: Record<string, string>) => value || '--',
    },
    {
      title: 'Maximal uses',
      dataIndex: 'maxUses',
      key: 'maxUses',
      fixed: 'left',
      ellipsis: true,
      render: (value: Record<string, string>) => value,
    },
    {
      title: 'Times used',
      dataIndex: 'promocodeUsers',
      key: 'promocodeUsers',
      fixed: 'left',
      ellipsis: true,
      render: (value: []) => value.length,
    },
    {
      title: 'Expires At',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      fixed: 'left',
      ellipsis: true,
      render: (value) => {
        if (value) {
          const fulDate = new Date(value)?.toISOString()?.split('T');
          const date = fulDate[0];
          const time = fulDate[1]?.slice(0, 8);

          return `${date} ${time}`;
        }

        return '';
      },
    },
    {
      title: '',
      key: 'delete',
      fixed: 'left',
      ellipsis: true,
      render: (value: TPromocode) => <DeletePromocodeAction id={value.id} />,
    },
  ];
};
