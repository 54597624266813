import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { TUserChanges } from 'pages/UserChanges/utils/tableConfig';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';

interface Response {
  data: TUserChanges[];
  meta: TMetaData;
}

export const userChangesApi = createApi({
  reducerPath: 'userChangesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['UserChanges'],
  endpoints: (builder) => ({
    getAll: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.USER_CHANGES,
        params,
      }),
      providesTags: ['UserChanges'],
    }),
  }),
});

export const { useGetAllQuery } = userChangesApi;
