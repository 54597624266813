import React from 'react';
import { Button } from 'antd';
import { children } from 'types';
import { Link } from 'react-router-dom';
import { Header } from 'components/Layouts';

interface Props {
  children: children;
}

export const Games: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header label="Games">
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button>
            <Link to={'update'}>Group Update</Link>
          </Button>

          <Button>
            <Link to={'update-active'}>Change Restricted</Link>
          </Button>

          <Button>
            <Link to={'game-status-change'}>Change Game Status</Link>
          </Button>
        </div>
      </Header>
      {children}
    </>
  );
};
