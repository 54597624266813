import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { TGame } from '../../../types/games';
import { Image } from 'antd';
import { AddBonusGameAction } from './bonusActions';

export const getColumns = (bonusId: string): ColumnsType<TGame> => {
  return [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      fixed: true,
      ellipsis: true,

      render: (value) => <Image width={50} src={value} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      ellipsis: true,
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      ellipsis: true,
    },
    {
      title: '',
      key: 'add',
      fixed: 'left',
      ellipsis: true,
      render: (value: TGame) => (
        <AddBonusGameAction bonusId={bonusId} gameId={value.id} />
      ),
    },
  ];
};
