import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { withdrawsApi } from 'store/services';

interface Props {
  open: boolean;
  id: string;
  closeHandler: () => void;
}

export const RejectWithdrawModal: React.FC<Props> = ({
  open,
  id,
  closeHandler,
}) => {
  const [fields] = Form.useForm();

  const [reject, { isSuccess }] = withdrawsApi.useRejectWithdrawMutation();

  const onFinish = async () => {
    const allFields = fields.getFieldsValue();
    const rejectMessage = allFields['rejectMessage'];

    await reject({ id, reason: rejectMessage });
  };

  useEffect(() => {
    if (isSuccess) {
      closeHandler();
    }
  }, [isSuccess]);

  return (
    <Modal
      title={'Reject withdraw'}
      open={open}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={null}
    >
      <Form form={fields} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="rejectMessage"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter reason',
                },
              ]}
            >
              <Input placeholder="Please enter reason" />
            </Form.Item>
          </Col>
        </Row>
        <Button key="submit" htmlType="submit">
          Reject
        </Button>
      </Form>
    </Modal>
  );
};
