import React from 'react';
import { IPropsSVG } from 'types';

export const EthereumLogo: React.FC<IPropsSVG> = ({
  width = 25,
  height = 26,
  fill = {
    primary: '#505050',
    secondary: 'white',
  },
  viewBox = '0 0 25 26',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M24.9326 12.9182C24.9326 19.7854 19.3657 25.3524 12.4986 25.3524C5.63137 25.3524 0.0644531 19.7854 0.0644531 12.9182C0.0644531 6.05108 5.63137 0.484161 12.4986 0.484161C19.3657 0.484161 24.9326 6.05108 24.9326 12.9182Z"
        fill={fill.primary}
      />
      <g opacity="0.8">
        <path
          d="M12.4577 7.14877L12.3809 7.40981V14.9846L12.4577 15.0613L15.9738 12.9829L12.4577 7.14877Z"
          fill={fill.secondary}
        />
        <path
          d="M12.4575 7.14877L8.94141 12.9829L12.4575 15.0613V11.3847V7.14877Z"
          fill={fill.secondary}
        />
        <path
          d="M12.4573 16.2053L12.4141 16.258V18.9563L12.4573 19.0827L15.9756 14.1279L12.4573 16.2053Z"
          fill={fill.secondary}
        />
        <path
          d="M12.4575 19.0828V16.2053L8.94141 14.1279L12.4575 19.0828Z"
          fill={fill.secondary}
        />
        <path
          d="M12.457 15.0613L15.9731 12.9829L12.457 11.3848V15.0613Z"
          fill={fill.secondary}
        />
        <path
          d="M8.94141 12.9829L12.4575 15.0613V11.3847L8.94141 12.9829Z"
          fill={fill.secondary}
        />
      </g>
    </svg>
  );
};
