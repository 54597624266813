import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Bonuses } from '.';
import { BonusesHome } from './Home';
import { BonusAdd } from './Add';
import { BonusesEdit } from './Edit';

interface Props {
  redirectRoute?: string;
}

export const BonusesRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Bonuses>
            <Outlet />
          </Bonuses>
        }
      >
        <Route path={'/'} element={<BonusesHome />} />
        <Route path={'/add'} element={<BonusAdd />} />
        <Route path={'/edit/:id'} element={<BonusesEdit />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
