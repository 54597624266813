/* eslint-disable array-bracket-spacing */
import React, { useState } from 'react';
import { Form, Input, message, Button, InputNumber } from 'antd';
import { createCommand } from 'api/traffic';
import { ICreateCommand } from 'types/traffic';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const Command: React.FC<Props> = ({ closeHandler }) => {
  const messageKey = 'commandForm';

  const [fields] = Form.useForm<ICreateCommand>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      await createCommand(fields.getFieldsValue());

      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  return (
    <Form
      form={fields}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishChecker}
    >
      <Form.Item
        name="name"
        label="Command name"
        rules={[
          {
            required: true,
            message: 'Please enter command name',
          },
        ]}
      >
        <Input placeholder="Enter command name" />
      </Form.Item>

      <Form.Item
        name="commandId"
        label="Command id"
        rules={[
          {
            required: true,
            message: 'Please enter command id',
          },
        ]}
      >
        <Input placeholder="Enter command id" />
      </Form.Item>

      <Form.Item
        name="cpa"
        label="Command CPA"
        rules={[
          {
            required: true,
            message: 'Please enter command CPA',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={1}
          placeholder="Enter command cpa"
        />
      </Form.Item>

      <Form.Item
        name="postback"
        label="Command Postback"
        rules={[
          {
            required: true,
            message: 'Please enter postback',
          },
        ]}
      >
        <Input placeholder="Enter postback" />
      </Form.Item>

      <Button key="submit" htmlType="submit" loading={isLoading}>
        Submit
      </Button>
    </Form>
  );
};
