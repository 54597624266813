/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { TDeposit } from 'types/deposits';
import { Tag } from 'antd';
import React from 'react';
import blockchainIconByName from 'assets/svg/blockchains';

const obj: { [key: string]: string } = {
  FULFILLED: 'green',
  PENDING: 'orange',
  REJECTED: 'magenta',
};

export const columns: ColumnsType<TDeposit> = [
  {
    title: 'Wallet',
    dataIndex: 'address',
    key: 'address',
    render: (value) => (
      <div style={{ width: '150px', overflow: 'scroll', whiteSpace: 'nowrap' }}>
        {value}
      </div>
    ),
  },
  {
    title: 'Blockchain',
    dataIndex: 'blockchain',
    key: 'blockchain',
    ellipsis: true,
    render: (v) => blockchainIconByName(v),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
    render: (value, record) => Number(value) + Number(record.amountBonus),
  },
  {
    title: 'Amount in USD',
    dataIndex: 'amountInUSD',
    key: 'amountInUSD',
    ellipsis: true,
  },
  {
    title: 'Deposit',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
  },
  {
    title: 'Bonus',
    dataIndex: 'amountBonus',
    key: 'amountBonus',
    ellipsis: true,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    ellipsis: true,
  },
  {
    title: 'Network',
    dataIndex: 'network',
    key: 'network',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: (value: string) => <Tag color={obj[value]}>{value}</Tag>,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
];
