import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  TicketsActionEnum,
  TTicketsTransaction,
} from 'types/tickets-transactions';
import blockchainIconByName from 'assets/svg/blockchains';

const actiFilter = () => {
  const filters = [];

  for (const n in TicketsActionEnum) {
    filters.push({
      text: n,
      value: TicketsActionEnum[n as keyof typeof TicketsActionEnum],
    });
  }

  return filters;
};

export const columns: ColumnsType<TTicketsTransaction> = [
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    fixed: 'left',
    ellipsis: true,
    filterMultiple: true,
    filters: actiFilter(),
    onFilter: (value, record) => record.action.includes(value.toString()),
    render: (value: string) => <Tag>{value}</Tag>,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    title: 'Blockchain',
    dataIndex: 'blockchain',
    key: 'blockchain',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => a.quantity - b.quantity,
    render: (v) => blockchainIconByName(v),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    fixed: 'left',
    ellipsis: true,
    sorter: (a, b) => Number(a.amount) - Number(b.amount),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'right',
    ellipsis: true,
    sorter: (a, b) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    render: (value) => moment(value).fromNow(),
  },
];
