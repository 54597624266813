import React from 'react';
import { Button } from 'antd';
import { children } from 'types';
import { Link } from 'react-router-dom';
import { Header } from 'components/Layouts';

interface Props {
  children: children;
}

export const Traffic: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header label="Traffic">
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button>
            <Link to={'campaign'}>Add Campaign</Link>
          </Button>
          <Button>
            <Link to={'command'}>Add Command</Link>
          </Button>
        </div>
      </Header>
      {children}
    </>
  );
};
