/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Space } from 'antd';
import { deleteCampaign, deleteCommand } from 'api/traffic';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
  fetchTraffic: () => void;
}

export const DeleteCampaignAction: React.FC<Props> = ({ id, fetchTraffic }) => {
  const onDeleteCampaign = async (id: string) => {
    await deleteCampaign(id);

    fetchTraffic();
  };

  return (
    <Space>
      <Button type="primary">
        <Link to={`campaign-edit?campaignId=${id}`}>Edit</Link>
      </Button>
      <Button type="primary" danger onClick={() => onDeleteCampaign(id)}>
        Delete
      </Button>
    </Space>
  );
};

export const DeleteCommandAction: React.FC<Props> = ({ id, fetchTraffic }) => {
  const onDeleteCommand = async () => {
    await deleteCommand(id);

    fetchTraffic();
  };

  const onClick = () => onDeleteCommand();

  return (
    <Space>
      <Button type="primary" danger onClick={() => onClick()}>
        Delete
      </Button>
    </Space>
  );
};
