import React, { useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Image,
  Typography,
  Spin,
} from 'antd';
import { verificationsApi } from 'store/services/verifications';
import { TextAreaModal } from 'components/Modals/TextAreaModal';
import { VerificationStatusEnum } from 'types/verification';

interface Props {
  id: string;
  closeHandler: (value: boolean) => void;
}

export const VerificationEdit: React.FC<Props> = ({ id, closeHandler }) => {
  const { data, isLoading } = verificationsApi.useGetVerificationQuery({ id });
  const [updateVerification, { isLoading: isLoadingUpdate }] =
    verificationsApi.useUpdateVerificationStatusMutation();
  const [open, setOpen] = useState<boolean>(false);

  const onVerify = (
    status: VerificationStatusEnum,
    message: string | undefined
  ) => {
    updateVerification({
      id,
      data: {
        status,
        message,
      },
    });
    closeHandler(true);
  };

  const onApprove = () => onVerify(VerificationStatusEnum.ACCEPTED, undefined);
  const onReject = () => setOpen(true);

  const rejectEvent = (text: string) => {
    onVerify(VerificationStatusEnum.REJECTED, text);
  };

  return (
    <Spin spinning={isLoading} delay={500}>
      <Col>
        <Space direction="vertical" className="d-flex" size="middle">
          <Row>
            <Col span={24}>
              <Descriptions
                bordered
                size="small"
                title={<Typography>Personal info</Typography>}
                layout="vertical"
              >
                <Descriptions.Item label="First name">
                  {data?.firstName}
                </Descriptions.Item>
                <Descriptions.Item label="Phone Number">
                  {data?.phoneNumber}
                </Descriptions.Item>
                {/*{data?.middleName && (*/}
                {/*  <Descriptions.Item label="Middle name">*/}
                {/*    {data?.middleName}*/}
                {/*  </Descriptions.Item>*/}
                {/*)}*/}
                <Descriptions.Item label="Last name">
                  {data?.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">
                  {data?.gender}
                </Descriptions.Item>
                <Descriptions.Item label="Birthdate">
                  {new Date(data?.birthdayDate || '').toLocaleDateString()}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Descriptions
                title={<Typography>Address info</Typography>}
                bordered
                size="small"
                layout="vertical"
              >
                <Descriptions.Item label="Country">
                  {data?.country}
                </Descriptions.Item>
                <Descriptions.Item label="City">{data?.city}</Descriptions.Item>
                <Descriptions.Item label="ZIP code">
                  {data?.zipCode}
                </Descriptions.Item>
                <Descriptions.Item label="Street">
                  {data?.street}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row justify="start">
            <Col span={24}>
              <Image.PreviewGroup>
                {data?.passportImgUrl && (
                  <Image height={100} src={data?.passportImgUrl} />
                )}
                {data?.selfieImgUrl && (
                  <Image height={100} src={data?.selfieImgUrl} />
                )}
                {data?.driveLicenseImgUrlFirstStep && (
                  <Image height={100} src={data?.driveLicenseImgUrlFirstStep} />
                )}
                {data?.driveLicenseImgUrlSecondStep && (
                  <Image
                    height={100}
                    src={data?.driveLicenseImgUrlSecondStep}
                  />
                )}
              </Image.PreviewGroup>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button onClick={onReject} loading={isLoadingUpdate}>
                  Reject
                </Button>
                <Button
                  type="primary"
                  onClick={onApprove}
                  loading={isLoadingUpdate}
                >
                  Approve
                </Button>
              </Space>
            </Col>
          </Row>
        </Space>
      </Col>
      <TextAreaModal
        open={open}
        closeHandler={setOpen}
        callback={rejectEvent}
      />
    </Spin>
  );
};
