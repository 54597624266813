import { Button, Space } from 'antd';
import React from 'react';

import { promocodesApi } from 'store/services';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
};

export const DeletePromocodeAction: React.FC<Props> = ({ id }) => {
  const [del, { isLoading }] = promocodesApi.useDeletePromocodeMutation();

  return (
    <Space>
      <Link to={`edit/${id}`}>
        <Button type="primary">Edit</Button>
      </Link>
      <Button danger loading={isLoading} onClick={() => del({ id })}>
        Delete
      </Button>
    </Space>
  );
};
