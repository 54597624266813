import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Promocodes } from '.';
import { PromocodesHome } from './Home';
import { PromocodesAdd } from './Add';
import { PromocodesEdit } from './Edit';

interface Props {
  redirectRoute?: string;
}

export const PromocodesRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Promocodes>
            <Outlet />
          </Promocodes>
        }
      >
        <Route path={'/'} element={<PromocodesHome />} />
        <Route path={'/add'} element={<PromocodesAdd />} />
        <Route path={'/edit/:id'} element={<PromocodesEdit />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
