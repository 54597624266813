import React from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { CategoryActions } from './categoryActions';
import { TCategory } from 'types/categories';

export const columns: ColumnsType<TCategory> = [
  {
    title: 'Title',
    dataIndex: 'name',
    key: 'name',
    fixed: true,
    ellipsis: true,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    ellipsis: true,
    render: (value: TCategory) => <CategoryActions id={value.id} />,
  },
];
