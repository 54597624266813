/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { TDailyQuest } from 'types/dailyQuests';
import { Image } from 'antd';
import { DeleteDailyQuest } from './questActions';

export const getColumns = (): ColumnsType<TDailyQuest> => {
  return [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      fixed: 'left',
      ellipsis: true,
      render: (value) => <Image src={value} width={50} height={50} />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      ellipsis: true,
      render: (value: Record<string, string>) => value,
    },
    {
      title: 'GameId',
      dataIndex: 'gameId',
      key: 'gameId',
      fixed: 'left',
      render: (value: Record<string, string>) => value,
    },
    {
      title: 'Reward Amount',
      dataIndex: 'rewardAmount',
      key: 'rewardAmount',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Required Bets Amount',
      dataIndex: 'requiredBets',
      key: 'requiredBets',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left',
      ellipsis: true,
      render: (value) => {
        const fulDate = new Date(value)?.toISOString()?.split('T');
        const date = fulDate[0];
        const time = fulDate[1]?.slice(0, 8);

        return `${date} ${time}`;
      },
    },
    {
      title: '',
      key: 'delete',
      fixed: 'left',
      ellipsis: true,
      render: (value: TDailyQuest) => <DeleteDailyQuest id={value.id} />,
    },
  ];
};
