/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable padding-line-between-statements */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Row } from 'antd';

import { ImageUploader } from '../../ImageUploader';
import { Localizations } from './Localizations';
import { dailyQuestsApi } from 'store/services';
import { useNavigate } from 'react-router-dom';

export enum MODE {
  EDIT,
  CREATE,
}

interface Props {
  mode: MODE;
  questId?: string;
}

export const DailyQuestEdit: React.FC<Props> = ({ mode, questId }) => {
  const [fields] = Form.useForm<any>();
  const [fieldsValue, setFieldsValue] = useState<any>(null);

  const [checkbox, setCheckbox] = useState(false);

  const handleCheckbox = (value: boolean) => {
    setCheckbox(value);
  };
  const [create, { isSuccess: createSuccess }] =
    dailyQuestsApi.useCreateDailyQuestMutation();

  const [update, { isSuccess: updateSuccess }] =
    dailyQuestsApi.useUpdateDailyQuestMutation();

  const { data: quest } = dailyQuestsApi.useGetDailyQuestQuery(questId);

  const navigate = useNavigate();

  const onFinish = () => {
    const textData: {
      title: string;
    } = {
      title: '',
    };
    const { image, gameId, minBet, ...rawTranslations } =
      fields.getFieldsValue(true);

    const fieldsValues = fields.getFieldsValue();
    const questData: any = {
      ...textData,
      title: fieldsValues.title,
      image: fieldsValues.image,
      gameId: fieldsValues.gameId,
      minBet: fieldsValues.minBet,
      requiredBets: fieldsValues.requiredBets,
      rewardAmount: fieldsValues.rewardAmount,
      houseGame: fieldsValues.houseGame,
      type: checkbox ? 'house' : 'regular',
    };

    if (mode === MODE.CREATE) {
      create(questData);
    }

    if (mode === MODE.EDIT) {
      update({ ...questData, id: questId });
    }
  };

  useEffect(() => {
    if (mode === MODE.EDIT && quest) {
      fields.setFieldsValue(quest);

      setFieldsValue(quest);
    }
  }, [quest]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate('..');
    }
  }, [createSuccess, updateSuccess]);

  return (
    <Form preserve form={fields} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="image"
            label="Image"
            rules={[
              {
                required: true,
                message: 'Please add image',
              },
            ]}
          >
            <ImageUploader />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item name="isHouseGame" label="House Game" preserve>
            <Checkbox
              name="isHouseGame"
              checked={checkbox}
              onChange={() => handleCheckbox(!checkbox)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {mode === MODE.CREATE && (
          <Col span={24}>
            <Localizations type={checkbox ? 'house' : 'regular'} />
          </Col>
        )}
        {mode === MODE.EDIT && fieldsValue && (
          <Col span={24}>
            <Localizations type={checkbox ? 'house' : 'regular'} />
          </Col>
        )}
      </Row>

      <Button key="submit" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
