export enum RolesEnum {
  user = 'user',
  admin = 'admin',
  smm = 'smm'
}

export type TChangeRole = {
  id: string;
  role: RolesEnum;
};
