import React from 'react';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { TWithdraw } from 'types/withdraws';
import { WithdrawActions } from './withdrawActions';
import { Tag } from 'antd';
import { UserData } from '../Home';
import { Link } from 'react-router-dom';

const obj: { [key: string]: string } = {
  FULFILLED: 'green',
  PENDING: 'orange',
  REJECTED: 'magenta',
};

export const columns = (): ColumnsType<TWithdraw> => [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    ellipsis: true,
    render: (value, record) => (
      <div>
        <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>

        <UserData userId={record?.user?.id} />
      </div>
    ),
  },
  {
    title: 'Wallet',
    dataIndex: 'address',
    key: 'address',
    ellipsis: true,
  },
  {
    title: 'Blockchain',
    dataIndex: 'blockchain',
    key: 'blockchain',
    ellipsis: true,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    ellipsis: true,
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    ellipsis: true,
  },
  {
    title: 'Amount with Fee',
    dataIndex: 'amountWithFee',
    key: 'amountWithFee',
    ellipsis: true,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    ellipsis: true,
  },
  {
    title: 'Network',
    dataIndex: 'network',
    key: 'network',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: (value: string) => <Tag color={obj[value]}>{value}</Tag>,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Admin',
    key: 'actions',
    ellipsis: true,
    render: (value: TWithdraw) => (
      <WithdrawActions
        id={value.id}
        status={value.status}
        admin={value.admin}
      />
    ),
  },
];
