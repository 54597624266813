/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import {
  Form,
  message,
  Button,
  Radio,
  RadioChangeEvent,
  Checkbox,
  Select,
} from 'antd';
import { TUpdateGamesParams } from 'types/games';
import { getProviders, changeGamesStatus } from 'api/games';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const GameStatusChange: React.FC<Props> = ({ closeHandler }) => {
  const [type, setType] = useState('new');
  const [providers, setProviders] = useState([]);
  const [isRemove, setIsRemove] = useState(false);
  const [fields] = Form.useForm<TUpdateGamesParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChangeType = (e: RadioChangeEvent) => {
    setType(e.target.value);

    if (type === 'restricted') setIsRemove(false);
  };

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await getProviders('all');

      setProviders(response);
    };

    fetchProviders().then();
  }, []);

  const messageKey = 'categoryForm';

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      const rawFields = fields.getFieldsValue();

      const data = {
        ...rawFields,
        isRemove,
      };

      await changeGamesStatus(data);

      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  return (
    <Form
      form={fields}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishChecker}
    >
      <Form.Item
        name="type"
        label="Type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group name="type" onChange={onChangeType} value={type}>
          <Radio value={'top'}>Top</Radio>
          <Radio value={'new'}>New</Radio>
          <Radio value={'hot'}>Hot</Radio>
          <Radio value={'active'}>Active</Radio>
          <Radio value={'restricted'}>Restricted</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="provider" label="Game Provider">
        <Select
          options={providers.map(({ label }) => ({ value: label, label }))}
          style={{ width: '180px' }}
          placeholder="Select Provider"
        />
      </Form.Item>

      {type !== 'restricted' && (
        <Form.Item name="isRemove" label="Remove">
          <Checkbox
            name="isRemove"
            onChange={() => setIsRemove(!isRemove)}
            value={isRemove}
            disabled={type === 'restricted'}
          />
        </Form.Item>
      )}

      <Button key="submit" htmlType="submit" loading={isLoading}>
        {type !== 'restricted' ? 'Update' : 'Remove'}
      </Button>
    </Form>
  );
};
