import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Traffic } from './index';
import {
  CampaignDateUsers,
  EditCampaign,
  TrafficCampaign,
} from './TrafficCampaign';
import { EditCommand, TrafficCommand } from './TrafficCommand';
import { Home } from './Home';

type Props = {
  redirectRoute?: string;
};

export const TrafficRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Traffic>
            <Outlet />
          </Traffic>
        }
      >
        <Route
          path={'/'}
          element={
            <Home>
              <Outlet />
            </Home>
          }
        >
          <Route path={'/campaign'} element={<TrafficCampaign />} />
          <Route path={'/command'} element={<TrafficCommand />} />
          <Route path={'/campaign-edit'} element={<EditCampaign />} />
          <Route
            path={'/campaign-date-users'}
            element={<CampaignDateUsers />}
          />
          <Route path={'/command-edit'} element={<EditCommand />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
