/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable space-infix-ops */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React, { FC, useEffect, useMemo, useState } from 'react';

import moment, { Moment } from 'moment';
import { getTraffic } from 'api/traffic';
import { CustomTable } from 'components/Table';
import { prepareColumns } from '../utils/tableConfig';
import { ExpandedElement } from '../ExpandedElement';
import { Col, Row, Select, Space } from 'antd';


import generatePicker from 'antd/es/date-picker/generatePicker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import {
  EnumDateTypes,
  convertDates,
  disabledDate,
  disabledDatePlusOne,
} from 'pages/Dashboard/utils/dates';
import { children } from 'types';
import { summary } from '../utils/summary';

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

interface Props {
  children?: children;
}

export const Home: FC<Props> = ({ children }) => {
  const [meta, setMeta] = useState();
  const [traffic, setTraffic] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDate, setDate] = useState<Moment | null>(moment());
  const [currentDates, setDates] = useState<RangeValue<Moment>>(null);
  const [type, setType] = useState<EnumDateTypes>(EnumDateTypes.date);

  const MomentDatePicker = generatePicker<Moment>(momentGenerateConfig);
  const dates = useMemo(
    () => convertDates(currentDates, currentDate, type),
    [currentDates, currentDate, type]
  );

  const fetchTraffic = async () => {
    setLoading(true);
    try {
      const result = await getTraffic({
        page: currentPage,
        perPage: 20,
        type: 'all',
        isMobile: false,
        limit: 20,
        ...dates,
      });

      const { data, ...meta } = result;

      setTraffic(data);
      setMeta(meta);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTraffic();
  }, [dates]);

  const columns = prepareColumns(fetchTraffic);

  const renderCell = (
    _value: any,
    _record: any,
    _index: any,
    originNode: any
  ) => originNode;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col>
          <Space className="py-4">
            <Select value={type} onChange={setType}>
              <Select.Option value="day">Day</Select.Option>
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="date">Date</Select.Option>
            </Select>

            {type !== 'day' ? (
              <MomentDatePicker.RangePicker
                disabledDate={disabledDate}
                picker={type}
                onChange={setDates}
                value={currentDates}
              />
            ) : (
              <MomentDatePicker
                value={currentDate}
                onChange={(v) => setDate(v)}
                disabledDate={disabledDatePlusOne}
              />
            )}
          </Space>
        </Col>
      </Row>

      <CustomTable
        expandedProps={{
          expandedRowRender: (record) =>
            record?.campaigns.map((info: any, id: number) => (
              <>
                {id === 0 && <div>CAMPAIGNS</div>}
                <ExpandedElement
                  {...info}
                  numericId={id + 1}
                  key={info.id}
                  fetchTraffic={fetchTraffic}
                  renderCell={renderCell}
                />
              </>
            )),
          rowExpandable: (record) => record?.campaigns,
        }}
        meta={meta}
        data={traffic}
        loading={loading}
        columns={columns}
        hideSizeChanger={true}
        // @ts-ignore
        renderCell={renderCell}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        summary={(arr: any) => summary(arr)}
      />
      {children}
    </>
  );
};
