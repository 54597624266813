import { authApi } from './auth';
import { usersApi } from './users';
import { depositsApi } from './deposits';
import { withdrawsApi } from './withdraws';
import { verificationsApi } from './verifications';
import { articlesApi } from './articles';
import { categoriesApi } from './categories';
import { bannedCountryApi } from './bannedCountries';
import { statisticApi } from './statistic';
import { awsApi } from './aws';
import { bansApi } from './bans';
import { promocodesApi } from './promocodes';
import { bonusesApi } from './bonuses';
import { dailyQuestsApi } from './dailyQuest';
import { userChangesApi } from './userChanges';

export {
  authApi,
  usersApi,
  depositsApi,
  withdrawsApi,
  verificationsApi,
  dailyQuestsApi,
  articlesApi,
  categoriesApi,
  promocodesApi,
  bannedCountryApi,
  statisticApi,
  awsApi,
  bansApi,
  bonusesApi,
  userChangesApi,
};
