import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { DailyQuests } from '.';
import { DailyQuestHome } from './Home';
import { DailyQuestAdd } from './Add';
import { DailyQuestsEdit } from './Edit';

interface Props {
  redirectRoute?: string;
}

export const DailyQuestRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <DailyQuests>
            <Outlet />
          </DailyQuests>
        }
      >
        <Route path={'/'} element={<DailyQuestHome />} />
        <Route path={'/add'} element={<DailyQuestAdd />} />
        <Route path={'/edit/:id'} element={<DailyQuestsEdit />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
