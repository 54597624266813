import React from 'react';
import { IPropsSVG } from 'types';

export const BUSDLogo: React.FC<IPropsSVG> = ({
  width = 337,
  height = 338,
  fill = {
    primary: '#F0B90B',
  },
  viewBox = '0 0 337 338',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1528_18175)">
        <path
          d="M168.201 0.709961L209.701 43.21L105.201 147.71L63.7012 106.21L168.201 0.709961Z"
          fill={fill.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.204 -0.00317383L210.404 43.2141L105.202 148.417L62.9961 106.212L168.204 -0.00317383ZM64.4069 106.208L105.202 147.003L208.999 43.2057L168.199 1.42296L64.4069 106.208Z"
          fill={fill.primary}
        />
        <path
          d="M231.201 63.71L272.701 106.21L105.201 273.71L63.7012 232.21L231.201 63.71Z"
          fill={fill.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.203 62.9976L273.403 106.214L105.2 274.417L62.9941 232.211L231.203 62.9976ZM64.4062 232.209L105.2 273.003L271.997 106.206L231.197 64.4224L64.4062 232.209Z"
          fill={fill.primary}
        />
        <path
          d="M42.2012 126.71L83.7012 169.21L42.2012 210.71L0.701172 169.21L42.2012 126.71Z"
          fill={fill.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.201 125.994L84.4039 169.214L42.201 211.417L-0.00195312 169.214L42.201 125.994ZM1.40389 169.206L42.201 210.003L82.998 169.206L42.201 127.425L1.40389 169.206Z"
          fill={fill.primary}
        />
        <path
          d="M294.201 126.71L335.701 169.21L168.201 336.71L126.701 295.21L294.201 126.71Z"
          fill={fill.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M294.203 125.998L336.403 169.214L168.2 337.417L125.994 295.211L294.203 125.998ZM127.406 295.209L168.2 336.003L334.997 169.206L294.197 127.422L127.406 295.209Z"
          fill={fill.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1528_18175">
          <rect width="337" height="338" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
