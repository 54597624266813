import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { VerificationActions } from './verificationActions';
import {
  StatusColorsEnum,
  TVerification,
  VerificationStatusEnum,
} from 'types/verification';

const statusTags = (status: VerificationStatusEnum) => (
  <Tag color={StatusColorsEnum[status]}>{status}</Tag>
);

export const columns: ColumnsType<TVerification> = [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
    align: 'center',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    ellipsis: true,
    render: (value) => value?.username,
  },
  {
    title: 'Name',
    key: 'name',
    ellipsis: true,
    render: (record: TVerification) =>
      [record?.firstName, record?.middleName, record?.lastName]
        .filter(Boolean)
        .join(' '),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: (value) => statusTags(value),
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: {
      showTitle: false,
    },
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: {
      showTitle: false,
    },
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'Admin',
    dataIndex: 'admin',
    key: 'admin',
    ellipsis: true,
    render: (value) => value?.username,
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    ellipsis: true,
    render: (value, record) => <VerificationActions id={record.id} />,
  },
];
