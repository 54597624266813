import React from 'react';
import { Button } from 'antd';
import { Header } from 'components/Layouts';
import { children } from 'types';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: children;
}

export const CountryBlocker: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const openModal = () => navigate('add');

  return (
    <>
      <Header label="CountryBlocker">
        <Button onClick={openModal}>Add new country</Button>
      </Header>
      {children}
    </>
  );
};
