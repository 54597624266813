import { Button } from 'antd';
import React from 'react';
import { bansApi } from 'store/services';

type Props = {
  id: string;
};

export const BansActions: React.FC<Props> = ({ id }) => {
  const [unban, { isLoading }] = bansApi.useUnbanMutation();

  return (
    <Button loading={isLoading} onClick={() => unban(id)}>
      Unban
    </Button>
  );
};
