import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { TSuspiciousUsers } from 'pages/SuspiciousUsers/utils/tableConfig';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';

interface Response {
  data: TSuspiciousUsers[];
  meta: TMetaData;
}

export const suspiciousUsersApi = createApi({
  reducerPath: 'suspiciousUsersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['suspiciousUsers'],
  endpoints: (builder) => ({
    getAll: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.SUSPICIOUS_USERS,
        params,
      }),
      providesTags: ['suspiciousUsers'],
    }),
  }),
});

export const { useGetAllQuery } = suspiciousUsersApi;
