import React from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

interface Props {
  open: boolean;
  callback: (text: string) => void;
  closeHandler: (value: boolean) => void;
}

export const TextAreaModal: React.FC<Props> = ({
  open,
  closeHandler,
  callback,
}) => {
  const [fields] = Form.useForm();

  const onFinish = () => {
    const field = fields.getFieldValue('message');

    if (field) {
      callback(field);
      closeHandler(false);
    }
  };

  return (
    <Modal
      title={'Modal'}
      open={open}
      onOk={() => closeHandler(false)}
      onCancel={() => closeHandler(false)}
      footer={null}
    >
      <Form form={fields} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="message"
              label="Reject reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter reason',
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={150}
                style={{ height: 120, resize: 'none' }}
                placeholder="Print reject reason"
              />
            </Form.Item>
          </Col>
        </Row>
        <Button key="submit" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
