import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { IUser } from 'types/users';
import { Link } from 'react-router-dom';
import moment from 'moment';

export type TSendTips = {
  id: string;
  fromUser: IUser;
  toUser: IUser;
  blockchain: string;
  currency: string;
  amount: string;
  createdAt: string;
};

export const columns: ColumnsType<TSendTips> = [
  {
    title: 'From',
    dataIndex: 'fromUser',
    key: 'fromUser',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'To',
    dataIndex: 'toUser',
    key: 'toUser',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Blockchain',
    dataIndex: 'blockchain',
    key: 'blockchain',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
