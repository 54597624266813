import React from 'react';
import { IPropsSVG } from 'types';

export const TetherLogo: React.FC<IPropsSVG> = ({
  width = 26,
  height = 23,
  fill = {
    primary: '#50AF95',
    secondary: 'white',
  },
  viewBox = '0 0 26 23',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1528_18182)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.7602 0.111083L0.0194901 10.069C0.00145036 10.106 -0.00426633 10.1478 0.00317607 10.1884C0.0106185 10.2289 0.0308295 10.2659 0.0608536 10.2942L12.8644 22.563C12.9007 22.5979 12.9492 22.6174 12.9996 22.6174C13.05 22.6174 13.0984 22.5979 13.1348 22.563L25.9383 10.2949C25.9683 10.2667 25.9885 10.2296 25.996 10.1891C26.0034 10.1486 25.9977 10.1068 25.9797 10.0697L21.2389 0.111849C21.2236 0.0784066 21.199 0.0500764 21.1681 0.0302445C21.1371 0.0104126 21.1011 -8.20366e-05 21.0643 1.48237e-05H4.93638C4.89943 -0.000444895 4.86313 0.00979695 4.83187 0.0295049C4.80061 0.0492128 4.77572 0.0775449 4.7602 0.111083V0.111083V0.111083Z"
          fill={fill.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6444 11.0915C14.5524 11.0984 14.0775 11.1267 13.0182 11.1267C12.1756 11.1267 11.5773 11.1015 11.3675 11.0915C8.11123 10.9483 5.68074 10.3814 5.68074 9.70276C5.68074 9.02409 8.11123 8.45803 11.3675 8.31249V10.527C11.5804 10.5423 12.1901 10.5783 13.0327 10.5783C14.0438 10.5783 14.5501 10.5362 14.6413 10.5277V8.31402C17.8906 8.45879 20.3157 9.02563 20.3157 9.70276C20.3157 10.3799 17.8914 10.9467 14.6413 11.0907L14.6444 11.0915ZM14.6444 8.08499V6.10337H19.179V3.08154H6.83279V6.10337H11.3667V8.08422C7.68151 8.25351 4.91016 8.9835 4.91016 9.85826C4.91016 10.733 7.68151 11.4622 11.3667 11.6323V17.9823H14.6436V11.63C18.3203 11.4607 21.0871 10.7315 21.0871 9.85749C21.0871 8.9835 18.3226 8.25427 14.6436 8.08422L14.6444 8.08499Z"
          fill={fill.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1528_18182">
          <rect width="26" height="22.6174" fill={fill.secondary} />
        </clipPath>
      </defs>
    </svg>
  );
};
