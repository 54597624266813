import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { CountryBlocker } from '.';
import { CountryBlockerHome } from './Home';
import { CountryBlockerAdd } from './Add';

type Props = {
  redirectRoute?: string;
};

export const CountryBlockerRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <CountryBlocker>
            <Outlet />
          </CountryBlocker>
        }
      >
        <Route
          path={'/'}
          element={
            <CountryBlockerHome>
              <Outlet />
            </CountryBlockerHome>
          }
        >
          <Route path={'/add'} element={<CountryBlockerAdd />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
