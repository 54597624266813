import React, { useState } from 'react';
import { Modal } from 'antd';
import { Campaign } from 'components/Forms/Campaign';
import { EditCampaign } from 'components/Forms/EditCampaign';
import { useGetParam } from 'utils/useGetParam';
import { CustomTable } from 'components/Table';
import { usersApi } from 'store/services';
import { columns } from 'pages/Users/utils/tableConfig';

interface Props {
  open: boolean;
  closeHandler: (onOk: boolean) => void;
}

export const TrafficCampaignModal: React.FC<Props> = ({
  open,
  closeHandler,
}) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  return (
    <>
      <Modal
        title={'Campaign'}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <Campaign closeHandler={closeHandler} />
      </Modal>
    </>
  );
};

export const EditCampaignModal: React.FC<Props> = ({ open, closeHandler }) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  return (
    <>
      <Modal
        title={'Edit Campaign'}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <EditCampaign closeHandler={closeHandler} />
      </Modal>
    </>
  );
};

export const CampaignDateUsersModal: React.FC<Props> = ({
  open,
  closeHandler,
}) => {
  const onOk = () => closeHandler(true);
  const onCancel = () => closeHandler(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const campaignId = useGetParam('campaignId');
  const date = useGetParam('date');

  const { data, isLoading } = usersApi.useGetAllUsersQuery({
    perPage: 15,
    order: 'ASC',
    page: currentPage,
    filterBy: 'createdAt',

    where: JSON.stringify({
      ...(campaignId && { campaignId }),
      ...(date && { registration_date: date }),
    }),
  });

  return (
    <Modal
      title={'Registered Users'}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      className="userDateModalStyle"
    >
      <section>Date: {date}</section>

      <CustomTable
        loading={isLoading}
        columns={columns(true)}
        data={data?.data}
        meta={data?.meta}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hideSizeChanger
      />
    </Modal>
  );
};
