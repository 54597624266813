import React from 'react';
import { useParams } from 'react-router-dom';
import { MODE } from 'components/Forms/Articles';
import { DailyQuestEdit } from '../../../components/Forms/DailyQuest';

export const DailyQuestsEdit = () => {
  const { id } = useParams();

  return <DailyQuestEdit mode={MODE.EDIT} questId={id} />;
};
