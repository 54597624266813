/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DTGames } from 'pages/Games/utils/tableConfig';
import { TMetaData } from 'types';
import { IArticle } from 'types/articles';
import { IBannedCountry } from 'types/banned-country';
import { TCategory } from 'types/categories';
import { TVerification } from 'types/verification';
import { TUser } from 'types/auth';
import { TWithdraw } from 'types/withdraws';
import { TDeposit } from 'types/deposits';
import { TSession } from 'types/session';
import { TBan } from 'types/bans';
import { TTicketsTransaction } from 'types/tickets-transactions';
import { ExpandableConfig } from 'antd/es/table/interface';
import { TGame, TUserGameTransactions } from 'types/games';
import { TLanding } from 'types/landings';
import { TUserChanges } from 'pages/UserChanges/utils/tableConfig';
import { TSendTips } from 'pages/SendTips/utils/tableConfig';
import { TSuspiciousUsers } from 'pages/SuspiciousUsers/utils/tableConfig';
import { TPromocodeUser } from '../../types/promocodes';
import { TUserDailyQuest } from '../../types/dailyQuests';
import { TUserBonus } from '../../types/bonuses';
import { TCashback } from 'types/cashback';
import { TReferralClaims, TReferralTx, TReferrals } from 'types/referral';
import { TRakebackUser } from '../../types/rakeback';
import { TExchange } from 'types/exchange';
import { TUserAdminBalance } from 'types/userAdminBalance';
import { IGetTraffic } from 'types/traffic';

type DataType =
  | TCategory
  | IArticle
  | TVerification
  | IBannedCountry
  | TUser
  | TDeposit
  | TWithdraw
  | TBan
  | TSession
  | TTicketsTransaction
  | DTGames
  | TUserGameTransactions
  | TLanding
  | TSendTips
  | TSuspiciousUsers
  | TUserChanges
  | TPromocodeUser
  | TUserDailyQuest
  | TUserBonus
  | TCashback
  | TReferralClaims
  | TReferralTx
  | TReferrals
  | TGame
  | TExchange
  | TUserAdminBalance
  | IGetTraffic
  | TRakebackUser;
interface Props<T> {
  loading: boolean;
  meta?: TMetaData | undefined;
  columns: ColumnsType<T>;
  data: T[] | undefined;
  currentPage?: number;
  setCurrentPage?: (value: number) => void;
  hideSizeChanger?: boolean;
  setPerPage?: (v: number) => void;
  expandedProps?: ExpandableConfig<T>;
  summary?: any;
}

export const CustomTable = <T extends DataType>({
  loading,
  columns,
  data = [],
  meta = { currentPage: 1, totalPages: 1, total: 0, perPage: 10 },
  currentPage = 1,
  setCurrentPage,
  hideSizeChanger,
  expandedProps,
  summary,
}: Props<T>) => {
  const [nativeCurrentPage, setNativeCurrentPage] = useState(currentPage);
  useEffect(() => {
    if (!setCurrentPage) {
      const localCurrentPage = sessionStorage.getItem('currentPage');

      if (localCurrentPage) setNativeCurrentPage(Number(nativeCurrentPage));
    }
  }, []);

  useEffect(() => {
    if (!setCurrentPage) {
      sessionStorage.setItem('currentPage', String(nativeCurrentPage));

      return () => {
        sessionStorage.removeItem('currentPage');
      };
    }
  }, [nativeCurrentPage]);
  return (
    <Table
      loading={loading}
      rowKey={(record) => record.id}
      size={'small'}
      columns={columns}
      dataSource={data}
      scroll={{
        x: true,
      }}
      pagination={{
        total: meta?.total || meta?.count,
        current: setCurrentPage ? currentPage : nativeCurrentPage,
        defaultCurrent: 1,
        pageSize: meta?.perPage || 100,
        showSizeChanger: hideSizeChanger ? false : true,
        hideOnSinglePage: true,
        showLessItems: true,
        onChange(page) {
          if (setCurrentPage) {
            setCurrentPage(page);
          } else {
            setNativeCurrentPage(page);
          }
        },
      }}
      expandable={expandedProps ? expandedProps : {}}
      summary={summary || (() => '')}
    />
  );
};
