import { Button, Space } from 'antd';
import React from 'react';
import { dailyQuestsApi } from 'store/services';
import { Link } from 'react-router-dom';

type Props = {
  id: string;
};

export const DeleteDailyQuest: React.FC<Props> = ({ id }) => {
  const [del, { isLoading }] = dailyQuestsApi.useDeleteDailyQuestMutation();

  return (
    <Space>
      <Link to={`edit/${id}`}>
        <Button type="primary">Edit</Button>
      </Link>
      <Button danger loading={isLoading} onClick={() => del({ id })}>
        Delete
      </Button>
    </Space>
  );
};
