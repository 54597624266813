/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {ColumnsType} from 'antd/es/table';
import {DeleteCommandAction} from './trafficActions';
import {Button, Space} from 'antd';
import {Link} from 'react-router-dom';

export const prepareColumns = (fetchTraffic: () => void) =>
    [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: true,
            ellipsis: true,
        },
        {
            title: 'Command Id',
            dataIndex: 'commandId',
            key: 'commandId',
            ellipsis: true,
            render: (value: any) => (
                <div
                    style={{cursor: 'copy'}}
                    onClick={() => navigator.clipboard.writeText(value || '')}
                >
                    {value}
                </div>
            ),
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            fixed: true,
            ellipsis: true,
        },
        {
            title: 'Registrations',
            dataIndex: 'registrations',
            key: 'registrations',
            ellipsis: true,
        },
        {
            title: 'FD',
            dataIndex: 'fdp',
            key: 'fdp',
            ellipsis: true,
        },
        {
            title: 'RD',
            dataIndex: 'rdp',
            key: 'rdp',
            ellipsis: true,
        },
        {
            title: 'Deposits sum',
            dataIndex: 'depositsSum',
            key: 'depositsSum',
            ellipsis: true,
        },
        {
            title: 'Bet sum',
            dataIndex: 'betSum',
            key: 'betSum',
            ellipsis: true,
        },
        {
            title: 'Wins sum',
            dataIndex: 'winSum',
            key: 'winSum',
            ellipsis: true,
        },
        {
            title: 'Loses sum',
            dataIndex: 'loseSum',
            key: 'loseSum',
            ellipsis: true,
        },
        {
            title: 'Total GGR',
            dataIndex: 'ggr',
            key: 'ggr',
            ellipsis: true
        },

        {
            title: 'Action',
            key: 'action',
            align: 'right',
            ellipsis: true,
            render: (value: any) => (
                <Space>
                    <Button type="primary">
                        <Link to={`command-edit?commandId=${value.id}`}>Edit</Link>
                    </Button>

                    <DeleteCommandAction id={value.id} fetchTraffic={fetchTraffic}/>
                </Space>
            ),
        },
    ] as ColumnsType<any>;

export const prepareExpandedColumns = () =>
    [
        {
            title: 'ID',
            key: 'index',
            render: (_v, _r, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            fixed: true,
            ellipsis: true,
            sorter: (a, b) =>
                new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            fixed: true,
            ellipsis: true,
        },
        {
            title: 'Regs.',
            dataIndex: 'registrations',
            key: 'registrations',
            ellipsis: true,
            render: (value: any, record) => (
                <Link
                    to={`campaign-date-users?campaignId=${record.campaignId}&date=${record.date}`}
                >
                    {value}
                </Link>
            ),
        },
        {
            title: 'FD',
            dataIndex: 'fdp',
            key: 'fdp',
            ellipsis: true,
        },
        {
            title: 'RD',
            dataIndex: 'rdp',
            key: 'rdp',
            ellipsis: true,
        },
        {
            title: 'Dep. Sum',
            dataIndex: 'depositsSum',
            key: 'depositsSum',
            ellipsis: true,
        },
        {
            title: 'Bet sum',
            dataIndex: 'betSum',
            key: 'betSum',
            ellipsis: true,
        },
        {
            title: 'Wins sum',
            dataIndex: 'winSum',
            key: 'winSum',
            ellipsis: true,
        },
        {
            title: 'Loses sum',
            dataIndex: 'loseSum',
            key: 'loseSum',
            ellipsis: true,
        },
        {
            title: 'CPA',
            dataIndex: 'cpa',
            key: 'cpa',
            ellipsis: true,
        },
        {

            title: 'GGR',
            dataIndex: 'GGR',
            key: 'GGR',
            ellipsis: true
        }
    ] as ColumnsType<any>;
