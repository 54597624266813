import React, { useMemo, useState } from 'react';
import { BanUserModal } from 'components/Modals/BanUser';
import { ChangeUserStatsModal } from 'components/Modals/ChangeUserStatsModal';
import type { ItemType } from "antd/es/menu/interface";
import { Button, Dropdown, Space } from 'antd';
import { usersApi } from 'store/services';
import { RolesEnum } from 'types/roles';

interface Props {
  userId: string;
}

export const InfoActions: React.FC<Props> = ({ userId }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);

  const [changeRole, { isLoading: roleLoading }] =
    usersApi.useChangeRoleMutation();

  const [disableSessions, { isLoading: sessionsLoading }] =
    usersApi.useDisableSessionsMutation();

  const [remove2fa, { isLoading: remove2faLoading }] =
    usersApi.useRemove2faMutation();

  const getItems = (): ItemType[] => {
    const items: ItemType[] = [];

    Object.values(RolesEnum).forEach((value) => {
      items.push({
        key: `${value}`,
        label: value,
        onClick: () =>
          changeRole({
            id: userId,
            role: RolesEnum[value as keyof typeof RolesEnum],
          }),
      });
    });

    return items;
  };

  const items = useMemo(() => getItems(), [RolesEnum]);

  return (
    <>
      <Space className="py-5">
        <Dropdown menu={{ items }} disabled={roleLoading}>
          <Button type="dashed">Role</Button>
        </Dropdown>
        <Button onClick={() => setModalOpen(true)}>Ban user</Button>
        <Button onClick={() => setUserModalOpen(true)}>Change Stats</Button>
        <Button
          danger
          loading={remove2faLoading}
          onClick={() => remove2fa(userId)}
        >
          Turn off 2fa
        </Button>
        <Button
          danger
          loading={sessionsLoading}
          onClick={() => disableSessions({ id: userId })}
        >
          Logout
        </Button>
      </Space>
      <BanUserModal
        open={modalOpen}
        userId={userId}
        closeHandler={() => setModalOpen(false)}
      />
      <ChangeUserStatsModal
        open={userModalOpen}
        userId={userId}
        closeHandler={() => setUserModalOpen(false)}
      />
    </>
  );
};
