import React from 'react';
import { Actions } from './actions';
import { ColumnsType } from 'antd/es/table';
import { TCategory } from 'types/categories';
import styles from '../styles.module.scss';

export const columns: ColumnsType<TCategory> = [
  {
    title: 'Domain',
    dataIndex: 'domain',
    key: 'domain',
    fixed: true,
    ellipsis: true,
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
    ellipsis: true,
  },
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    ellipsis: true,
    render: (value) => {
      return (
        <iframe
          loading="lazy"
          className={styles.chooseLanding_item_canvas}
          src={value}
        />
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    ellipsis: true,
    render: (value: TCategory) => <Actions id={value.id} />,
  },
];
