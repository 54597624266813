import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { ArticleActions } from './articleActions';
import { IArticle } from 'types/articles';

export const columns: ColumnsType<IArticle> = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    render: (_, e) => {
      if(e?.translation_eng?.title) {
        return e?.translation_eng?.title
      }
      if (e?.translation_hi?.title) {
        return e?.translation_hi?.title
      }
      if(e?.translation_vi?.title) {
        return e?.translation_vi?.title
      }
      if (e?.translation_fil?.title) {
        return e?.translation_fil?.title
      }
      if(e?.translation_tr?.title) {
        return e?.translation_tr?.title
      }
      if (e?.translation_kz?.title) {
        return e?.translation_kz?.title
      }
      if(e?.translation_ru?.title) {
        return e?.translation_ru?.title
      }
      if (e?.translation_ua?.title) {
        return e?.translation_ua?.title
      }
    },
  },
  {
    title: 'Subtitle',
    dataIndex: 'subtitle',
    key: 'subtitle',
    ellipsis: true,
    render: (_, e) => {
      if(e?.translation_eng?.title) {
        return e?.translation_eng?.subtitle
      }
      if (e?.translation_hi?.title) {
        return e?.translation_hi?.subtitle
      }
      if(e?.translation_vi?.title) {
        return e?.translation_vi?.subtitle
      }
      if (e?.translation_fil?.title) {
        return e?.translation_fil?.subtitle
      }
      if(e?.translation_tr?.title) {
        return e?.translation_tr?.subtitle
      }
      if (e?.translation_kz?.title) {
        return e?.translation_kz?.subtitle
      }
      if(e?.translation_ru?.title) {
        return e?.translation_ru?.subtitle
      }
      if (e?.translation_ua?.title) {
        return e?.translation_ua?.subtitle
      }
    },
  },
  {
    title: 'Url',
    dataIndex: 'publicUrl',
    key: 'publicUrl',
    ellipsis: true,
  },
  {
    title: 'Likes',
    dataIndex: 'likesCount',
    key: 'likesCount',
    ellipsis: true,
  },
  {
    title: 'Views',
    dataIndex: 'viewsCount',
    key: 'viewsCount',
    ellipsis: true,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: (value: Date) => moment(value).fromNow(),
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    render: (value: Date) => moment(value).fromNow(),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    ellipsis: true,
    render: (value: IArticle) => <ArticleActions id={value.id} />,
  },
];
