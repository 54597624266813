import { Button, Space } from 'antd';
import { BanUserModal } from 'components/Modals/BanUser';
import React, { useState } from 'react';
import { usersApi } from 'store/services';

type Props = {
  id: string;
  status: string;
  refetch: () => void;
};

export const BansActions: React.FC<Props> = ({ id, status, refetch }) => {
  const [unban, { isLoading }] = usersApi.useUnbanMutation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      {status === 'active' && (
        <Space>
          <Button loading={isLoading} onClick={() => unban(id)}>
            Unban
          </Button>

          <Button
            type="primary"
            danger
            loading={isLoading}
            onClick={() => setModalOpen(true)}
          >
            Edit
          </Button>
        </Space>
      )}

      <BanUserModal
        refetch={refetch}
        open={modalOpen}
        banId={id}
        closeHandler={() => setModalOpen(false)}
      />
    </>
  );
};
