import React from 'react';
import { Modal } from 'antd';
import { BannedCountryAdd } from 'components/Forms/BannedCountries';

interface Props {
  open: boolean;
  closeHandler: () => void;
}

export const BannedCountryModal: React.FC<Props> = ({ open, closeHandler }) => {
  return (
    <Modal
      title={'Ban country modal'}
      open={open}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={null}
    >
      <BannedCountryAdd closeHandler={closeHandler} />
    </Modal>
  );
};
