import React, { useEffect } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { TCategoryNameParams } from 'types/categories';
import { categoriesApi } from 'store/services';
import { skipToken } from '@reduxjs/toolkit/dist/query';

interface Props {
  id?: string;
  closeHandler: (value: boolean) => void;
}

export const CategoryEdit: React.FC<Props> = ({ id, closeHandler }) => {
  const [fields] = Form.useForm<TCategoryNameParams>();
  const [create, { isLoading, isSuccess }] =
    categoriesApi.useCreateCategoryMutation();
  const [update, { isSuccess: updateSuccess }] =
    categoriesApi.useChangeNameMutation();
  const {
    data: category,
    isSuccess: categorySuccess,
    isError: categoryError,
    isLoading: categoryLoading,
  } = categoriesApi.useGetCategoryQuery(id ?? skipToken);

  const onFinish = () => {
    if (!id || categoryError) {
      create(fields.getFieldsValue(true));
    }
    if (id && categorySuccess) {
      update(fields.getFieldsValue(true));
    }
  };

  useEffect(() => {
    if (categorySuccess && category) {
      fields.setFieldsValue(category);
    }
  }, [categoryError, categorySuccess, category]);

  useEffect(() => {
    if (updateSuccess || isSuccess) {
      closeHandler(true);
    }
    if (categoryError) {
      closeHandler(false);
    }
  }, [updateSuccess, isSuccess, categoryError]);

  if (categoryLoading) return <Spin />;

  return (
    <Form
      form={fields}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinish}
    >
      <Form.Item
        name="name"
        label="Category name"
        rules={[
          {
            required: true,
            message: 'Please enter category name',
          },
        ]}
      >
        <Input placeholder="Enter category name" />
      </Form.Item>
      <Button key="submit" htmlType="submit" loading={isLoading}>
        Submit
      </Button>
    </Form>
  );
};
