/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TGame } from 'types/games';
import { Checkbox, Image } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GameActions } from './gameActions';
import styles from '../Home/styles.module.scss';
import classNames from 'classnames';

export interface DTGames extends TGame {
  key: React.Key;
  onDelete: (id: string) => void;
}

export const prepareColumns = (
  handleCheckboxTypeChangeFactory: any,
  handleCheckboxActiveChangeFactory: any,

  setSortBy: any,
  sortBy: { name: string; type: string }
) =>
  [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      fixed: true,
      ellipsis: true,

      render: (value) => <Image width={50} src={value} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      ellipsis: true,
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      ellipsis: true,
    },
    {
      title: () => {
        if (sortBy.name && (sortBy.name === '' || sortBy.name === 'top')) {
          let type = '';

          if (sortBy.type === '') type = 'DESC';
          if (sortBy.type === 'DESC') type = 'ASC';
          if (sortBy.type === 'ASC') type = '';

          return (
            <div
              onClick={() =>
                setSortBy({ name: type === '' ? '' : 'top', type })
              }
              className={classNames(styles.sorted, styles.pointer)}
            >
              Top
            </div>
          );
        }

        return (
          <div
            className={styles.pointer}
            onClick={() => setSortBy({ name: 'top', type: 'DESC' })}
          >
            Top
          </div>
        );
      },
      dataIndex: 'top',
      key: 'top',
      ellipsis: true,

      render: (value, record, rowIndex) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxTypeChangeFactory(rowIndex, 'top')}
        />
      ),
    },
    {
      title: () => {
        if (sortBy.name && (sortBy.name === '' || sortBy.name === 'new')) {
          let type = '';

          if (sortBy.type === '') type = 'DESC';
          if (sortBy.type === 'DESC') type = 'ASC';
          if (sortBy.type === 'ASC') type = '';

          return (
            <div
              onClick={() =>
                setSortBy({ name: type === '' ? '' : 'new', type })
              }
              className={classNames(styles.sorted, styles.pointer)}
            >
              New
            </div>
          );
        }

        return (
          <div
            className={styles.pointer}
            onClick={() => setSortBy({ name: 'new', type: 'DESC' })}
          >
            New
          </div>
        );
      },
      dataIndex: 'new',
      key: 'new',
      ellipsis: true,
      render: (value, record, rowIndex) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxTypeChangeFactory(rowIndex, 'new')}
        />
      ),
    },
    {
      title: () => {
        if (sortBy.name && (sortBy.name === '' || sortBy.name === 'hot')) {
          let type = '';

          if (sortBy.type === '') type = 'DESC';
          if (sortBy.type === 'DESC') type = 'ASC';
          if (sortBy.type === 'ASC') type = '';

          return (
            <div
              onClick={() =>
                setSortBy({ name: type === '' ? '' : 'hot', type })
              }
              className={classNames(styles.sorted, styles.pointer)}
            >
              Hot
            </div>
          );
        }

        return (
          <div
            className={styles.pointer}
            onClick={() => setSortBy({ name: 'hot', type: 'DESC' })}
          >
            Hot
          </div>
        );
      },
      dataIndex: 'hot',
      key: 'hot',
      ellipsis: true,
      render: (value, record, rowIndex) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxTypeChangeFactory(rowIndex, 'hot')}
        />
      ),
    },

    {
      title: () => {
        if (sortBy.name && (sortBy.name === '' || sortBy.name === 'active')) {
          let type = '';

          if (sortBy.type === '') type = 'DESC';
          if (sortBy.type === 'DESC') type = 'ASC';
          if (sortBy.type === 'ASC') type = '';

          return (
            <div
              onClick={() =>
                setSortBy({ name: type === '' ? '' : 'active', type })
              }
              className={classNames(styles.sorted, styles.pointer)}
            >
              Active
            </div>
          );
        }

        return (
          <div
            className={styles.pointer}
            onClick={() => setSortBy({ name: 'active', type: 'DESC' })}
          >
            Active
          </div>
        );
      },
      dataIndex: 'active',
      key: 'active',
      ellipsis: true,
      render: (value, record, rowIndex) => (
        <Checkbox
          checked={value}
          onChange={handleCheckboxActiveChangeFactory(rowIndex, 'active')}
        />
      ),
    },
    {
      title: () => {
        if (
          sortBy.name &&
          (sortBy.name === '' || sortBy.name === 'restricted')
        ) {
          let type = '';

          if (sortBy.type === '') type = 'DESC';
          if (sortBy.type === 'DESC') type = 'ASC';
          if (sortBy.type === 'ASC') type = '';

          return (
            <div
              onClick={() =>
                setSortBy({ name: type === '' ? '' : 'restricted', type })
              }
              className={classNames(styles.sorted, styles.pointer)}
            >
              Restricted
            </div>
          );
        }

        return (
          <div
            className={styles.pointer}
            onClick={() => setSortBy({ name: 'restricted', type: 'DESC' })}
          >
            Restricted
          </div>
        );
      },
      dataIndex: 'restricted',
      key: 'restricted',
      ellipsis: true,
      render: (value) => {
        const length = value?.length;

        return (
          <div>
            {value?.map((code: string, id: number) =>
              length === id + 1 ? code : `${code},`
            )}
          </div>
        );
      },
    },

    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      align: 'left',
      render: (value) => <GameActions {...value} />,
    },
  ] as ColumnsType<DTGames>;
