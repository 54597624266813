import React from 'react';
import { Modal } from 'antd';
import { CategoryEdit } from 'components/Forms/Categories';
import { useNavigate } from 'react-router';

interface Props {
  open: boolean;
  id?: string;
}

export const AddCategoryModal: React.FC<Props> = ({ id, open }) => {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate('..');
  };

  return (
    <>
      <Modal
        title={'Category modal'}
        open={open}
        onOk={closeHandler}
        onCancel={closeHandler}
        footer={null}
      >
        <CategoryEdit id={id} closeHandler={closeHandler} />
      </Modal>
    </>
  );
};
