import React from "react";
import {IPropsSVG} from "../../../types";

const KZTLogo: React.FC<IPropsSVG> = ({ width = 25, height = 26 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#262833"/>
            <path
                d="M26.1298 42.9308V26.1788H18.9205V21.6256H41.0795V26.1788H33.8702V42.9308H26.1298ZM18.9205 18.5901V16.693H41.0795V18.5901H18.9205Z"
                fill="#FFCC00"/>
        </svg>


    );
};

export default KZTLogo