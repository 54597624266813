import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { TCashback } from 'types/cashback';

export const columns: ColumnsType<TCashback> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <Link to={`/users/info/${value?.id}`}>{value.username}</Link>
    ),
  },
  {
    title: 'RoundId',
    dataIndex: 'roundId',
    key: 'roundId',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Lose Sum',
    dataIndex: 'loseSum',
    key: 'loseSum',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Win Sum',
    dataIndex: 'winSum',
    key: 'winSum',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'wager Sum',
    dataIndex: 'wagerSum',
    key: 'wagerSum',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Cashback',
    dataIndex: 'cashback',
    key: 'cashback',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('MM/DD/YYYY, h:mm:ss a'),
  },
];
