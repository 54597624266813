import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { ICardConfig } from 'types/grid';
import { TSession } from 'types/session';

export const lastSession: ICardConfig<TSession> = {
  key: 'last-visit',
  title: 'Last visit',
  className: 'user-info-card',
  grids: [
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      render: (record) => moment(record.updatedAt).fromNow(),
    },
    {
      title: 'Geo',
      dataIndex: 'geo',
      render: (record) => record.geo,
    },
    {
      title: 'Device',
      dataIndex: 'browser',
      render: (record) => record.browser,
    },
    {
      title: 'Logged in at',
      dataIndex: 'createdAt',
      render: (record) => moment(record.createdAt).fromNow(),
    },
    {
      title: 'Last visit ip',
      dataIndex: 'ip',
      render: (record) => record.ip,
    },
    {
      title: 'Session type',
      dataIndex: 'admin',
      render: (record) => (
        <Typography.Text code>
          {record.admin ? 'admin-panel' : 'player-panel'}
        </Typography.Text>
      ),
    },
  ],
};
