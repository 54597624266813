import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
interface Props {
  type: string;
}
export const Localizations: React.FC<Props> = ({ type }) => {
  return (
    <>
      {type !== 'house' && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'gameId'}
              label="gameId"
              rules={[
                {
                  required: true,
                  message: 'Please enter game uuid',
                },
              ]}
            >
              <Input placeholder="Please enter  game uuid" />
            </Form.Item>
          </Col>
        </Row>
      )}
      {type === 'house' && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'houseGame'}
              label="houseGame"
              rules={[
                {
                  required: true,
                  message: 'Please enter house game name',
                },
              ]}
            >
              <Select
                placeholder="Please select an house game name"
                options={[
                  {
                    label: 'Dice',
                    value: 'dice',
                  },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'title'}
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'rewardAmount'}
            label="Reward Amount"
            rules={[
              {
                required: true,
                message: 'Please enter reward amount',
              },
            ]}
          >
            <Input placeholder="Please enter reward amount" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'requiredBets'}
            label="Required Amount of Bets"
            rules={[
              {
                required: true,
                message: 'Please enter required amount of bets to complete',
              },
            ]}
          >
            <Input placeholder="Please enter required amount of bets" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'minBet'}
            label="Minimal bet amount"
            rules={[
              {
                required: true,
                message: 'Please enter minimal bet amount',
              },
            ]}
          >
            <Input placeholder="Please enter minimal bet amount" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
