import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Users } from '.';
import { UsersHome } from './Home';
import { UsersInfo } from './Info';

interface Props {
  redirectRoute?: string;
}

export const UsersRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Users>
            <Outlet />
          </Users>
        }
      >
        <Route path={'/'} element={<UsersHome />} />
        <Route path={'/info/:id'} element={<UsersInfo />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
