import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';

export const awsApi = createApi({
  reducerPath: 'awsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    upload: builder.mutation<string, FormData>({
      query: (params) => ({
        url: API_ROUTES.UPLOAD_AZURE,
        body: params,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useUploadMutation } = awsApi;
