/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React from 'react';
import { TBan } from 'types/bans';
import { IUser } from 'types/users';
import { BansActions } from './bansActions';
import { Tag } from 'antd';

export const columns = (refetch?: any): ColumnsType<TBan> => [
  {
    title: 'Banned user',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => value?.username,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (
      <>{<Tag color={value === 'active' ? 'green' : 'red'}>{value}</Tag>}</>
    ),
  },
  {
    title: 'Ban message',
    dataIndex: 'banMessage',
    key: 'banMessage',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'From',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    sorter: {
      compare: (a, b) =>
        new Date(b.createdAt).getSeconds() - new Date(a.createdAt).getSeconds(),
      multiple: 1,
    },
    render: (value: string) => moment(value).fromNow(),
  },
  {
    title: 'To',
    dataIndex: 'deletedTill',
    key: 'deletedTill',
    fixed: 'left',
    ellipsis: true,
    render: (value: string) => moment(value).fromNow(),
  },
  {
    title: 'Banned by',
    dataIndex: 'admin',
    key: 'admin',
    fixed: 'left',
    ellipsis: true,
    sorter: true,
    render: (value: IUser) => value?.username,
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'left',
    ellipsis: true,
    render: (value: TBan) => (
      <BansActions id={value.id} status={value.status} refetch={refetch} />
    ),
  },
];
