import React from 'react';
import { Tag } from 'antd';
import { ICardConfig } from 'types/grid';
import { getCountryByCode } from 'utils/countries';
import { StatusColorsEnum, TVerification } from 'types/verification';

export const addressConfig: ICardConfig<TVerification> = {
  key: 'address-card',
  title: 'Address',
  className: 'user-info-card',
  grids: [
    {
      title: 'Country',
      dataIndex: 'country',
      render: (record) =>
        `${record.country}, ${getCountryByCode(record.country)?.label}`,
    },
    {
      title: 'City',
      dataIndex: 'city',
      render: (record) => record.city,
    },
    {
      title: 'Address',
      dataIndex: 'street',
      render: (record) =>
        `${record.street}, ${record.apartment}, ${record.house}`,
    },
    {
      title: 'Zip code',
      dataIndex: 'zipCode',
      render: (record) => record.zipCode,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (record) => (
        <Tag color={StatusColorsEnum[record.status]}>{record.status}</Tag>
      ),
    },
  ],
};
