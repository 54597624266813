import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { AddCategoryModal } from 'components/Modals/AddCategoryModal';

export const CategoriesEdit = () => {
  const { id } = useParams();

  return (
    <>
      <Outlet />
      <AddCategoryModal open={true} id={id} />
    </>
  );
};
