/* eslint-disable array-bracket-spacing */
import React, { useState } from 'react';
import {
  Form,
  Input,
  message,
  Button,
  Radio,
  RadioChangeEvent,
  Checkbox,
} from 'antd';
import { TUpdateGamesParams } from 'types/games';
import { updateBulkGames } from 'api/games';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const GameEdit: React.FC<Props> = ({ closeHandler }) => {
  const [type, setType] = useState('new');
  const [search, setSearch] = useState('name');
  const [toggle, setToggle] = useState(true);
  const [fields] = Form.useForm<TUpdateGamesParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChangeType = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  const onChangeSearch = (e: RadioChangeEvent) => {
    setSearch(e.target.value);
  };

  const messageKey = 'categoryForm';

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      const rawFields = fields.getFieldsValue();

      const games = rawFields.text
        .split('\n')
        .filter((e) => e.length > 0)
        .map((t) => t.trim());

      const data = {
        ...rawFields,
        text: games,
        toggle,
      };

      await updateBulkGames(data);

      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  return (
    <Form
      form={fields}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishChecker}
    >
      <Form.Item
        name="type"
        label="Type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group name="type" onChange={onChangeType} value={type}>
          <Radio value={'top'}>Top</Radio>
          <Radio value={'new'}>New</Radio>
          <Radio value={'hot'}>Hot</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="search"
        label="Search By"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group onChange={onChangeSearch} value={search}>
          <Radio value={'game_id'}>Game Id</Radio>
          <Radio value={'name'}>Name</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="toggle" label="Remove">
        <Checkbox
          name="toggle"
          onChange={() => setToggle(!toggle)}
          value={toggle}
        />
      </Form.Item>

      <Form.Item
        name="text"
        label="Games"
        rules={[
          {
            required: true,
            message: 'Please enter game name',
          },
        ]}
      >
        <Input.TextArea style={{ height: '250px' }} />
      </Form.Item>

      <Button key="submit" htmlType="submit" loading={isLoading}>
        Submit
      </Button>
    </Form>
  );
};
