import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { TMeta, TMetaData } from 'types';
import {
  IBannedCountry,
  TBanCountryParams,
  TBannedCountry,
} from 'types/banned-country';

interface Response {
  data: IBannedCountry[];
  meta: TMetaData;
}

export const bannedCountryApi = createApi({
  reducerPath: 'bannedCountryApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['BannedCountry'],
  endpoints: (builder) => ({
    getAllBannedCountries: builder.query<Response, TMeta>({
      query: (params) => ({
        url: API_ROUTES.BANNED_COUNTRY,
        params,
      }),
      providesTags: ['BannedCountry'],
    }),
    addCountry: builder.mutation<IBannedCountry, TBannedCountry>({
      query: (params) => ({
        url: `${API_ROUTES.BANNED_COUNTRY}`,
        body: params,
        method: 'POST',
      }),
      invalidatesTags: ['BannedCountry'],
    }),
    deleteCountry: builder.mutation<IBannedCountry, TBanCountryParams>({
      query: (params) => ({
        url: `${API_ROUTES.BANNED_COUNTRY}/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BannedCountry'],
    }),
  }),
});

export const { useGetAllBannedCountriesQuery } = bannedCountryApi;
