import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { UserChanges } from '.';
import { Home } from './Home';

interface Props {
  redirectRoute?: string;
}

export const UserChangesRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <UserChanges>
            <Outlet />
          </UserChanges>
        }
      >
        <Route path={'/'} element={<Home />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
