import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Categories } from '.';
import { CategoriesAdd } from './Add';
import { CategoriesEdit } from './Edit';
import { CategoriesHome } from './Home';

type Props = {
  redirectRoute?: string;
};

export const CategoriesRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Categories>
            <Outlet />
          </Categories>
        }
      >
        <Route
          path={'/'}
          element={
            <CategoriesHome>
              <Outlet />
            </CategoriesHome>
          }
        >
          <Route path={'/add'} element={<CategoriesAdd />} />
          <Route path={'/edit/:id'} element={<CategoriesEdit />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
