import React, { useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { MainLayout, LoginLayout } from 'components/Layouts';
import {
  DashboardRouter,
  UsersRouter,
  ArticlesRouter,
  CategoriesRouter,
  DepositsRouter,
  WithdrawsRouter,
  VerificationsRouter,
  CountryBlockerRouter,
  GamesRouter,
  Logout,
  Login,
  BansRouter,
  BonusesRouter,
  DailyQuestRouter,
  PromocodesRouter,
} from 'pages';
import { paths } from 'pages/paths';
import local from 'hooks/local';
import { TrafficRouter } from './Traffic/router';
import { LandingRouter } from './Landings/router';
import { UserChangesRouter } from './UserChanges/router';
import { SendTipsRouter } from './SendTips/router';
import { SuspiciousUsersRouter } from './SuspiciousUsers/router';
import { TicketsRouter } from './Tickets/router';

const MyRouter = () => {
  const storage = local;
  const storageToken = storage.local<string>('token', { stringify: false });

  const [token, setToken] = useState<string | undefined>(storageToken.get());

  storageToken.listen((value) => setToken(value));

  return (
    <Routes>
      {token ? (
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          <Route path={`${paths.HOME}`} element={<DashboardRouter />} />
          <Route path={`${paths.USERS}/*`} element={<UsersRouter />} />
          <Route path={`${paths.BANS}/*`} element={<BansRouter />} />
          <Route path={`${paths.ARTICLES}/*`} element={<ArticlesRouter />} />
          <Route
            path={`${paths.CATEGORIES}/*`}
            element={<CategoriesRouter />}
          />
          <Route path={`${paths.GAMES}/*`} element={<GamesRouter />} />
          <Route path={`${paths.BONUSES}/*`} element={<BonusesRouter />} />
          <Route path={`${paths.DAILY}/*`} element={<DailyQuestRouter />} />
          <Route
            path={`${paths.PROMOCODES}/*`}
            element={<PromocodesRouter />}
          />
          <Route path={`${paths.TRAFFIC}/*`} element={<TrafficRouter />} />
          <Route path={`${paths.LANDINGS}/*`} element={<LandingRouter />} />
          <Route path={`${paths.DEPOSITS}/*`} element={<DepositsRouter />} />
          <Route path={`${paths.SEND_TIPS}/*`} element={<SendTipsRouter />} />
          <Route path={`${paths.TICKETS}/*`} element={<TicketsRouter />} />
          <Route
            path={`${paths.SUSPICIOUS_USERS}/*`}
            element={<SuspiciousUsersRouter />}
          />
          <Route
            path={`${paths.USER_CHANGES}/*`}
            element={<UserChangesRouter />}
          />
          <Route
            path={`${paths.VERIFICATIONS}/*`}
            element={<VerificationsRouter />}
          />
          <Route path={`${paths.WITHDRAWS}/*`} element={<WithdrawsRouter />} />
          <Route
            path={`${paths.COUNTRY_BLOCKER}/*`}
            element={<CountryBlockerRouter />}
          />
          <Route path={paths.LOGOUT} element={<Logout />} />
        </Route>
      ) : (
        <Route
          element={
            <LoginLayout>
              <Outlet />
            </LoginLayout>
          }
        >
          <Route path={paths.HOME} element={<Login />} />
        </Route>
      )}
      <Route path="*" element={<Navigate to={paths.HOME} replace />} />
    </Routes>
  );
};

export default MyRouter;
