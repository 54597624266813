import React from 'react';
import { Button, Typography } from 'antd';
const { Text, Title } = Typography;

type Props = {
  closeHandler: (onOk: boolean) => void;
  isLoading: boolean;
  generatedUrl: null | {
    generatedUrlToSite: string;
    generatedUrlToLanding: string;
    generatedUrlToRedirectLanding: string;
  };
};

export const Result: React.FC<Props> = ({
  closeHandler,
  isLoading,
  generatedUrl,
}) => {
  return (
    <div
      style={{
        height: '370px',
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '100%',
          gap: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Title level={4}>Generated URLS</Title>

        <div style={{ width: '100%', height: '20px' }}>
          Landing:{' '}
          {isLoading ? (
            'Loading...'
          ) : (
            <Text>{generatedUrl?.generatedUrlToLanding}</Text>
          )}
        </div>

        <Button
          style={{ width: '100px' }}
          onClick={() =>
            navigator.clipboard.writeText(
              generatedUrl?.generatedUrlToLanding || ''
            )
          }
        >
          COPY
        </Button>

        <div style={{ width: '100%', height: '20px' }}>
          Redirect:{' '}
          {isLoading ? (
            'Loading...'
          ) : (
            <Text>{generatedUrl?.generatedUrlToRedirectLanding}</Text>
          )}
        </div>

        <Button
          style={{ width: '100px' }}
          onClick={() =>
            navigator.clipboard.writeText(
              generatedUrl?.generatedUrlToRedirectLanding || ''
            )
          }
        >
          COPY
        </Button>

        <div style={{ width: '100%', height: '20px' }}>
          Binobi:{' '}
          {isLoading ? (
            'Loading...'
          ) : (
            <Text>{generatedUrl?.generatedUrlToSite}</Text>
          )}
        </div>

        <Button
          style={{ width: '100px' }}
          onClick={() =>
            navigator.clipboard.writeText(
              generatedUrl?.generatedUrlToSite || ''
            )
          }
        >
          COPY
        </Button>
      </div>
      <Button onClick={() => closeHandler(true)}>Close</Button>
    </div>
  );
};
