import { TLang } from 'types';

export const languages: TLang[] = [
  {
    label: 'English',
    tag: 'eng',
  },
  {
    label: 'Filipino',
    tag: 'fil',
  },
  {
    label: 'Hindi',
    tag: 'hi',
  },
  {
    label: 'Turkish',
    tag: 'tr',
  },
  {
    label: 'Vietnamese',
    tag: 'vi',
  },
  {
    label: 'Kazakh',
    tag: 'kz'
  },
  {
    label: 'Russian',
    tag: 'ru'
  },
  {
    label: 'Ukrainian',
    tag: 'ua',
  }
];
