import React, { useState } from 'react';
import { CustomTable } from 'components/Table';
import { dailyQuestsApi } from 'store/services';
import { getColumns } from '../utils/tableConfig';

export const DailyQuestHome = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const { data, isLoading } = dailyQuestsApi.useGetAllDailyQuestsQuery({
    page: currentPage,
    perPage,
  });

  return (
    <CustomTable
      loading={isLoading}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      columns={getColumns()}
      data={data}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setPerPage={setPerPage}
    />
  );
};
