import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AddLandingModal } from 'components/Modals/AddLandingModal';

export const CategoriesEdit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');

    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <AddLandingModal open={true} id={id} closeHandler={closeHandler} />
    </>
  );
};
