import React from 'react';
import { TBan } from 'types/bans';
import { columns as userColumns } from '../../Users/Info/utils/subTables/bansTableConfig';
import { BansActions } from './banActions';

export const getColumns = () => {
  const columns = [...userColumns()];
  const index = columns.findIndex((column) => column.title === 'Action');

  if (index !== -1) {
    columns[index] = {
      ...columns[index],
      render: (value: TBan) => <BansActions id={value?.id} />,
    };
  }

  return columns;
};
