import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { IUser } from 'types/users';
import { Link } from 'react-router-dom';
import moment from 'moment';

export type TSuspiciousUsers = {
  id: string;
  user: IUser;
  resembleTo: IUser;
  detectedWith: string;
  detectionTool: string;
  createdAt: string;
};

export const columns: ColumnsType<TSuspiciousUsers> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Resemble To',
    dataIndex: 'resembleTo',
    key: 'resembleTo',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Detected With',
    dataIndex: 'detectedWith',
    key: 'detectedWith',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Detection Tool',
    dataIndex: 'detectionTool',
    key: 'detectionTool',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
