import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Articles } from '.';
import { ArticlesAdd } from './Add';
import { ArticlesEdit } from './Edit';
import { ArticlesHome } from './Home';

type Props = {
  redirectRoute?: string;
};

export const ArticlesRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Articles>
            <Outlet />
          </Articles>
        }
      >
        <Route path={'/'} element={<ArticlesHome />} />
        <Route path={'/add'} element={<ArticlesAdd />} />
        <Route path={'/edit/:id'} element={<ArticlesEdit />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
