import React from 'react';
import { Col, Row, Spin } from 'antd';
import { InfoCard } from '../InfoCard';
import { aboutUserConfig } from './utils/cards/aboutUser';
import { addressConfig } from './utils/cards/address';
import { contactInfoConfig } from './utils/cards/contactInfo';
import { personalInfoConfig } from './utils/cards/personalInfo';
import { lastSession } from './utils/cards/lastSession';
import { IUser } from 'types/users';
import { firstSession } from './utils/cards/firstSession';

interface Props {
  data: IUser;
}

export const FullInfo: React.FC<Props> = ({ data }) => {
  const colProps = {
    sm: 24,
    xs: 24,
    md: 24,
    xl: 12,
    xxl: 8,
  };

  if (!data) return <Spin />;

  return (
    <Row gutter={[16, 16]} wrap>
      {data.verification && (
        <Col {...colProps}>
          <InfoCard data={data.verification} config={personalInfoConfig} />
        </Col>
      )}
      {data.firstSession && (
        <Col {...colProps}>
          <InfoCard data={data.firstSession} config={firstSession} />
        </Col>
      )}
      {data.lastSession && (
        <Col {...colProps}>
          <InfoCard data={data.lastSession} config={lastSession} />
        </Col>
      )}
      {data.verification && (
        <Col {...colProps}>
          <InfoCard data={data.verification} config={addressConfig} />
        </Col>
      )}
      <Col {...colProps}>
        <InfoCard data={data} config={aboutUserConfig} />
      </Col>
      <Col {...colProps}>
        <InfoCard data={data} config={contactInfoConfig} />
      </Col>
    </Row>
  );
};
