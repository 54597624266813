/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable array-bracket-spacing */
import { Button, Form, Input, Select } from 'antd';

import { useDebouncedState } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

import { CustomTable } from 'components/Table';
import { prepareColumns } from '../utils/tableConfig';
import { getGames, editGame, getProviders } from 'api/games';

import styles from './styles.module.scss';

export const GamesHome = ({ children }) => {
  const [fields] = Form.useForm();

  const [meta, setMeta] = useState({});
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useDebouncedState('', 500);
  const [perPage, setPerPage] = useState(15);
  const [sortBy, setSortBy] = useState({});

  const handleCheckboxTypeChangeFactory =
    (rowIndex, columnKey) => async (event) => {
      const newCheckboxState = [...games];

      newCheckboxState[rowIndex][columnKey] = event.target.checked;

      const element = newCheckboxState[[rowIndex]];

      await editGame({
        id: element.id,
        hot: element.hot,
        new: element.new,
        top: element.top,
      });

      setGames(newCheckboxState);
    };

  const handleCheckboxActiveChangeFactory =
    (rowIndex, columnKey) => async (event) => {
      const newCheckboxState = [...games];

      newCheckboxState[rowIndex][columnKey] = event.target.checked;

      const element = newCheckboxState[[rowIndex]];

      await editGame({
        id: element.id,
        active: element.active,
      });

      setGames(newCheckboxState);
    };

  const columns = prepareColumns(
    handleCheckboxTypeChangeFactory,
    handleCheckboxActiveChangeFactory,
    setSortBy,
    sortBy
  );

  const fetchGame = async () => {
    setLoading(true);
    try {
      const result = await getGames({
        page: currentPage,
        perPage,
        type: 'all',
        isMobile: false,
        limit: perPage,
        sortByType: sortBy?.type,
        sortByField: sortBy?.name,
        ...filters,
      });

      const { data, ...meta } = result;

      setGames(data);
      setMeta({ ...meta, totalPage: meta.lastPage, currentPage, perPage });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGame();
  }, [currentPage, filters, perPage, sortBy]);

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await getProviders('all');

      setProviders(response);
    };

    fetchProviders().then();
  }, []);

  const onFilterProviderChanged = (value) => {
    setFilters({ ...filters, provider: value });
  };

  const renderCell = (value, record, index, originNode) => originNode;

  const items = [
    {
      value: 15,
      label: 15,
    },
    {
      value: 20,
      label: 20,
    },
    {
      value: 50,
      label: 50,
    },
  ];

  useEffect(() => {
    const localCurrentPage = sessionStorage.getItem('currentPage');
    const localGameProvider = sessionStorage.getItem('gameProvider');
    const localGameName = sessionStorage.getItem('gameName');
    const localPerPage = sessionStorage.getItem('perPage');

    if (localCurrentPage) setCurrentPage(Number(localCurrentPage));
    if (localPerPage) {
      fields.setFieldValue('perPageSelect', localPerPage);
      setPerPage(Number(localPerPage));
    }
    if (localGameProvider) {
      fields.setFieldValue('provider', localGameProvider);
      setFilters({ ...filters, provider: localGameProvider });
    }

    if (localGameName) {
      fields.setFieldValue('name', localGameName);
      setFilters({ ...filters, name: localGameName });
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', String(currentPage));
    sessionStorage.setItem('perPage', String(perPage));

    if (filters.provider)
      sessionStorage.setItem('gameProvider', filters.provider);

    if (filters.name) sessionStorage.setItem('gameName', filters.name);

    return () => {
      sessionStorage.removeItem('currentPage');
      sessionStorage.removeItem('perPage');
      sessionStorage.removeItem('gameProvider');
      sessionStorage.removeItem('gameName');
    };
  }, [currentPage, perPage, filters.provider, filters.name]);

  return (
    <>
      <Form
        form={fields}
        layout="vertical"
        onChange={() => setFilters(fields.getFieldsValue())}
        style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}
      >
        <Form.Item name="name" label="Game Name">
          <Input placeholder="Enter game name" />
        </Form.Item>

        <Form.Item name="provider" label="Game Provider">
          <Select
            name="provider"
            options={providers.map(({ label }) => ({ value: label, label }))}
            style={{ width: '180px' }}
            placeholder="Select Provider"
            onChange={onFilterProviderChanged}
          />
        </Form.Item>

        <Form.Item label="Reset Filter">
          <Button type="primary" danger onClick={() => fields.setFieldValue()}>
            Reset
          </Button>
        </Form.Item>
      </Form>

      <Form.Item name="pagination" className={styles.paginationContainer}>
        <Select
          name="pagination"
          options={items}
          defaultValue={15}
          style={{ width: '70px' }}
          onChange={(value) => setPerPage(value)}
        />
      </Form.Item>

      <CustomTable
        hideSizeChanger={true}
        loading={loading}
        columns={columns}
        data={games}
        meta={meta}
        currentPage={currentPage}
        renderCell={renderCell}
        setCurrentPage={setCurrentPage}
      />
      {children}
    </>
  );
};
