import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Deposits } from '.';
import { DepositsHome } from './Home';

type Props = {
  redirectRoute?: string;
};

export const DepositsRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Deposits>
            <Outlet />
          </Deposits>
        }
      >
        <Route path={'/'} element={<DepositsHome />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
