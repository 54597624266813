/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-bracket-spacing */
import { Input, message, Button } from 'antd';
import React, { useRef, useState } from 'react';

import styles from './styles.module.scss';
import { editGameMeta } from 'api/games';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

type UploadProps = {
  selectedImage: Blob | MediaSource | null;
  setSelectedImage: (string: Blob | MediaSource | null) => void;
};
const messageKey = 'categoryForm';

const UploadAndDisplayImage: React.FC<UploadProps> = ({
  selectedImage,
  setSelectedImage,
}) => {
  const inputRef = useRef(null);

  const resetFileInput = () => {
    setSelectedImage(null);
  };

  return (
    <div className={styles.form}>
      <input
        hidden
        id="actual-btn"
        type="file"
        name="image"
        ref={inputRef}
        onChange={(event: any) => {
          setSelectedImage(event.target.files[0]);
        }}
      />

      <label className={styles.label} htmlFor="actual-btn">
        Choose File
      </label>

      <div className={styles.pictureContainer}>
        {selectedImage && (
          <>
            New Image
            <img
              className={styles.newPicture}
              alt="not found"
              width={'150px'}
              src={URL.createObjectURL(selectedImage)}
            />
            <br />
            <button onClick={() => resetFileInput()}>Remove</button>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadAndDisplayImage;

export const GameEditMetaById: React.FC<Props> = ({ closeHandler }) => {
  const [selectedImage, setSelectedImage] = useState<any>(undefined);
  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString) as any;

    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      const data = new FormData();

      data.append('file', selectedImage);

      await editGameMeta({
        ...(selectedImage && { image: data }),
        ...(name && { name }),
        id: urlParams.get('id'),
      });

      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <UploadAndDisplayImage
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />

      <Input
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter game name"
      />

      <Button
        onClick={() => onFinish()}
        key="submit"
        htmlType="submit"
        loading={isLoading}
      >
        Submit
      </Button>
    </div>
  );
};
