import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Tickets } from '.';
import { Home } from './Home';

interface Props {
  redirectRoute?: string;
}

export const TicketsRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Tickets>
            <Outlet />
          </Tickets>
        }
      >
        <Route path={'/'} element={<Home />} />
      </Route>

      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
