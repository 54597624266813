/* eslint-disable function-paren-newline */
import React, { useEffect } from 'react';
import { Col, Row, Spin, Tabs } from 'antd';
import { PopoverInfo } from 'components/Pages/Users/PopoverInfo';
import { useNavigate, useParams } from 'react-router-dom';
import { usersApi } from 'store/services';
import { tableTabsConfig } from './utils/tabs/tableTabsConfig';
import { tabsConfig } from './utils/tabs/tabsConfig';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { InfoActions } from 'components/Pages/Users/FullInfo/utils/infoActions';
import { TCashback } from 'types/cashback';

export const UsersInfo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { refetch, data, isError, isLoading } =
    usersApi.useGetFullInfoUserQuery(id ?? skipToken);

  useEffect(() => {
    if (isError) navigate('..');
  }, [isError]);

  if (isLoading || !data) return <Spin />;

  const claims: TCashback[] = [];
  const accruals: TCashback[] = [];

  if (data.cashback)
    data.cashback.map((c) => {
      if (c.action === 'claim' || (!c.wagerSum && c.amount)) {
        claims.push(c);
      } else {
        accruals.push(c);
      }
    });

  return (
    <Col>
      <Row gutter={[16, 16]}>
        <Col md={15} sm={24}>
          <PopoverInfo data={data} />
        </Col>
        <Col md={9} sm={24} className="j-end d-flex">
          <InfoActions userId={data?.id} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={18} xs={24}>
          <Tabs
            items={tableTabsConfig({ ...data, claims, accruals, refetch })}
            animated
          />
        </Col>
        <Col md={6} xs={24}>
          <Tabs items={tabsConfig(data, refetch)} animated />
        </Col>
      </Row>
    </Col>
  );
};
