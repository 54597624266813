import React from 'react';
import { BlockchainEnum } from 'types/user-balance';
// import { ApecoinLogo } from './ApecoinLogo';
import { BinanceLogo } from './BinanceLogo';
import { BitcoinLogo } from './BitcoinLogo';
import { BUSDLogo } from './BUSDLogo';
import { DogecoinLogo } from './DogecoinLogo';
import { EthereumLogo } from './EthereumLogo';
import { LitecoinLogo } from './LitecoinLogo';
// import { ShibaLogo } from './ShibaLogo';
import { SolanaLogo } from './SolanaLogo';
import { TetherLogo } from './TetherLogo';
import { ToncoinLogo } from './ToncoinLogo';
import { TronLogo } from './TronLogo';
import { USDTLogo } from './USDTLogo';
import { MaticLogo } from './MaticLogo';
import { MoneroLogo } from './MoneroLogo';
import { USDCLogo } from './USDCLogo';

export const blockchainIconByName = (name: BlockchainEnum) => {
  switch (name) {
    // case BlockchainEnum.apecoin:
    //   return <ApecoinLogo width={25} height={25} />;
    case BlockchainEnum['BNB.BSC']:
      return <BinanceLogo width={25} height={25} />;
    case BlockchainEnum.BNB:
      return <BinanceLogo width={25} height={25} />;
    case BlockchainEnum.BTC:
      return <BitcoinLogo width={25} height={25} />;
    case BlockchainEnum.BUSD:
      return <BUSDLogo width={25} height={25} />;
    case BlockchainEnum.DOGE:
      return <DogecoinLogo width={25} height={25} />;
    case BlockchainEnum.ETH:
      return <EthereumLogo width={25} height={25} />;
    case BlockchainEnum.LTC:
      return <LitecoinLogo width={25} height={25} />;
    // case BlockchainEnum.shib:
    //   return <ShibaLogo width={25} height={25} />;
    case BlockchainEnum.SOL:
      return <SolanaLogo width={25} height={25} />;
    case BlockchainEnum.tether:
      return <TetherLogo width={25} height={25} />;
    case BlockchainEnum.TON:
      return <ToncoinLogo width={25} height={25} />;
    case BlockchainEnum.TRX:
      return <TronLogo width={25} height={25} />;
    case BlockchainEnum['MATIC.POLY']:
      return <MaticLogo width={30} height={25} />;
    case BlockchainEnum.XMR:
      return <MoneroLogo width={25} height={25} />;
    case BlockchainEnum.USDC:
      return <USDCLogo width={25} height={25} />
    default:
      return <USDTLogo width={25} height={25} />;
  }
};

export default blockchainIconByName;
