import React from 'react';
import MyRouter from 'pages/router';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { store } from 'store';
import themeConfig from 'utils/themeConfig';

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider theme={themeConfig}>
        <div className="App">
          <MyRouter />
        </div>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
