/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export const CustomEditor: React.FC<Props> = ({ value = '', onChange }) => {
  const editor = useRef(null);
  const [content, setContent] = useState('Start writing');
  const config = {
    readonly: false,
    height: 400,
  };

  const handleSubmit = (html: string) => {
    setContent(html);

    if (onChange) onChange(html);
  };

  useEffect(() => {
    setContent(value);
  }, []);

  return (
    <section className="jodit-container">
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onBlur={handleSubmit}
      />
    </section>
  );
};
