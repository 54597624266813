/* eslint-disable space-before-function-paren */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable array-bracket-spacing */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from 'antd';
import { getLandings } from 'api/traffic';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
const { Title } = Typography;

type Props = {
  setLanding: (landing: string) => void;
  setTab: (tab: string) => void;
};

export const ChooseLanding: React.FC<Props> = ({ setLanding, setTab }) => {
  const [landings, setFetchedLandings] = useState<
    { domain: string; id: string; imageUrl: string; language: string }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const fetchLandings = async () => {
    setLoading(true);
    try {
      const result = await getLandings();

      setFetchedLandings(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLandings();
  }, []);

  const handleNewLanding = (landing: string) => {
    setLanding(landing);
    setTab('options');
  };

  return (
    <section>
      <Title level={4}>Choose Landing</Title>

      {loading ? 'Loading...' : ''}

      <div className={styles.chooseLanding_container}>
        {landings?.map(({ domain, imageUrl, language }) => (
          <div
            className={styles.chooseLanding_item}
            key={domain}
            onClick={() => handleNewLanding(domain)}
          >
            <span className={styles.title}>
              {domain} - {language}
            </span>

            <iframe
              loading="lazy"
              className={styles.chooseLanding_item_canvas}
              src={imageUrl}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
