import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { usersApi } from 'store/services';
import { updateUserByAdmin } from 'api/user';

interface Props {
  open: boolean;
  userId: string;
  closeHandler: () => void;
}

export const ChangeUserStatsModal: React.FC<Props> = ({
  open,
  userId,
  closeHandler,
}) => {
  const [fields] = Form.useForm();

  const { data, isLoading } = usersApi.useGetFullInfoUserQuery(userId);

  const onFinish = async () => {
    await updateUserByAdmin(userId, fields.getFieldsValue());
    closeHandler();
  };

  useEffect(() => {
    fields.setFieldsValue({
      ...(data?.rank && { rank: data?.rank }),
      ...(data?.totalWager && { totalWager: data?.totalWager }),
    });
  }, [data]);

  return (
    <Modal
      title={'User Stats'}
      open={open}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={null}
    >
      <Form form={fields} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="rank"
              label="Rank"
              rules={[
                {
                  required: true,
                  message: 'Please enter rank',
                },
              ]}
            >
              <Input placeholder="Please enter rank" />
            </Form.Item>

            <Form.Item
              name="totalWager"
              label="Wager"
              rules={[
                {
                  required: true,
                  message: 'Please enter wager',
                },
              ]}
            >
              <Input placeholder="Please enter wager" />
            </Form.Item>
          </Col>
        </Row>
        <Button key="submit" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
