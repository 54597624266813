import axios from 'axios';
import * as auth from './auth';
import * as user from './user';
import * as articles from './articles';
import * as categories from './categories';
import { paths } from 'pages/paths';
import { BASE_URL } from './routes';

const $api = axios.create({
  baseURL: BASE_URL,
});

$api.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  return config;
});

$api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      error?.config &&
      !error?.config?.isRetry
    ) {
      originalRequest.isRetry = true;
      try {
        const response = await axios.get(`${BASE_URL}/auth/refresh`, {
          headers: error.config.headers,
        });

        localStorage.setItem('token', response.data.token);

        return $api.request(originalRequest);
      } catch (e) {
        window.location.href = paths.LOGOUT;
      }
    }
    throw error;
  }
);

window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global error handler:', {
    message,
    source,
    lineno,
    colno,
    error,
  });
  return true; // Prevent the error from being displayed in a modal
};

window.onunhandledrejection = function (event) {
  console.error('Unhandled promise rejection:', event.reason);
  return true; // Prevent the error from being displayed in a modal
};

export { auth, user, articles, categories, $api };
