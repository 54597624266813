import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Verifications } from '.';
import { VerificationsHome } from './Home';
import { VerificationsEdit } from './Edit';

type Props = {
  redirectRoute?: string;
};

export const VerificationsRouter: React.FC<Props> = ({ redirectRoute }) => {
  return (
    <Routes>
      <Route
        element={
          <Verifications>
            <Outlet />
          </Verifications>
        }
      >
        <Route
          path={'/'}
          element={
            <VerificationsHome>
              <Outlet />
            </VerificationsHome>
          }
        >
          <Route path={'/edit/:id'} element={<VerificationsEdit />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={<Navigate to={redirectRoute || '..'} replace />}
      />
    </Routes>
  );
};
