export type TWithdrawUser = {
  id: string;
  username: string;
};

export type TWithdraw = {
  id: string;
  username: string;
  wallet: string;
  currency: string;
  network: string;
  amount: string;
  status: TransactionStatus;
  createdAt: string;
  updatedAt: string;
  user: TWithdrawUser;
  admin: TWithdrawUser | undefined;
};

export enum TransactionStatus {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}
