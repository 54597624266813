import React from 'react';
import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

type Props = {
  id: string;
};

export const VerificationActions: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();

  return (
    <Space>
      <Button type="primary" onClick={() => navigate(`edit/${id}`)}>
        Review
      </Button>
    </Space>
  );
};
