import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  CampaignDateUsersModal,
  EditCampaignModal,
  TrafficCampaignModal,
} from 'components/Modals/TrafficCampaignModal';

export const TrafficCampaign = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <TrafficCampaignModal open={true} closeHandler={closeHandler} />
    </>
  );
};
export const EditCampaign = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <EditCampaignModal open={true} closeHandler={closeHandler} />
    </>
  );
};

export const CampaignDateUsers = () => {
  const navigate = useNavigate();

  const closeHandler = (onOk: boolean) => {
    navigate('..');
    // TODO
    // remove after adding a state manager
    if (onOk) {
      navigate(0);
    }
  };

  return (
    <>
      <Outlet />
      <CampaignDateUsersModal open={true} closeHandler={closeHandler} />
    </>
  );
};
