import { Component } from 'react';

export type TGuard = {
  isPrivate?: boolean;
  isPublic?: boolean;
};

export interface IRoute {
  path: string;
  exact?: boolean;
  component: Component;
  guards?: TGuard;
}

export interface IUserTicket {
  id: string;
  quantity: number;
  createdAt: Date;
  updatedAt: Date;
}

export type TLang = {
  label: string;
  tag: string;
};

export type TDuoTone = {
  primary?: string;
  secondary?: string;
};

export interface IPropsSVG {
  width?: string | number;
  height?: string | number;
  fill?: TDuoTone;
  viewBox?: string;
  className?: string;
  style?: React.CSSProperties;
}

export type TMetaData = {
  total: number;
  perPage: number;
  totalPages: number;
  currentPage: number;
  count?: number;
  where?: string;
};

export type TMeta = {
  page?: number;
  perPage?: number;
  filterBy?: string;
  order?: string;
  type?: string;
  isMobile?: boolean;
  limit?: number;
  name?: string;
  provider?: string;
  sortBy?: string;
  gameId?: string;
  where?: string;
  campaignId?: string;
  from?: string;
  to?: string;
};

export enum NumberTypeEnum {
  PLUS,
  MINUS,
  ZERO,
}

export enum StatusColorsEnum {
  'green',
  'red',
  'default',
}

export type children = string | number | JSX.Element | JSX.Element[];
