// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_paginationContainer__O2tcW {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.styles_usernameFilter__oBFKX {
  max-width: 300px;
}

.styles_searchContainer__1eBC9 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.styles_email__rBUL4 {
  margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Users/Home/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,aAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[".paginationContainer {\n  width: 100%;\n\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 5px;\n}\n\n.usernameFilter {\n  max-width: 300px;\n}\n\n.searchContainer {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n\n.email {\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `styles_paginationContainer__O2tcW`,
	"usernameFilter": `styles_usernameFilter__oBFKX`,
	"searchContainer": `styles_searchContainer__1eBC9`,
	"email": `styles_email__rBUL4`
};
export default ___CSS_LOADER_EXPORT___;
