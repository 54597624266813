/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */
import React, { useMemo, useState } from 'react';
import { Col, DatePicker, Row, Select, Space, Tabs } from 'antd';
import moment, { Moment } from 'moment';
import { statisticApi } from 'store/services';
import generatePicker from 'antd/es/date-picker/generatePicker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import {
  cardSpan,
  convertDates,
  disabledDate,
  EnumDateTypes,
} from '../utils/dates';
import {
  TStatisticCardItem,
  gamesCards,
  txCards,
  users,
} from '../utils/statisticConfig';
import { StatisticCard } from 'components/Pages/Users/Statistic/StatisticCard';
// import { StatisticChart } from 'components/Pages/Users/Statistic/StatisticChart';
// import { TotalUsers } from 'components/Pages/Users/Statistic/TotalUsers';

import styles from '../styles.module.scss';
declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

interface IDates {
  from: string | undefined;
  to: string | undefined;
}

interface Props {
  dates: IDates;
  query: string;
  cards: TStatisticCardItem[];
}

export const UnifiedTab: React.FC<Props> = ({ dates, query, cards }) => {
  const { data, isLoading } = statisticApi.useGetStatisticQuery({
    ...(query && { query }),
    ...dates,
  });

  return (
    <div className={styles.tab}>
      {cards.map((c) => (
        <Col {...cardSpan} key={c.title}>
          <StatisticCard
            data={data?.statistic?.[c.dataIndex]}
            title={c.title}
            prefix={c.prefix}
            hideDiff={c?.hideDiff}
            loading={isLoading}
          />
        </Col>
      ))}
    </div>
  );
};

export const DashboardHome = () => {
  const MomentDatePicker = generatePicker<Moment>(momentGenerateConfig);
  const [currentDates, setDates] = useState<RangeValue<Moment>>([
    moment().subtract(1, 'M'),
    moment(),
  ]);

  const [currentDate, setDate] = useState<Moment>(moment());

  const [type, setType] = useState<EnumDateTypes>(EnumDateTypes.date);

  const dates = useMemo(
    () => convertDates(currentDates, currentDate, type),
    [currentDates, currentDate, type]
  );

  // const { data, isLoading } = statisticApi.useGetStatisticQuery(dates);
  // const totalUsersWithoutRange = data?.statistic?.totalUsersWithoutRange;

  return (
    <Col>
      <Row gutter={[16, 16]}>
        <Col>
          <Space className="py-4">
            <Select value={type} onChange={setType}>
              <Select.Option value="date">Date</Select.Option>
              <Select.Option value="day">Day</Select.Option>
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">Month</Select.Option>
              <Select.Option value="quarter">Quarter</Select.Option>
              <Select.Option value="year">Year</Select.Option>
            </Select>

            {type !== 'day' ? (
              <MomentDatePicker.RangePicker
                disabledDate={disabledDate}
                picker={type}
                onChange={setDates}
                value={currentDates}
              />
            ) : (
              <MomentDatePicker
                value={currentDate}
                onChange={(v) => setDate(v as any)}
              />
            )}
          </Space>
        </Col>
      </Row>

      <>
        <UnifiedTab dates={dates} query="users" cards={users} />
        <br />
        <UnifiedTab dates={dates} query="tx" cards={txCards} />
        <br />
        <UnifiedTab dates={dates} query="games" cards={gamesCards} />
      </>
      {/* <Tabs
        items={tabs.map((t) => {
          const id = String(t.key + 1);

          return {
            label: t.label,
            key: id,
            children: t.children,
          };
        })}
      /> */}

      {/* <TotalUsers totalUsersWithoutRange={totalUsersWithoutRange} /> */}

      {/* <Row gutter={[16, 16]}>
        <Col span={24} xl={24} xxl={18}>
          <StatisticChart
            data={data?.graph}
            type={data?.type}
            loading={isLoading}
          />
        </Col>
      </Row> */}
    </Col>
  );
};
