import React, { useState } from 'react';
import { columns } from '../utils/tableConfig';
import { CustomTable } from 'components/Table';
import { verificationsApi } from 'store/services/verifications';
import { children } from 'types';

interface Props {
  children: children;
}

export const VerificationsHome: React.FC<Props> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const { data, isLoading } = verificationsApi.useGetAllVerificationsQuery({
    page: currentPage,
    perPage,
  });

  return (
    <>
      <CustomTable
        loading={isLoading}
        columns={columns}
        data={data?.data}
        meta={data?.meta}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPerPage={setPerPage}
      />
      {children}
    </>
  );
};
