import React from 'react';
import { IPropsSVG } from 'types';

export const DogecoinLogo: React.FC<IPropsSVG> = ({
  width = 35,
  height = 35,
  fill = {
    primary: '#C2A633',
    secondary: 'white',
  },
  viewBox = '0 0 35 35',
  className,
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1369_18800)">
        <path
          d="M17.9203 11.5325H15.4199V16.4621H19.3537V18.526H15.4199V23.4552H18.0428C18.7167 23.4552 23.5756 23.5314 23.5681 17.7201C23.5606 11.9089 18.8555 11.5325 17.9203 11.5325Z"
          fill={fill.primary}
        />
        <path
          d="M17.5 0C7.83493 0 0 7.83493 0 17.5C0 27.1651 7.83493 35 17.5 35C27.1651 35 35 27.1651 35 17.5C35 7.83493 27.1651 0 17.5 0ZM18.1876 26.9517H11.8499V18.5262H9.6159V16.4622H11.8498V8.03617H17.2891C18.5759 8.03617 27.0989 7.76895 27.0989 17.6521C27.0989 27.6987 18.1878 26.9517 18.1878 26.9517H18.1876Z"
          fill={fill.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1369_18800">
          <rect width="35" height="35" fill={fill.secondary} />
        </clipPath>
      </defs>
    </svg>
  );
};
