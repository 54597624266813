import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { IUser } from 'types/users';
import { Link } from 'react-router-dom';
import moment from 'moment';

export type TUserChanges = {
  id: string;
  user: IUser;
  prevUsername: string;
  nextUsername: string;
  createdAt: string;
};

export const columns: ColumnsType<TUserChanges> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Prev username',
    dataIndex: 'prevUsername',
    key: 'prevUsername',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'New Username',
    dataIndex: 'newUsername',
    key: 'newUsername',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
