import { API_ROUTES, BASE_URL } from 'api/routes';
import {
  IGetArticlesParams,
  IGetArticleParams,
  IArticle,
} from 'types/articles';
import { $api } from 'api';

export const updateArticleNew = async (params: IArticle) => {
  const { data } = await $api.patch(
    `${BASE_URL}/${API_ROUTES.ARTICLES}/new/${params.id}`,
    params
  );

  return data;
};

export const getArticles = async (params: IGetArticlesParams) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.ARTICLES}`, {
    params,
  });

  return data;
};

export const deleteArticle = async (params: IGetArticleParams) => {
  const { data } = await $api.delete(
    `${BASE_URL}/${API_ROUTES.ARTICLES}/${params.id}`
  );

  return data;
};

export const createArticle = async (params: IArticle) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.ARTICLES}/new/add`,
    params
  );

  return data;
};

export const uploadFile = async (file: FormData) => {
  const { data } = await $api.post(`${BASE_URL}/${API_ROUTES.UPLOAD_AZURE}`, file);

  return data;
};

export const getArticleById = async (params: IGetArticleParams) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.ARTICLES}/${params.id}`
  );

  return data;
};
