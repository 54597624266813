/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Tabs } from 'antd';
import { editCommand, getCommand } from 'api/traffic';
import { useGetParam } from 'utils/useGetParam';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const EditCommand: React.FC<Props> = ({ closeHandler }) => {
  const messageKey = 'messageKey-1';

  const [fields] = Form.useForm();
  const [_, setIsLoading] = useState<boolean>(false);

  const commandId = useGetParam('commandId');

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      await editCommand({ ...fields.getFieldsValue(), commandId });

      setIsLoading(false);
      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishChecker = async () => {
    message.error('Error in form');
  };

  useEffect(() => {
    const getCommandById = async (commandId: string | null) => {
      const command = await getCommand(commandId);

      fields.setFieldsValue(command);
    };

    getCommandById(commandId);
  }, []);

  return (
    <Form form={fields} layout="vertical" onFinishFailed={onFinishChecker}>
      <Form.Item
        name="name"
        label="Command name"
        rules={[
          {
            required: true,
            message: 'Please enter command name',
          },
        ]}
      >
        <Input placeholder="Enter command name" />
      </Form.Item>

      <Form.Item
        name="cpa"
        label="Command CPA"
        rules={[
          {
            required: true,
            message: 'Please enter command CPA',
          },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          min={1}
          placeholder="Enter command cpa"
        />
      </Form.Item>

      <Form.Item
        name="postback"
        label="Command Postback"
        rules={[
          {
            required: true,
            message: 'Please enter postback',
          },
        ]}
      >
        <Input placeholder="Enter postback" />
      </Form.Item>

      <Button onClick={() => onFinish()}>Submit</Button>
    </Form>
  );
};
