import { ColumnsType } from 'antd/es/table';
import { IUser } from '../../../types/users';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';
import { TPromocode, TPromocodeUser } from '../../../types/promocodes';

export const columns: ColumnsType<TPromocodeUser> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Promocode',
    dataIndex: 'promocode',
    key: 'promocode.name',
    fixed: 'left',
    ellipsis: true,
    render: (promocode: TPromocode) => promocode.name,
  },
  {
    title: 'Reward amount',
    dataIndex: 'promocode',
    key: 'promocode.rewardAmount',
    fixed: 'left',
    ellipsis: true,
    render: (promocode: TPromocode) =>
      promocode.rewardAmount ? promocode.rewardAmount : '--',
  },
  {
    title: 'Related bonus id',
    dataIndex: 'promocode',
    key: 'promocode.bonusId',
    fixed: 'left',
    ellipsis: true,
    render: (promocode: TPromocode) =>
      promocode.bonusId ? promocode.bonusId : '--',
  },
  {
    title: 'Claimed At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
