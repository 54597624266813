import { ColumnsType } from 'antd/es/table';
import { IUser } from '../../../types/users';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import React from 'react';
import { TRakebackUser } from '../../../types/rakeback';

export const columns: ColumnsType<TRakebackUser> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value: IUser) => (
      <Link to={`/users/info/${value?.id}`}>{value?.username}</Link>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'isClaimed',
    key: 'isClaimed',
    fixed: 'left',
    ellipsis: true,
    render: (value) => (value ? 'Claimed' : 'Not claimed'),
  },
  {
    title: 'Claimed At',
    dataIndex: 'claimedAt',
    key: 'claimedAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => value && moment(value).format('YYYY/MM/DD HH:MM'),
  },
];
