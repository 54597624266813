import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { TBan } from 'types/bans';

export const columns: ColumnsType<TBan> = [
  {
    title: 'Banned user',
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    ellipsis: true,
    render: (value) => value.username,
  },
  {
    title: 'Ban message',
    dataIndex: 'banMessage',
    key: 'banMessage',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: 'From',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).fromNow(),
  },
  {
    title: 'To',
    dataIndex: 'deletedTill',
    key: 'deletedTill',
    fixed: 'left',
    ellipsis: true,
    render: (value) => moment(value).toLocaleString(),
  },
];
