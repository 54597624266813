import { Button, Space } from 'antd';
import React from 'react';
import { bannedCountryApi } from 'store/services/bannedCountries';

type Props = {
  id: string;
};

export const BannedCountryActions: React.FC<Props> = ({ id }) => {
  const [deleteCountry] = bannedCountryApi.useDeleteCountryMutation();

  return (
    <Space>
      <Button type="primary" danger onClick={() => deleteCountry({ id })}>
        Delete
      </Button>
    </Space>
  );
};
