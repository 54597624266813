import React from 'react';

export const Search = () => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_378_19649)">
        <path
          d="M22.2764 21.4482L15.8476 15.0149C17.115 13.495 17.8773 11.5526 17.8773 9.4311C17.8773 4.59118 13.9052 0.655884 9.0194 0.655884C4.13356 0.655884 0.152344 4.59578 0.152344 9.43569C0.152344 14.2756 4.12438 18.2109 9.01021 18.2109C11.0858 18.2109 12.996 17.4992 14.5114 16.3098L20.9631 22.7615C21.3396 23.1381 21.8998 23.1381 22.2764 22.7615C22.6529 22.385 22.6529 21.8248 22.2764 21.4482ZM2.03504 9.43569C2.03504 5.63356 5.16675 2.54317 9.01021 2.54317C12.8537 2.54317 15.9854 5.63356 15.9854 9.43569C15.9854 13.2378 12.8537 16.3282 9.01021 16.3282C5.16675 16.3282 2.03504 13.2332 2.03504 9.43569Z"
          fill="#5E6670"
        />
      </g>
      <defs>
        <clipPath id="clip0_378_19649">
          <rect
            width="22.4064"
            height="22.4064"
            fill="white"
            transform="translate(0.152344 0.647095)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
