import { Col, Form, Input, Row } from 'antd';
import Button from 'antd/es/button';
import React, { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { languages } from '../../Articles/Localizations/utils/languages';
import { TLang } from 'types';
import { PromocodeType } from '../index';

export const Localizations: React.FC<{ type: PromocodeType | null }> = ({
  type,
}: {
  type: PromocodeType | null;
}) => {
  const [currentLang, setCurrentLang] = useState<number>(0);

  return (
    <>
      <Row gutter={6} style={{ margin: '12px 0' }}>
        {languages.map((lang: TLang, index: number) => (
          <Col key={`text-lang-${lang.tag}`}>
            <Button
              onClick={() => setCurrentLang(index)}
              type={currentLang === index ? 'primary' : 'default'}
            >
              {lang.label}
            </Button>
          </Col>
        ))}
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'name'}
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
                min: 6,
              },
            ]}
          >
            <Input placeholder="Please enter name" />
          </Form.Item>
        </Col>
      </Row>
      {type === PromocodeType.BALANCE && (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              preserve
              name={'rewardAmount'}
              label="Promocode balance reward amount"
              rules={[
                {
                  required: type === PromocodeType.BALANCE,
                  message: 'Please enter required reward amount',
                },
              ]}
            >
              <Input placeholder="Please enter required reward amount" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            preserve
            name={'maxUses'}
            label="Maximal amount of times promocode can be used"
            rules={[
              {
                required: true,
                message: 'Please enter max uses amount',
              },
            ]}
          >
            <Input placeholder="Please enter max uses amount" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
