import React, { useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';
import { usersApi } from 'store/services';
import { editBan, getBan } from 'api/user';
import dayjs from 'dayjs';

interface Props {
  open: boolean;
  userId?: string;
  banId?: string;
  refetch?: () => void;
  closeHandler: () => void;
}

export const BanUserModal: React.FC<Props> = ({
  open,
  banId,
  userId,
  refetch,
  closeHandler,
}) => {
  const [ban, { isLoading, isSuccess }] = usersApi.useBanMutation();

  const [fields] = Form.useForm();

  const getBanEntity = async () => {
    if (banId) {
      const res = await getBan({ banId });

      fields.setFieldsValue({
        banMessage: res.banMessage,
        deletedTill: dayjs(res.deletedTill),
      });
    }
  };

  if (banId) {
    getBanEntity();
  }

  const onFinish = () => {
    if (userId) {
      const allFields = fields.getFieldsValue();
      const deletedTill = moment(allFields['deletedTill']?.$d).toISOString();
      const banMessage = allFields['banMessage'];

      ban({
        userId,
        deletedTill,
        banMessage,
      });

      if (refetch) refetch();
    }

    if (banId) {
      editBan({ banId }, fields.getFieldsValue());

      if (refetch) refetch();
      closeHandler();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      closeHandler();
    }
  }, [isSuccess]);

  return (
    <Modal
      title={banId ? 'Edit ban' : 'Ban user'}
      open={open}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={null}
    >
      <Form form={fields} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="banMessage"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter reason',
                },
              ]}
            >
              <Input placeholder="Please enter reason" />
            </Form.Item>
            <Form.Item
              name="deletedTill"
              label="Deleted till"
              rules={[
                {
                  required: true,
                  message: 'Please enter date',
                },
              ]}
            >
              <DatePicker
                showTime
                format={'YYYY-MM-DD hh:mm:ss'}
                disabledDate={(current) => moment().add(-1, 'days') >= current}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button key="submit" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
