import React from 'react';
import { useParams } from 'react-router-dom';
import { MODE } from 'components/Forms/Articles';
import { BonusEdit } from '../../../components/Forms/Bonus';

export const BonusesEdit = () => {
  const { id } = useParams();

  return <BonusEdit mode={MODE.EDIT} bonusId={id} />;
};
