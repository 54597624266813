import React, { useState } from 'react';
import { CustomTable } from 'components/Table';
import { columns } from 'pages/Users/Info/utils/subTables/ticketsTransConfig';
import { ticketsApi } from 'store/services/tickets';

export const Home = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const { data, isLoading } = ticketsApi.useGetAllQuery({
    page: currentPage,
    limit: perPage,
  });

  const dataForTable = data ? data[1] : [];
  const meta = data
    ? data[0].meta
    : { total: 0, perPage, totalPages: 0, currentPage };

  return (
    <CustomTable
      data={dataForTable}
      meta={meta}
      columns={columns}
      loading={isLoading}
      setPerPage={setPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};
