import React from 'react';
import { Tag, Typography } from 'antd';
import { getStringWithDots } from 'utils';
import { ICardConfig } from 'types/grid';
import { IUser } from 'types/users';
import { ChangeData } from 'components/Pages/Users/ChangeData';

export const aboutUserConfig: ICardConfig<IUser> = {
  key: 'about-card',
  title: 'Info about user',
  className: 'user-info-card',
  grids: [
    {
      title: 'Player id',
      dataIndex: 'id',
      render: (record) => (
        <Typography.Text copyable={{ text: record.id }}>
          {getStringWithDots(record.id)}
        </Typography.Text>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      render: (record) => (
        <ChangeData
          id={record.id}
          value={'username'}
          initial={record.username}
          copyable={true}
        />
      ),
    },
    {
      title: 'Password',
      dataIndex: 'password',
      render: (record) => (
        <ChangeData
          id={record.id}
          value={'password'}
          initial={'(change password)'}
          trigger={'text'}
          afterText={'(change password)'}
        />
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (record) => <Tag>{record.role}</Tag>,
    },
  ],
};
