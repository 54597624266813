import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from 'api/routes';
import { baseQueryWithReauth } from 'store/interceptor';
import { IStatistic, TDates } from 'types/statistic';

export const statisticApi = createApi({
  reducerPath: 'statisticApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Statistic'],
  endpoints: (builder) => ({
    getStatistic: builder.query<IStatistic, TDates | undefined>({
      query: (params) => ({
        url: API_ROUTES.STATISTIC,
        params,
      }),
      providesTags: ['Statistic'],
    }),
  }),
});

export const { useGetStatisticQuery } = statisticApi;
