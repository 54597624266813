import React, { useState } from 'react';
import { Layout, theme, FloatButton } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { SideMenu } from 'components/SideMenu';
import { children } from 'types';

const { Content, Footer } = Layout;

interface Props {
  children: children;
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout hasSider className={'h-fluid'}>
      <FloatButton
        className={'r-5-svh'}
        onClick={() => setCollapsed(!collapsed)}
        icon={collapsed ? <MenuOutlined /> : <CloseOutlined />}
        tooltip={<div>Navigation</div>}
        type={'primary'}
      />
      <SideMenu collapsed={collapsed} />
      <Layout className={'mh-100'}>
        <Content
          className={'b-radius ma-4 pa-4'}
          style={{ background: colorBgContainer }}
        >
          {children}
        </Content>
        <Footer className={'text-center'}>Binobi.com</Footer>
      </Layout>
    </Layout>
  );
};
