/* eslint-disable comma-dangle */
/* eslint-disable array-bracket-newline */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Button, Select } from 'antd';
import { getOneGame, getProviders, updateBulkGames } from 'api/games';
import countries from 'utils/countries';

type Props = {
  closeHandler: (onOk: boolean) => void;
};

export const GameEditActive: React.FC<Props> = ({ closeHandler }) => {
  const messageKey = 'categoryForm';

  const [providers, setProviders] = useState([]);
  const [customFields, setCustomFields] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState('');

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await getProviders('all');

      setProviders(response);
    };

    fetchProviders().then();
  }, []);

  const onFilterProviderChanged = (value: string) => {
    setSelectedProvider(value);
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);
      message.open({
        key: messageKey,
        type: 'loading',
        content: 'Sending data...',
      });

      const countriesCodes =
        customFields?.length === 0
          ? []
          : customFields[0]?.value
              .split('\n')
              .filter((e: any) => e.length > 0)
              .map((t: any) => t.trim());

      await updateBulkGames({ countriesCodes, provider: selectedProvider });

      closeHandler(true);
    } catch (e) {
      message.open({
        key: messageKey,
        type: 'error',
        content: 'Error',
        duration: 2,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const search = (
    input: string,
    option: { label: string; value: string } | undefined
  ) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  const onCountryChange = (value: string) => {
    const currentCountry = countries.find((i) => value === i.value);
    const currentValue =
      customFields?.length === 0 ? [] : customFields[0]?.value;
    const newValue =
      currentValue?.length === 0
        ? currentCountry?.value
        : `${currentValue}\n${currentCountry?.value}`;

    if (currentCountry) {
      setCustomFields([{ name: 'countries', value: newValue }]);
    }
  };

  useEffect(() => {
    const fetchGames = async () => {
      const game = await getOneGame({ provider: selectedProvider });
      const newValue = game?.restricted
        ? String(game?.restricted).replaceAll(',', '\n')
        : '';

      setCustomFields([{ name: 'countries', value: newValue }]);
    };

    fetchGames().then();
  }, [selectedProvider]);

  return (
    <>
      <Form.Item name="provider" label="Game Provider">
        <Select
          options={providers.map(({ label }) => ({ value: label, label }))}
          style={{ width: '180px' }}
          placeholder="Select Provider"
          onChange={onFilterProviderChanged}
        />
      </Form.Item>

      <Form.Item
        name="countryCode"
        label="Country"
        rules={[
          {
            required: true,
            message: 'Please select an country',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={search}
          placeholder="Please select an country"
          disabled={!countries}
          options={countries}
          onChange={onCountryChange}
        ></Select>
      </Form.Item>

      <Form
        fields={customFields}
        layout="vertical"
        onChange={(newFields) => {
          setCustomFields([
            { name: 'countries', value: (newFields.target as any).value },
          ]);
        }}
      >
        <Form.Item name="countries" label="Restricted">
          <Input.TextArea id="text-area" style={{ height: '250px' }} />
        </Form.Item>

        <Button
          onClick={() => onFinish()}
          key="submit"
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form>
    </>
  );
};
