import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { TWithdrawUser, TransactionStatus } from 'types/withdraws';
import { withdrawsApi } from 'store/services';
import { RejectWithdrawModal } from 'components/Modals/RejectWithdraw';

interface Props {
  id: string;
  status: TransactionStatus;
  admin?: TWithdrawUser;
}

export const WithdrawActions: React.FC<Props> = ({ id, status, admin }) => {
  const [approve] = withdrawsApi.useApproveWithdrawMutation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const items = [
    {
      key: '1',
      label: 'Approve',
      onClick: () => approve({ id }),
    },
    {
      key: '2',
      label: 'Reject',
      onClick: () => setModalOpen(true),
    },
  ];

  return (
    <>
      {status === TransactionStatus.PENDING && (
        <Dropdown
          menu={{ items }}
          disabled={status !== TransactionStatus.PENDING}
        >
          <a>Change status</a>
        </Dropdown>
      )}
      {admin && admin.username}
      <RejectWithdrawModal
        open={modalOpen}
        id={id}
        closeHandler={() => setModalOpen(false)}
      />
    </>
  );
};
