/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable padding-line-between-statements */
/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { ImageUploader } from '../../ImageUploader';
import { Localizations } from './Localizations';
import { IArticle } from 'types/articles';
import { articlesApi, categoriesApi } from 'store/services';
import { TCategory } from 'types/categories';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';
import { languages } from './Localizations/utils/languages';

export enum MODE {
  EDIT,
  CREATE,
}

interface Props {
  mode: MODE;
  articleId?: string;
}

export const ArticleEdit: React.FC<Props> = ({ mode, articleId }) => {
  const translations = {} as any;

  const [fields] = Form.useForm<IArticle>();
  const [fieldsValue, setFieldsValue] = useState<IArticle | null>(null);

  const [update, { isSuccess: updateSuccess }] =
    articlesApi.useUpdateArticleMutation();
  const [create, { isSuccess: createSuccess }] =
    articlesApi.useCreateArticleMutation();

  const {
    data: article,
    isSuccess: articleSuccess,
    isError: articleError,
    isLoading: articleLoading,
  } = articlesApi.useGetArticleQuery(articleId ?? skipToken);
  const { data: categories, isLoading: categoriesLoading } =
    categoriesApi.useGetAllCategoriesQuery({
      perPage: 100,
    });

  const navigate = useNavigate();

  const onFinish = () => {
    const {
      id,
      imageUrl,
      publicUrl,
      createdAt,
      updatedAt,
      viewsCount,
      likesCount,
      categoryId,

      ...rawTranslations
    } = fields.getFieldsValue(true);

    const baseValue = {
      id,
      imageUrl,
      publicUrl,
      createdAt,
      updatedAt,
      viewsCount,
      likesCount,
      categoryId,
    };

    languages.map(({ tag }) => {
      const translationsSuffix = `_${tag}`;
      translations[`translation_${tag}`] = rawTranslations[
        'text' + translationsSuffix
      ]
        ? {
            text: rawTranslations['text' + translationsSuffix].replace(
              '\\r\\n',
              '<br/>'
            ),
            title: rawTranslations['title' + translationsSuffix],
            subtitle: rawTranslations['subtitle' + translationsSuffix],
          }
        : null;
    });
    const formResult = { ...baseValue, ...translations };

    if (mode === MODE.CREATE) {
      create(formResult);
    }
    if (mode === MODE.EDIT) {
      update(formResult);
    }
  };

  useEffect(() => {
    if (articleSuccess && article) {
      const {
        id,
        imageUrl,
        publicUrl,
        createdAt,
        updatedAt,
        viewsCount,
        likesCount,
        categoryId,
        ...translations
      } = article;

      const parsedArticle = {
        id,
        imageUrl,
        publicUrl,
        createdAt,
        updatedAt,
        viewsCount,
        likesCount,
        categoryId,
      } as any;

      Object.entries(translations).map((translation) => {
        if (translation[1])
          Object.entries(translation[1]).map((field) => {
            const fieldName = field[0];
            const translationTag = translation[0].slice(12);

            const newField = `${fieldName}_${translationTag}`;

            parsedArticle[newField] = field[1];
          });
      });

      fields.setFieldsValue(parsedArticle);

      setFieldsValue(parsedArticle);
    }
  }, [articleError, articleSuccess, article]);

  useEffect(() => {
    if (updateSuccess || createSuccess || articleError) {
      navigate('..');
    }
  }, [updateSuccess, createSuccess, articleError]);

  if (articleLoading) return <Spin />;

  return (
    <Form preserve form={fields} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="publicUrl"
            label="Url"
            rules={[
              {
                required: true,
                message: 'Please enter url',
              },
            ]}
          >
            <Input
              addonBefore="http://binobi.casino/"
              placeholder="Please enter url"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="categoryId"
            label="Category"
            rules={[
              {
                required: true,
                message: 'Please select an category',
              },
            ]}
          >
            <Select
              placeholder="Please select an category"
              disabled={!categories?.data}
              loading={categoriesLoading}
              options={categories?.data?.map((category: TCategory) => {
                return {
                  label: category.name,
                  value: category.id,
                };
              })}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="imageUrl"
            label="Image"
            rules={[
              {
                required: true,
                message: 'Please add image',
              },
            ]}
          >
            <ImageUploader />
          </Form.Item>
        </Col>

        {mode === MODE.CREATE && (
          <Col span={24}>
            <Localizations />
          </Col>
        )}

        {mode === MODE.EDIT && fieldsValue && (
          <Col span={24}>
            <Localizations />
          </Col>
        )}
      </Row>
      <Button key="submit" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};
