import React, { useState } from 'react';
import { CustomTable } from 'components/Table';
import { bansApi } from 'store/services/bans';
import { getColumns } from '../utils/tableConfig';

export const BansHome = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const { data, isLoading } = bansApi.useGetAllBansQuery({
    page: currentPage,
    perPage,
  });

  return (
    <CustomTable
      loading={isLoading}
      columns={getColumns()}
      data={data?.data}
      meta={data?.meta}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setPerPage={setPerPage}
    />
  );
};
