/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { TBonus } from 'types/bonuses';
import { OpenAddBonusGameModal, DeleteBonusAction } from './bonusActions';
import { Button, Checkbox, Image, Space } from 'antd';
import { bonusesApi } from 'store/services';

import freespins from '../images/freespins.png';
import deposit from '../images/deposit.png';

export const getColumns = (): ColumnsType<TBonus> => {
  const [toggle] = bonusesApi.useToggleBonusMutation();
  const [del] = bonusesApi.useRemoveBonusGameMutation();

  const getDefaultImage = (bonus: TBonus) => {
    return bonus.type === 'free-spins' ? freespins : deposit;
  };

  return [
    {
      title: 'Image',
      key: 'image',
      fixed: 'left',
      ellipsis: true,
      render: (value) => (
        <Image
          src={value.image || getDefaultImage(value)}
          width={50}
          height={50}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: 'Games',
      dataIndex: 'bonusGames',
      key: 'games',
      fixed: 'left',
      ellipsis: true,
      render: (value, record) => (
        <Space>
          <div
            style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}
          >
            {value &&
              value.map((item: any) => (
                <div style={{ display: 'flex', gap: '4px' }} key={item.id}>
                  {item.gameId}
                  <Button
                    danger
                    size="small"
                    onClick={() =>
                      del({ gameId: item.gameId, bonusId: item.bonusId })
                    }
                  >
                    Delete
                  </Button>
                </div>
              ))}
          </div>
          <OpenAddBonusGameModal id={record.id} />
        </Space>
      ),
    },
    {
      title: 'Is active',
      dataIndex: 'isActive',
      key: 'isActive',
      fixed: 'left',
      ellipsis: true,
      render: (value, record) => (
        <Checkbox checked={value} onChange={() => toggle({ id: record.id })} />
      ),
    },
    {
      title: 'End At',
      dataIndex: 'endAt',
      key: 'endAt',
      fixed: 'left',
      ellipsis: true,
      render: (value) => {
        if (value) {
          const fulDate = new Date(value)?.toISOString()?.split('T');
          const date = fulDate[0];
          const time = fulDate[1]?.slice(0, 8);

          return `${date} ${time}`;
        }

        return '';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      fixed: 'left',
      ellipsis: true,
      render: (value) => {
        const fulDate = new Date(value)?.toISOString()?.split('T');
        const date = fulDate[0];
        const time = fulDate[1]?.slice(0, 8);

        return `${date} ${time}`;
      },
    },
    {
      title: '',
      key: 'delete',
      fixed: 'left',
      ellipsis: true,
      render: (value: TBonus) => <DeleteBonusAction id={value.id} />,
    },
  ];
};
