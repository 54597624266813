import React from "react";
import {IPropsSVG} from "../../../types";

const RUBLogo: React.FC<IPropsSVG> = ({ width = 25, height = 26 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="60" height="60" rx="30" fill="#262833"/>
            <path
                d="M20.59 42.9308V34.811H17.1751V33.2932H20.59V16.693H32.713C34.6101 16.693 36.286 17.0724 37.7405 17.8313C39.195 18.5775 40.3269 19.6336 41.1364 20.9995C41.9585 22.3655 42.3696 23.9591 42.3696 25.7804V25.8184C42.3696 27.6396 41.9585 29.2269 41.1364 30.5803C40.3269 31.9209 39.195 32.9644 37.7405 33.7106C36.286 34.4442 34.6101 34.811 32.713 34.811H28.3115V42.9308H20.59ZM17.1751 39.3642V37.8464H35.0085V39.3642H17.1751ZM28.3115 29.3281H30.4553C31.7833 29.3281 32.8078 29.0435 33.5287 28.4744C34.2623 27.9052 34.6291 27.0578 34.6291 25.9322V25.8942C34.6291 24.7686 34.2623 23.9275 33.5287 23.371C32.8078 22.8018 31.7833 22.5173 30.4553 22.5173H28.3115V29.3281Z"
                fill="#FFCC00"/>
        </svg>


    );
};

export default RUBLogo