import { API_ROUTES, BASE_URL } from 'api/routes';
import { TCategoryNameParams, TCategoryIdParams } from 'types/categories';
import { TMeta } from 'types';
import { $api } from 'api';

export const addCategory = async (params: TCategoryNameParams) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.CATEGORIES}/add`,
    params
  );

  return data;
};

export const getCategories = async (params?: TMeta) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.CATEGORIES}`, {
    params,
  });

  return data;
};

export const deleteCategory = async (params: TCategoryIdParams) => {
  const { data } = await $api.delete(
    `${BASE_URL}/${API_ROUTES.CATEGORIES}/${params.id}`
  );

  return data;
};
